<template>
  <div class="partyBform">
    <div class="paryAform" v-if="init">
      <div class="newContract-form">
        <el-form :inline="true" :model="partyBInformation" class="demo-form-inline">
          <el-form-item label="乙方 :">
            <el-input v-model="partyBInformation.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="法定代理人 :">
            <el-input v-model="partyBInformation.iegalRepresentative" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码 :">
            <el-input
              v-model="partyBInformation.unifiedSocialCreditCode"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="代理人 :">
            <el-input
              v-model="partyBInformation.unifiedSocialCreditCode"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话 :">
            <el-input v-model="partyBInformation.phone" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" :model="partyBInformation" class="demo-form-inline">
          <el-form-item label="身份证号码 :">
            <el-input v-model="partyBInformation.identificationNum" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="户名 :" class="huName">
            <el-input v-model="partyBInformation.accountName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开户行 :" style="padding-left: 70px;">
            <el-input v-model="partyBInformation.accountBank" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="账号 :" style="padding-left: 10px;">
            <el-input v-model="partyBInformation.account" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="地址 :" style="padding-left: 30px;">
            <el-input v-model="partyBInformation.address" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="newContract-form" v-else>
      <el-form :inline="true" :model="partyBInformation" class="demo-form-inline">
        <el-form-item label="乙方 :">
          <el-input v-model="partyBInformation.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="代理人 :">
          <el-input v-model="partyBInformation.agent" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="联系电话 :">
          <el-input v-model="partyBInformation.phone" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码 :">
          <el-input v-model="partyBInformation.identificationNum" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="地址 :">
          <el-input v-model="partyBInformation.address" :disabled="true"> </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'partyBform',
  props: {
    auditDetails: {
      type: Boolean,
      default: true
    },
    init: {
      type: Boolean,
      default: true
    },
    secondContractUser: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      merchantsDate: [],
      merchantName: '',
      phone: '',
      total: 0,
      page: {
        pageSize: 10,
        currentPage: 1
      },
      partyBInformation: {
        agent: '', // 代理人
        iegalRepresentative: '', // 法定代理人
        unifiedSocialCreditCode: '', // 统一社会信用代码
        phone: '', // 联系电话
        identificationNum: '', // 身份证号码
        accountName: '', // 户名
        accountBank: '', // 开户行
        account: '', // 账号
        address: '',
        name: '',
        identity: '',
        isMarketSide: '',
        merchantId: ''
      }
    }
  },
  watch: {
    secondContractUser: {
      handler: function() {
        this.partyBInformation.agent = this.secondContractUser.agent
        this.partyBInformation.iegalRepresentative = this.secondContractUser.iegalRepresentative
        this.partyBInformation.unifiedSocialCreditCode = this.secondContractUser.unifiedSocialCreditCode
        this.partyBInformation.phone = this.secondContractUser.phone
        this.partyBInformation.identificationNum = this.secondContractUser.identificationNum
        this.partyBInformation.accountName = this.secondContractUser.accountName
        this.partyBInformation.accountBank = this.secondContractUser.accountBank
        this.partyBInformation.account = this.secondContractUser.account
        this.partyBInformation.address = this.secondContractUser.address
        this.partyBInformation.name = this.secondContractUser.name
        this.partyBInformation.identity = this.secondContractUser.identity
        this.partyBInformation.isMarketSide = this.secondContractUser.isMarketSide
        this.partyBInformation.merchantId = this.secondContractUser.merchantId
        this.partyBInformation.id = this.secondContractUser.id
      },
      deep: true
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.partyBform {
  .eldialog-tabeDate {
    margin-top: 20px;
  }
  .dialog-input {
    display: inline-block;
    margin-left: 10px;
  }
  .el-input--small {
    width: 140px;
  }
  .dialogName {
    width: 230px;
    display: inline-block;
  }
  .Nuber {
    margin-left: 20px;
  }
  .eldialog-button {
    display: inline-block;
    width: 400px;
    text-align: right;
  }
  .paryAform {
    .newContract-form {
      .ringr {
        padding-left: 40px;
      }
      .huName {
        padding-left: 40px;
      }
    }
  }
}
</style>
