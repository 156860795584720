<template>
  <div class="basiCinformation">
    <div class="stall-information">
      <el-card header="档口信息">
        <el-row class="stall-row">
          <el-col :span="5"> 区域名称:{{ stallinformation.regionName }} </el-col>
          <el-col :span="5"> 楼层:{{ stallinformation.floorsNum }} </el-col>
          <el-col :span="5"> 建筑面积:{{ stallinformation.floorage }} </el-col>
          <el-col :span="5"> 档口标签:{{ stallinformation.labelName }} </el-col>
        </el-row>
        <el-row>
          <el-col :span="5"> 档口号:{{ stallinformation.number }} </el-col>
          <el-col :span="5"> 所属类型:{{ stallinformation.businessTypeName }} </el-col>
          <el-col :span="5"> 算费系数:{{ stallinformation.coefficient }} </el-col>
          <el-col :span="5"> 备注:{{ stallinformation.remarks }} </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="merchant-Information">
      <el-card header="入驻商户">
        <el-row>
          <el-col :span="5">商户姓名:{{ dnpMerchantInfo.merchantName }}</el-col>
          <el-col :span="5">性别:{{ dnpMerchantInfo.sex | sex }}</el-col>
          <el-col :span="5">开户行:{{ dnpMerchantInfo.depositBank }}</el-col>
          <el-col :span="5">卡号:{{ dnpMerchantInfo.cardNumber }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="5">身份证号:{{ dnpMerchantInfo.idNumber }}</el-col>
          <el-col :span="5">联系方式:{{ dnpMerchantInfo.phone }}</el-col>
          <el-col :span="5">户名:{{ dnpMerchantInfo.accountName }}</el-col>
          <el-col :span="5">经营品类:{{ dnpMerchantInfo.merchantCategoryInfo }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="5">创建时间: {{ dnpMerchantInfo.createTime }}</el-col>
          <el-col :span="5">创建员工:{{ dnpMerchantInfo.addOperatorName }}</el-col>
          <el-col :span="5">绑定鲜道赢账号:{{ dnpMerchantInfo.bindGearPhone }}</el-col>

          <el-col :span="5">积分:{{ dnpMerchantInfo.totalIntegral }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="1">营业执照:</el-col>
          <el-col :span="10">
            <el-image
              v-for="(img, index) in srcList"
              class="m-l-20"
              style="width: 100px; height: 100px"
              :src="img"
              :key="index"
              :preview-src-list="srcList"
            >
            </el-image>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="cntract-Information">
      <el-card header="合同信息">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="合同名称" width="170"></el-table-column>
          <el-table-column label="合同属性" width="170">
            <template slot-scope="scope">
              <div v-if="scope.row.attribute === 1">线下合同</div>
              <div v-if="scope.row.attribute === 0">电子合同</div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="起草时间" show-overflow-tooltip width="170">
          </el-table-column
          ><el-table-column prop="startTime" label="开始时间" width="170"> </el-table-column>
          <el-table-column prop="endTime" label="结束时间" show-overflow-tooltip width="170">
          </el-table-column
          ><el-table-column prop="signTime" label="签订时间" width="170"> </el-table-column>
          <el-table-column prop="status" label="状态" show-overflow-tooltip width="170">
            <template slot-scope="scope">
              <div>{{ status(scope.row.status) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="firstName" label="甲方" width="170"> </el-table-column>
          <el-table-column prop="firstPhone" label="甲方电话" show-overflow-tooltip width="170">
          </el-table-column>
          <el-table-column prop="secondName" label="乙方" show-overflow-tooltip> </el-table-column
          ><el-table-column prop="secondPhone" label="乙方电话" width="170"> </el-table-column>
          <el-table-column prop="updateTime" label="最后修改时间" width="170"> </el-table-column>
          <el-table-column prop="updateByName" label="修改人" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="getView(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
import { mapState } from 'vuex'
export default {
  props: {
    typeName: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentPage: 1,
      path: process.env.BASE_PATH,
      pagesize: 10,
      totalCount: 1, // 总条数
      dnpMerchantInfo: {
        merchantName:'',
        sex:'',
        depositBank:'',
        cardNumber:'',
        idNumber:'',
        phone:'',
        accountName:'',
        merchantCategoryInfo:'',
        createTime:'',
        addOperatorName:'',
        bindGearPhone:'',
        totalIntegral:'',
      },
      stallinformation: {},
      srcList: [],
      mrgId: '',
      tableData: [],
    }
  },
  filters: {
    sex(sex) {
      return sex === 1 ? '男' : '女'
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      }else{
        console.log("---");
          this.init()

      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getbasicinformation()
      this.getMerchant()
      this.getqueryContractPageData()
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.getqueryContractPageData()
    },
    handleCurrentChange(current) {
      this.currentPage = current
      this.getqueryContractPageData()
    },
    getView(row) {
      this.$router.push({
        name: 'newContract',
        query: { contractId: row.id, audit: false, details: false, status: row.status }
      })
    },
    // 过滤状态
    status(status) {
      let statusName = ''
      if (status === 0) {
        statusName = '暂存'
      } else if (status === 1) {
        statusName = '待他人签署'
      } else if (status === 2) {
        statusName = '待审核'
      } else if (status === 3) {
        statusName = '执行中'
      } else if (status === 4) {
        statusName = '已终止'
      } else if (status === 5) {
        statusName = '已到期'
      } else if (status === 6) {
        statusName = '已撤销'
      } else if (status === 7) {
        statusName = '已拒签'
      }
      return statusName
    },
    //档口信息
    getbasicinformation() {
      marketplan.DNP_GET_NEW_REGION_NUMBER_LIST({ mrgId: this.$route.query.data.mrgId }).then((result) => {
          this.stallinformation = result.data.results.newRegionNumberList[0]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //入驻商户信息
    getMerchant() {
      marketplan.DNP_FIND_MERCHANT_INFO({ miId: this.$route.query.data.miId, mrgId:this.$route.query.data.mrgId }).then((result) => {
          this.dnpMerchantInfo = result.data.results.dnpMerchantInfo || this.dnpMerchantInfo
          if (this.dnpMerchantInfo.businessLicenses) {
            let imgArr = this.dnpMerchantInfo.businessLicenses.split(',') || []
            this.srcList = imgArr.map((item) => this.path + item)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //合同信息
    getqueryContractPageData() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true,
        boothId: this.$route.query.data.mrgId,
        current: this.currentPage,
        pageSize: this.pagesize
      }
      marketplan
        .getqueryContractPageData({ ...obj })
        .then((result) => {
          this.tableData = result.data.data.data
          this.totalCount = result.data.data.total
        })
        .catch((err) => {})
    }
  }
}
</script>
<style lang="scss">
.basiCinformation {
  .stall-information {
    font-size: 15px;
    margin-bottom: 10px;
    .stall-row {
      margin-bottom: 20px;
    }
  }
  .merchant-Information {
    font-size: 15px;
    margin-bottom: 10px;
    .el-row {
      margin-bottom: 10px;
    }
  }
  .cntract-Information {
    font-size: 15px;
  }
  .cell {
    text-align: center;
  }
}
</style>
