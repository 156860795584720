import md5 from 'js-md5';
import Cookies from 'js-cookie'
import store from '../store'
//  市场id
const marketId = store.state.marketId;
const unchangeable = {
  language: 'zh_CN',
  terminal_type: 'SAAS_PC',
  version: 1,
};

export function serverMoudle(params,type=false) {
  let randomKey = Cookies.get('managerRandomKey') || '';
  let Uid = Cookies.get('uid')
  if(params && params.uid){
    Uid = params.uid
  }
  const changeable = {
    marketId, //范围id//江南市场
    scopeId: marketId,
    uid: Uid
  };

  let nObj = Object.assign({}, params, unchangeable, changeable);
  if(type===true){
  //   参数不加密
    nObj ={
      ... params,
      isDecode : true,
      requestNo : new Date().getTime(),
    }
  }
  let BaseTransferEntity = new Object();
    // 这个数据是不是被解密的
    if (!nObj.isDecode) {
      BaseTransferEntity.object = Base64.encode(JSON.stringify(nObj));
      BaseTransferEntity.sign = md5.hex(BaseTransferEntity.object + randomKey);
    } else {
      BaseTransferEntity = nObj
    }
  return BaseTransferEntity
}
