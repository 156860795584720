'use strict';

import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import {MessageBox} from 'element-ui';
import {serverMoudle} from '@/util/fetch'


let instance = axios.create({
  baseURL: process.env.BASE_API,
  timeout: 500000,
  withCredentials: false, //加了这段就可以跨域了
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  let token = Cookies.get('managerToken') || '';
  config.headers['Accept'] = '*/*';
  config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
  if (token) config.headers['Authorization'] = 'Bearer ' + token;
  return config;

}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  response.headers['access'] = '*';
  if (response.data.code == 700) {
    setTimeout(function () {
      window.location.href = '#/login'
    }, 2000);
    MessageBox({
      message: response.data.message,
      type: 'error',
      showConfirmButton: false,
      duration: 5 * 1000
    });
  }
  if(!response.data?.message){
    response.data.message = response.data.msg || ''
  }
  return response;
}, function (error) {

  if (error.response.data.code == 700) {
    setTimeout(function () {
      window.location.href = '#/login'
    }, 2000)
  }
  if (error.response.config.responseType === 'blob' ) {
    let file = new FileReader()
    file.readAsText(error.response.data, 'utf-8')
    file.onload = function() {
      let msg = JSON.parse(file.result)
      MessageBox({
        message: msg.message || msg.results,
        type: 'error',
        showConfirmButton: false,
        duration: 10 * 1000
      })
    }
  } else {
    //  对响应错误做点什么
    MessageBox({
      message: error.response.data.message || error.response.data.results,
      type: 'error',
      showConfirmButton: false,
      duration: 10 * 1000
    })
  }

  return Promise.reject(error);
});


export default instance;








