export default [
  {
    path: 'districtManagement',
    name: 'districtManagement',
    component: () => import('./views/districtManagement'),
    meta: {
      title: '区域管理'
    }
  },
  {
    path: 'accountBindingInformation',
    name: 'accountBindingInformation',
    component: () => import('./views/accountBindingInformation'),
    meta: {
      title: '账号绑定信息'
    }
  },
  {
    path: 'gearBindInfo',
    name: 'gearBindInfo',
    component: () => import('./views/gearBindInfo'),
    meta: {
      title: '商户入驻信息'
    }
  },
  {
    path: 'regionalLabels',
    name: 'regionalLabels',
    component: () => import('./views/regionalLabels'),
    meta: {
      title: '标签管理'
    }
  },
  {
    path: 'regionGearNumberDto',
    name: 'regionGearNumberDto',
    component: () => import('./components/regionGearNumberDto'),
    meta: {
      title: '已删除档口'
    }
  },
]


