export default [{
  path: 'traceability',
  name: 'traceability',
  component: () => import('./views/traceability.vue'),
  meta: {
    title: "溯源管理列表"
  }
},
  {
    path: 'traceabilitydetails',
    name: 'traceabilitydetails',
    component: () => import('./views/traceabilitydetails.vue'),
    meta: {
      title: "溯源详情"
    }
  },
  {
    path: 'traceabilityentry',
    name: 'traceabilityentry',
    component: () => import('./views/traceabilityentry.vue'),
    meta: {
      title: "溯源信息确认"
    }
  },
  {
    path: 'patrolrecords',
    name: 'patrolrecords',
    component: () => import('./views/patrolrecords.vue'),
    meta: {
      title: "巡查记录"
    }
  },
  {
    path: 'patrolrecorddetail',
    name: 'patrolrecorddetail',
    component: () => import('./views/patrolrecorddetail.vue'),
    meta: {
      title: "巡查记录详情"
    }
  },
  {
    path: 'patrolrecorddetaillist',
    name: 'patrolrecorddetaillist',
    component: () => import('./views/patrolrecorddetaillist.vue'),
    meta: {
      title: "巡查记录明细"
    }
  },
  {
    path: 'regionconfig',
    name: 'regionconfig',
    component: () => import('./views/regionConfig.vue'),
    meta: {
      title: "区域配置"
    }
  },
  {
    path: 'regionlog',
    name: 'regionlog',
    component: () => import('./views/regionLog.vue'),
    meta: {
      title: "区域配置日志"
    }
  }
]
