var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo",
              attrs: {
                "default-active": _vm.$route.path,
                router: "",
                mode: "horizontal",
              },
            },
            [
              _c(
                "el-menu-item",
                { attrs: { index: "/marketsTab/ontherMarkets" } },
                [_vm._v("其他市场行情")]
              ),
              _c(
                "el-menu-item",
                { attrs: { index: "/marketsTab/largeScreenDisplaySettings" } },
                [_vm._v("大屏展示设置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view", { attrs: { name: "marketsTabView" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }