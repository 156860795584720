<template>
  <div>
    <div class="b-c-f m-t-10 p-20">
      <el-tabs :tab-position="tabPosition" v-model="componentName">
        <el-tab-pane v-for="(item,index) in roleList" :key="index" :name="item.roleName">
          <div slot="label">
            <span @click="getRoleRermission(index)">{{ item.roleName }}</span>
            <i class="el-icon-edit" @click="openEdit(item.roleId)" v-if="$getPermissionByRole('26')"></i>
            <i class="el-icon-close" @click="openDel(item.roleId)" v-if="$getPermissionByRole('27')"></i>
          </div>
          <permission-list
            v-if="item.roleName === componentName"
            @submit="submitRole"
            :ref="item.roleName"
            :permissionData="permissionData"
            :roleRermission="roleRermission">
            <h3 slot="title">{{ item.roleName }}</h3>
            <el-button
              slot="submit"
              type="success"
              plain
              dataList
              slot-scope="data"
              @click="submitRole(data.dataList)">提交
            </el-button>

          </permission-list>
        </el-tab-pane>
        <el-tab-pane label="添加" name="添加" v-if="$getPermissionByRole('25')">
          <div slot="label">
            <el-button v-if="$getPermissionByRole('26')" type="text" @click="openAdd">添加角色</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="deldetVisible" width="30%" center>
      <div class="prompt">
        <p class="m-b-20">确认删除该角色吗?</p>
        <p class="color87">删除后就没有该角色</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deldetVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteRole">确 认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加角色" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="addForm" style="width: 400px" :rules="rules" ref="addForm">
        <el-form-item label="角色" :label-width="formLabelWidth" prop="roleName">
          <el-input v-model="addForm.roleName" autocomplete="off" placeholder="请输入角色名"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="isEdit" type="primary" @click="editRole">确 定</el-button>
        <el-button v-else type="primary" @click="addRole">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {staff} from '../api'
import permissionList from '../components/permissionList'

export default {
  name: "staffPermission",
  data() {
    return {
      formLabelWidth: "120px",
      deldetVisible: false,
      dialogFormVisible: false,
      tabPosition: 'left',
      permissionData: [],
      roleList: [],
      roleRermission: [],
      componentName: '添加',
      componentIndex: 0,
      roleId: '',
      addForm: {
        roleName: '',
      },
      rules: {
        roleName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},

        ]
      },
      isEdit: false,
    }
  },
  components: {permissionList},
  mounted() {
    this.getNativeData();
    this.getStaffRoleList()
  },
  methods: {
    getNativeData() {
      staff.GET_DNPFUNCTIONLIST().then(res => {
        let data = res.data.results;
        // 排序数组
        data.sort(function (a, b) {
          return a.moduleId - b.moduleId;
        });
        //相同的moduleId合并
        this.permissionData = data.reduce((module, item) => {
          let groupFound = module.find(arrItem => item.moduleId === arrItem.moduleId);
          if (groupFound) {
            if (groupFound.funcId.indexOf(item.funcId) === -1) {  //去重
              groupFound.funcId.push({
                funcId: item.funcId,
                funcName: item.funcName,
                funcCode: item.funcCode,
                moduleId: item.moduleId
              });
            }
          } else {
            //不要直接在原来的对象修改，新建对象
            let newGroup = {
              moduleId: item.moduleId,
              moduleName: item.moduleName,
              funcId: [{
                funcId: item.funcId,
                funcName: item.funcName,
                funcCode: item.funcCode,
                moduleId: item.moduleId
              }]
            };
            module.push(newGroup);
          }
          return module;
        }, []);



      })
    },
    getStaffRoleList() {
      staff.GET_DNPROLELIST().then(res => {
        this.roleList = res.data.results;
        if (this.roleList.length) {
          this.componentName = this.roleList[0].roleName;
          this.getRoleRermission(0)
        }
      })
    },
    getRoleRermission(index) {
      this.componentIndex = index;
      let params = {
        roleId: this.roleList[index].roleId
      };
      staff.GET_DNPROLE_PERMISSIONLIST(params).then(res => {
        this.roleRermission = res.data.results || [];
      })
    },
    submitRole(data) {
      let params = {
        roleId: this.roleList[this.componentIndex].roleId,
        funcList: [],
      };
      data.forEach(item => {
        item.funcId.forEach(func => {
          if (func.effect === 'Y') {
            params.funcList.push(func)
          }
        })
      })

      // return
      staff.SAVE_DNPFUNCTION(params).then(res => {
        this.$message({
          type: 'success',
          message: '添加成功'
        })
      })
    },
    openDel(roleId) {
      this.deldetVisible = true;
      this.roleId = roleId;
    },

    // !删除角色
    deleteRole() {
      let params = {
        roleId: this.roleId,
      };
      staff.DEL_DNPROLE(params).then(res => {
        this.getStaffRoleList();
        this.deldetVisible = false;
      })
    },
    openEdit(roleId) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.roleId = roleId;
    },
    openAdd() {
      this.dialogFormVisible = true;
      this.isEdit = false;
    },

    //! 编辑角色
    editRole() {
      this.$refs.addForm.validate((valid) => {
        let params = {
          roleId: this.roleId,
          roleName: this.addForm.roleName
        };
        staff.EDIT_DNPROLE(params).then(res => {
          this.dialogFormVisible = false;
          this.addForm.roleName = '';
          this.getStaffRoleList();
        })
      })

    },

    // !添加角色
    addRole() {
      this.$refs.addForm.validate((valid) => {
        let params = {
          roleName: this.addForm.roleName
        };
        staff.ADD_DNPROLE(params).then(res => {
          this.dialogFormVisible = false;
          this.getStaffRoleList();
          this.addForm.roleName = '';
        })
      })

    },
  }
}
</script>

<style scoped>
.prompt {
  text-align: center;
  font-size: 18px;
}

.color87 {
  color: #898787;
  font-size: 14px;
}
</style>
