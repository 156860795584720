/**
 * 三轮车管理
 */
export default [
  {
    path: '/tricycle-config-management',
    name: 'TricycleConfigManagement',
    component: () => import('./views/config/index.vue'),
    meta: {
      title: '配置管理',
      permissionCode: '834'
    }
  },
  {
    path: '/tricycle-notice-management',
    name: 'TricycleNoticeManagement',
    component: () => import('./views/notice/index.vue'),
    meta: {
      title: '通知管理',
      permissionCode: '870'
    }
  },
  {
    path: '/tricycle-vehicle-dispatch',
    name: 'TricycleVehicleDispatch',
    component: () => import('./views/vehicle-dispatch/index.vue'),
    meta: {
      title: '车辆调度',
      permissionCode: '835'
    }
  },
  {
    path: '/tricycle-group-management',
    name: 'TricycleGroupManagement',
    component: () => import('./views/group/index.vue'),
    meta: {
      title: '班组管理',
      permissionCode: '836'
    }
  },
  {
    path: '/tricycle-staff-management',
    name: 'TricycleStaffManagement',
    component: () => import('./views/staff/index.vue'),
    meta: {
      title: '人员管理',
      permissionCode: '837'
    }
  }
]
