export const store = {
  // 状态
  state: {
    screenForm: {
      trainNum: '',//车牌号
      collectionStartTime: '',//收款时间
      collectionEndTime: '',//收款时间
      entryTimeStartTime: '',//进场时间
      entryTimeEndTime: '',//出场时间
      payMethod: '',//收款方式
      memberType: '',//会员类型
      payType: '',//收款类型
      payeeId: '',//收款人
      payStatus: '',//收款状态
      sysPayNo:'',//系统支付单号
      payTransactionId:'',//通道支付单号
      current_page: 1,
      page_size: 10,
      bool: true,
    },
    form: {
      trainNum: '',//车牌号
      collectionStartTime: '',//收款时间
      collectionEndTime: '',//收款时间
      incomType: '',//报备情况
      destinationGearName: '',//去往档口
      regionId: '',//去往区域
      payMethod: '',//收款方式
      payType: '',//收款类型
      payeeId: '',//收款人
      payStatus: '',//收款状态
      geaRegion: 'gear',
      current_page: 1,
      page_size: 10,
      bool: true,
    },
    // 时间默认模板
    timeObj: {
      curWeek: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      curYear: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
    },
    //  折现图默认模板
    lineData: {
      name: "总金额",
      type: "line",
      symbolSize: 1,
      data: [],
      itemStyle: {
        color: "rgba(0,0,0,0)"
      },
      lineStyle: {
        color: "rgba(0,0,0,0)"
      },
      label: {
        show: true,
        textStyle: {
          color: "#333",
          fontSize: '16'
        },
        padding: [10, 3],
      },
    },
    //柱状图
    seriesItem: {
      type: 'bar',
      name: '',
      barMaxWidth: 32,
      barMinHeight: 1,
      data: [],
      label: {
        show: true,
        position: 'top',
        color: '#333',
        fontSize: '16'
      },
    }

  },
  // 修改状态
  mutations: {
    SET_SCREEN_FORM: (state, data) => {
      state.screenForm = {...state.screenForm, ...data};
    },
    SET_FORM: (state, data) => {
      state.form = {...state.form, ...data};
    },
  },
  // 异步修改状态
  actions: {},
  namespaced: true
};
