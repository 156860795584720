export const store = {
  // 状态
  state: {
    screenForm: {
      gearNumber: '',
      driverPhone: '',
      regionId: '',
      categoryName: "",
      incomStartTime_start: "",
      incomStartTime_end: "",
      incomEndTime_start: "",
      incomEndTime_end: "",
      aggregateStateList: "",
      page_size: 10,
      current_page: 1,
      trainNum: "",
      tableValue: 'all',
      ownerName: '',//档主
      number: '',//档口号
      trainModel: '',
    },
    form: {
      carNumber: "",//车牌
      carStatus: "",//状态
      firstWeighingStartTime: "",//进场
      firstWeighingEndTime: "",
      secondWeighingStartTime: "",//出场
      secondWeighingEndTime: "",
      goodsType: '',
      current_page: 1,
      page_size: 10,
      bool: true
    }
  },
  // 修改状态
  mutations: {
    SET_SCREEN_FORM: (state, data) => {
      state.screenForm = {...state.screenForm, ...data};
    },
    SET_FORM: (state, data) => {
      state.form = {...state.form, ...data};
    },


  },
  // 异步修改状态
  actions: {},
  namespaced: true
};
