var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "b-c-f m-t-10 p-20" },
        [
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": _vm.tabPosition },
              model: {
                value: _vm.componentName,
                callback: function ($$v) {
                  _vm.componentName = $$v
                },
                expression: "componentName",
              },
            },
            [
              _vm._l(_vm.roleList, function (item, index) {
                return _c(
                  "el-tab-pane",
                  { key: index, attrs: { name: item.roleName } },
                  [
                    _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.getRoleRermission(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.roleName))]
                      ),
                      _vm.$getPermissionByRole("26")
                        ? _c("i", {
                            staticClass: "el-icon-edit",
                            on: {
                              click: function ($event) {
                                return _vm.openEdit(item.roleId)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.$getPermissionByRole("27")
                        ? _c("i", {
                            staticClass: "el-icon-close",
                            on: {
                              click: function ($event) {
                                return _vm.openDel(item.roleId)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    item.roleName === _vm.componentName
                      ? _c(
                          "permission-list",
                          {
                            ref: item.roleName,
                            refInFor: true,
                            attrs: {
                              permissionData: _vm.permissionData,
                              roleRermission: _vm.roleRermission,
                            },
                            on: { submit: _vm.submitRole },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "submit",
                                  fn: function (data) {
                                    return _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "success",
                                          plain: "",
                                          dataList: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitRole(data.dataList)
                                          },
                                        },
                                      },
                                      [_vm._v("提交 ")]
                                    )
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "h3",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(item.roleName))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.$getPermissionByRole("25")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "添加", name: "添加" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm.$getPermissionByRole("26")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.openAdd },
                                },
                                [_vm._v("添加角色")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deldetVisible, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.deldetVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prompt" }, [
            _c("p", { staticClass: "m-b-20" }, [_vm._v("确认删除该角色吗?")]),
            _c("p", { staticClass: "color87" }, [_vm._v("删除后就没有该角色")]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deldetVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteRole } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加角色",
            visible: _vm.dialogFormVisible,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticStyle: { width: "400px" },
              attrs: { model: _vm.addForm, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色",
                    "label-width": _vm.formLabelWidth,
                    prop: "roleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", placeholder: "请输入角色名" },
                    model: {
                      value: _vm.addForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "roleName", $$v)
                      },
                      expression: "addForm.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.isEdit
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.editRole } },
                    [_vm._v("确 定")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addRole } },
                    [_vm._v("确 定")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }