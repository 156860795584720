export default [
  {
    path: 'vehicleManagement',
    name: 'vehicleManagement',
    component: () => import('./views/vehicleManagement'),
    meta: {
      title: "车辆管理"
    }
  },
  {
    path: 'vehicleInfo/:id',
    name: 'vehicleInfo',
    component: () => import('./views/vehicleInfo'),
    meta: {
      title: "车辆管理详情"
    }
  }
]
