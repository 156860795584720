export default [
  {
    path: 'approachPrepare',
    name: 'approachPrepare',
    component: () => import('./views/approachPrepare'),
    meta: {
      title: '进场报备'
    }
  }, {
    path: 'approachPrepare/trainInfo/:incomTid',
    name: 'trainInfo',
    component: () => import('./views/trainInfo'),
    meta: {
      title: '进场报备详情'
    }
  },
  {
    path: 'approachPrepare/trainInfoOptimization',
    name: 'trainInfoOptimization',
    component: () => import('./views/trainInfoOptimization'),
    meta: {
      title: '进场报备详情'
    }
  },
  {
    path: 'approachPrepare/ReportInformation',
    name: 'ReportInformation',
    component: () => import('./views/ReportInformation')
  },
  {
    path: 'tabJournaling',
    name: 'tabJournaling',
    component: () => import('./views/tabJournaling'),
    meta: {
      title: '报备数据报表'
    }
  },
  {
    path: 'reportTheWarning',
    name: 'reportTheWarning',
    component: () => import('./views/reportTheWarning'),
    meta: {
      title: '来货净重核查'
    }
  },
  {
    path: 'netWeight',
    name: 'netWeight',
    component: () => import('./views/netWeight'),
    meta: {
      title: '来货净重查询'
    }
  },
  {
    path: 'netWeightInfo/:gid/:appearanceTime_start/:appearanceTime_end',
    name: 'netWeightInfo',
    component: () => import('./views/netWeightInfo'),
    meta: {
      title: '来货净重详情'
    }
  },
  {
    path: 'reportConfiguration',
    name: 'reportConfiguration',
    component: () => import('./views/reportConfiguration'),
    meta: {
      title: '报备设置'
    }
  },
  {
    path: 'purchasingInformation',
    name: 'purchasingInformation',
    component: () => import('../purchasingInformation/views/purchasingInformation'),
    meta: {
      title: '采购过磅'
    }
  },
  {
    path: 'InformationDetails',
    name: 'InformationDetails',
    component: () => import('../purchasingInformation/views/InformationDetails'),
    meta: {
      title: '收费详情'
    }
  },{
    path: 'merchantIncomingSummaryReport',
    name: 'merchantIncomingSummaryReport',
    component: () => import('./views/merchantIncomingSummaryReport'),
    meta: {
      title: '商户来货汇总报表'
    }
  },{
    path: 'inReg',
    name: 'inReg',
    component: () => import('./views/inReg'),
    meta: {
      title: '防疫报备'
    }
  }, {
    path: 'UserInformationReview',
    name: 'UserInformationReview',
    component: () => import('./views/UserInformationReview'),
    meta: {
      title: '用户信息审核'
    }
  },
  {
    path: 'epManage',
    name: 'epManage',
    component: () => import('./views/epManage'),
    meta: {
      title: '人员打卡记录'
    }
  },
  {
    path: 'epManageList',
    name: 'epManageList',
    component: () => import('./views/epManageList'),
    meta: {
      title: '人员管理列表'
    }
  },
  {
    path: 'dataAuthManagement',
    name: 'dataAuthManagement',
    component: () => import('./views/dataAuthManagement'),
    meta: {
      title: '防疫信息授权管理'
    }
  },
  {
    path: 'regRuleConfig',
    name: 'regRuleConfig',
    component: () => import('./views/regRuleConfig'),
    meta: {
      title: '报备规则配置'
    }
  },

  {
    path: 'welcomeLogin',
    name: 'welcomeLogin',
    component: () => import('./views/welcomeLogin'),
    meta: {
      title: '欢迎页'
    }
  },
  {
    path: 'nodeQuery',
    name: 'nodeQuery',
    component: () => import('./views/nodeQuery'),
    meta: {
      title: '节点查询'
    }
  },
  {
    path: 'traceabilityQuery',
    name: 'traceabilityQuery',
    component: () => import('./views/traceabilityQuery'),
    meta: {
      title: '溯源查询'
    }
  },

]


