export default [
  {
    path: 'chargingRule',
    name: 'chargingRule',
    component: () => import('./view/chargingRule.vue'),
    meta: {
      title: '收费规则管理'
    }
  },
]
