var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        { staticClass: "vuedraggable" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "排序",
                visible: _vm.dialogVisible,
                width: "27%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "vuedraggable",
                    {
                      attrs: {
                        tag: "el-collapse",
                        list: _vm.copyList,
                        "ghost-class": "d-none",
                        move: _vm.onMove,
                      },
                      on: { start: _vm.start, end: _vm.end },
                    },
                    _vm._l(_vm.copyList, function (item, index) {
                      return _c(
                        "el-collapse-item",
                        {
                          key: index,
                          attrs: { title: item.regionName, name: index },
                        },
                        [
                          _c(
                            "vuedraggable",
                            {
                              staticClass: "list-group",
                              attrs: {
                                tag: "ul",
                                "ghost-class": "d-none",
                                move: _vm.onMove,
                              },
                              on: {
                                start: function ($event) {
                                  return _vm.onStart(index, item)
                                },
                                end: _vm.onEnd,
                              },
                              model: {
                                value: item.numberIndexList,
                                callback: function ($$v) {
                                  _vm.$set(item, "numberIndexList", $$v)
                                },
                                expression: "item.numberIndexList",
                              },
                            },
                            [
                              _c(
                                "transition-group",
                                {
                                  attrs: {
                                    type: "transition",
                                    name: "flip-list",
                                  },
                                },
                                _vm._l(
                                  item.numberIndexList,
                                  function (lign, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: Math.random(),
                                        staticClass: "list-group-item",
                                      },
                                      [_vm._v(" " + _vm._s(lign.number) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getdetermineSort },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }