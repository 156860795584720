export default [
  {
    path: 'couponRecords',
    name: 'couponRecords',
    component: () => import('./views/couponRecords'),
    meta: {
      title: '优惠券记录'
    }
  }, {
    path: 'couponsReport',
    name: 'couponsReport',
    component: () => import('./views/couponsReport'),
    meta: {
      title: '统计报表'
    }
  }, {
    path: 'couponExchange',
    name: 'couponExchange',
    component: () => import('./views/couponExchange'),
  }, {
    path: 'couponExchangeRecords',
    name: 'couponExchangeRecords',
    component: () => import('./views/couponExchangeRecords'),
    meta: {
      title: '优惠券兑换记录'
    }
  }, {
    path: 'exchangeDetails',
    name: 'exchangeDetails',
    component: () => import('./views/exchangeDetails'),
  },
]
