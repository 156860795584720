<template>
  <div class="vuedraggable" v-if="dialogVisible">
    <el-dialog title="排序" :visible.sync="dialogVisible" width="27%" :before-close="handleClose">
      <div>
        <vuedraggable tag="el-collapse" :list="copyList" ghost-class="d-none" @start="start" @end="end" :move="onMove">
          <el-collapse-item
            v-for="(item, index) in copyList"
            :key="index"
            :title="item.regionName"
            :name="index"
          >
            <vuedraggable
              class="list-group"
              tag="ul"
              ghost-class="d-none"
              v-model="item.numberIndexList"
              :move="onMove"
              @start="onStart(index, item)"
              @end="onEnd"
            >
              <transition-group type="transition" name="flip-list">
                <li
                  class="list-group-item"
                  v-for="(lign, index) in item.numberIndexList"
                  :key="Math.random()"
                >
                  {{ lign.number }}
                </li>
              </transition-group>
            </vuedraggable>
          </el-collapse-item>
        </vuedraggable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="getdetermineSort">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import vuedraggable from 'vuedraggable'
import { marketplan } from '../api/api'

export default {
  components: {
    vuedraggable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      copyList: [],
      listDate: [],
      copyPremiseIndex: -1,
      copyPremiseIndexList: [],
      numberIndexList: [],
      dragElm: null,
      IndexList: [],
      ParentCmponent: []
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getRegionNumberPremiseIndexList()
        this.listDate = []
        this.IndexList = []
      }
    }
  },
  methods: {
    start(){
      this.list = this.copyList.slice(0)
    },
    end({ oldIndex, newIndex }) {
      const item = this.list[oldIndex]
      this.list.splice(oldIndex, 1, this.list[newIndex])
      this.list.splice(newIndex, 1, item)
      if(this.dragElm){
        this.dragElm.style.background = ''
        this.dragElm = null
      }

      const cache = this.list.slice(0)
      let move = cache[newIndex].premiseIndex
      let cover = cache[oldIndex].premiseIndex
      this.list[newIndex].premiseIndex = cover //换位置
      this.list[oldIndex].premiseIndex = move //被拖动

      // console.log(111, this.copyList, list)


      this.listDate.push(this.list[newIndex], this.list[oldIndex])
      this.listDate = [...new Set(this.listDate)]

      this.copyList = this.list.slice(0)
    },

    onStart(index, item) {
      this.copyPremiseIndex = index
      this.copyPremiseIndexList = this.copyList[index].numberIndexList
      this.listDate.push(this.copyList[index])
      this.listDate = [...new Set(this.listDate)]
    },
    onEnd({ oldIndex, newIndex }, e) {
      const item = this.copyPremiseIndexList[oldIndex]
      this.copyPremiseIndexList.splice(oldIndex, 1, this.copyPremiseIndexList[newIndex])
      this.copyPremiseIndexList.splice(newIndex, 1, item)

      this.numberIndexList = this.copyPremiseIndexList
      this.copyPremiseIndexList = this.numberIndexList.slice(0)

      this.copyList[this.copyPremiseIndex].numberIndexList = this.copyPremiseIndexList
      this.copyPremiseIndex = -1
      if(this.dragElm){
        this.dragElm.style.background = ''
        this.dragElm = null
      }
      const cache = this.copyPremiseIndexList.slice(0)
      let move = cache[newIndex].premiseIndex
      let cover = cache[oldIndex].premiseIndex
      // let move = this.copyPremiseIndexList[newIndex].premiseIndex
      // let cover = this.copyPremiseIndexList[oldIndex].premiseIndex
      this.copyPremiseIndexList[newIndex].premiseIndex = cover
      this.copyPremiseIndexList[oldIndex].premiseIndex = move
      this.IndexList.push(this.copyPremiseIndexList[newIndex], this.copyPremiseIndexList[oldIndex])
      this.IndexList = [...new Set(this.IndexList)]
    },
    onMove(evt, originalEvent) {
      if (evt.related) {
        if (evt.related.parentNode.children.length) {
          for(let i = 0; i < evt.related.parentNode.children.length; i++){
            evt.related.parentNode.children[i].style.background = ''
            const collapseElm = evt.related.parentNode.children[i].getElementsByClassName('el-collapse-item__header')
            if(collapseElm.length){
              collapseElm[0].style.background = ''
            }
          }
        }
        evt.related.style.background = '#cccccc'
        this.dragElm = evt.related
        const collapseElm = evt.related.getElementsByClassName('el-collapse-item__header')
        if(collapseElm.length){
          console.log(111, collapseElm)
          collapseElm[0].style.background = '#cccccc'
          this.dragElm = collapseElm[0]
        }
      }
    },
    getRegionNumberPremiseIndexList() {
      marketplan
        .getRegionNumberPremiseIndexList()
        .then(result => {
          this.copyList = result.data.results
          // this.copyList = this.list.slice(0)
        })
        .catch(err => {})
    },
    handleClose() {
      this.$emit('dargList', false)
    },
    getdetermineSort() {
      let regionPremiseIndexList = [] //区域
      let numberPremiseIndexList = [] //档口
      this.listDate.forEach(item => {
        regionPremiseIndexList.push({
          regionId: item.regionId,
          premiseIndex: item.premiseIndex
        })
      })
      this.IndexList.forEach(i => {
        numberPremiseIndexList.push({
          mrgId: i.mrgId,
          premiseIndex: i.premiseIndex
        })
      })
      let obj = {
        regionPremiseIndexList,
        numberPremiseIndexList
      }
      console.log(obj, '==obj')
      marketplan
        .getinstallRegionNumberPremiseIndex({ ...obj })
        .then(result => {
          if (result.data.status === 'N') {
            this.$message.error('排序失败')
            this.handleClose()
          } else {
            this.handleClose()
            this.$message({
              message: '排序成功!',
              type: 'success'
            })
          }
        })
        .catch(err => {})
    }
  }
}
</script>
<style lang="scss">
.vuedraggable {
  .list-group-item {
    border: 0px;
    border-bottom: 1px solid #e6e6e6;
    background: #f2f4f7;
    cursor: pointer;
  }
  .el-collapse-item__content {
    padding: 0px;
  }
  .el-dialog__body {
    padding: 30px 0px;
  }
  .el-collapse-item__header {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 550;
  }
  .list-group-item {
    padding-left: 40px;
  }
  .d-none {
    display: none;
  }
}
</style>
