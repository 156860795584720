export default [
  {
    path: 'coldChargingReport',
    name: 'coldChargingReport',
    component: () => import('./view/index.vue'),
    meta: {
      title: '打冷收费报表'
    }
  },
]
