export default [
  {
    path: 'stallCharge',
    name: 'stallCharge',
    component: () => import('../costManagement/views/stallCharge.vue'),
    meta: {
      title: '档口收费'
    }
  },
  {
    path: 'feeList',
    name: 'feeList',
    component: () => import('../costManagement/views/feeList.vue'),
    meta: {
      title: '费用列表'
    }
  },
  {
    path: 'arrears',
    name: 'arrears',
    component: () => import('../costManagement/views/arrears.vue'),
    meta: {
      title: '欠费缴费'
    }
  },
  {
    path: 'batchCreateStallCharge',
    name: 'batchCreateStallCharge',
    component: () => import('../costManagement/views/batchCreateStallCharge.vue'),
    meta: {
      title: '费用列表管理'
    }
  },
  {
    path: 'createStallCharge',
    name: 'createStallCharge',
    component: () => import('../costManagement/views/createStallCharge.vue'),
    meta: {
      title: '批量创建'
    }
  },
  {
    path: 'ChargesSetup',
    name: 'ChargesSetup',
    component: () => import('../costManagement/views/ChargesSetup.vue'),
    meta: {
      title: '收费项管理'
    }
  },{
    path: 'hydropower',
    name: 'hydropower',
    component: () => import('../costManagement/views/hydropower.vue'),
    meta: {
      title: '水电抄表'
    }
  },
]
