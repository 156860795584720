export default [
  {
    path: "/fullScreenDataIndex",
    name: "fullScreenDataIndex",
    component: () => import('./views/fullScreenDataIndex.vue'),
  },
  {
    path: "/fullScreenDataTradingMarket",
    name: "fullScreenDataTradingMarket",
    component: () => import("./components/componentView/fullScreenDataTradingMarket.vue"),
    meta: {
      title: "交易数据"
    }
  },
  {
    path: "/fullScreenDataApproachPrepare",
    name: "fullScreenDataApproachPrepare",
    component: () => import("./components/componentView/fullScreenDataApproachPrepare.vue"),
    meta: {
      title: "市场报备"
    }
  },
  {
    path: "/schedulingWarp",
    name: "schedulingWarp",
    component: () => import("./components/componentView/schedulingWarp.vue"),
    meta: {
      title: "市场价格对比"
    }
  },
  {
    path: "/reportedTrainsWarp",
    name: "reportedTrainsWarp",
    component: () => import("./components/componentView/reportedTrainsWarp.vue"),
    meta: {
      title: "市场报备信息"
    }
  },
  {
    path: "/marketReportedWarp",
    name: "marketReportedWarp",
    component: () => import("./components/componentView/marketReportedWarp.vue"),
  },
  {
    path: "/KDFullScreenData",
    name: "KDFullScreenData",
    component: () => import("./components/componentView/KDFullScreenData.vue"),
    meta: {
      title: "进出场称重收费数据"
    }
  }
];
