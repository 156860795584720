<template>
  <div class="titleBar  f-s-14 c-3">
    <div class='leftT'>
      <div class="appName click">
      <span class="loge">
        <img :src="MarketLogo">
      </span>
      <span>市场管理平台</span>

    </div>
      <div class="ub-f1">&nbsp;</div>
    </div>
    <!--    <div class="userName click" v-if="userName">{{ userName }}</div>-->
    <!--    <div class="userName click" v-else>超级管理员</div>-->
    <div class='rightU'>
    <el-dropdown>
      <span class="el-dropdown-link">
        <div class="userName click" v-if="userName">
          {{ userName }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <div class="userName click" v-else>
          超级管理员
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>

      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div style="line-height: 40px" @click="toChangePassword">
            <span>修改密码</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
      <div class=" m-l-30 click exit" @click="goLogin">
        <i class="iconfont">&#xe676;</i>
        退出
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      userName: '',
      MarketLogo:'',
    }
  },
  created() {
    this.userName = Cookies.get('userName');
    this.MarketLogo =require( process.env.LOGO_IMG )
  },
  methods: {

    toChangePassword() {

      this.$router.push({
        name: "changePassword",
      });
    },

    goLogin() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        let q = keys;
        q.forEach(function (value) {
          Cookies.remove(value);
        });
      }
      this.$router.push({name: 'Login'});
      location.reload();
    },
  },
}
</script>
<style scoped lang="scss">
.tab .el-header{
  padding-right: 20px !important;
  box-sizing: border-box;
}
.titleBar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  .rightU{
    display: flex;
  }
}

div {
  line-height: 60px;
}

.appName {
  width: 185px;
  line-height: 60px;
  background: #001529;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 2px;

  .loge {
    //background-image: url("../assets/tab/com_loog.png");
    img {
      width: 36px;
      height: 36px;
      margin-right: 14px;
    }
  }
}

.userName {
  background-image: url(../assets/titleBar/com_gerenzhongxin_icon.png);
  background-repeat: no-repeat;
  background-size: 24px 23px;
  background-position: left center;
  padding-left: 34px;
}

.exit {
  color: #05AE09;
}

.el-dropdown-link {
  cursor: pointer;
  /*color: #409EFF;*/
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
