<template>
  <div class="b-c-f m-t-10 page-warp page-warph">
    <el-row>
      <!--      <el-col class="m-t-10">-->
      <!--        大屏展示货品数据取值来源：-->
      <!--        <el-radio-group v-model="displayStatus" @change="handleDisplayData">-->
      <!--          <el-radio :disabled="!$getPermissionByRole('35')" label="N">系统实时统计数据</el-radio>-->
      <!--          <el-radio :disabled="!$getPermissionByRole('35')" label="Y">人工采集数据</el-radio>-->
      <!--        </el-radio-group>-->
      <!--      </el-col>-->
      <el-col class="m-t-10">
        大屏展示价格比较市场：
        <span>已选{{ marketTotal }}个市场</span>
        <el-button type="success" @click="showMarketFormVisible = true">设置大屏展示市场</el-button>
        <el-button type="success" @click="toPush('largeScreenSort')">大屏展示排序</el-button>
      </el-col>
      <el-col class="m-t-10">
        大屏展示货品：
        <span>已选{{ goodsNum }}种</span>
        <el-button type="success" @click="showGoodsVisible = true">设置大屏展示货品</el-button>
      </el-col>
      <el-col class="m-t-10">
        大屏价格预警设置：
        <span>{{ warningParams.warnValue }}%</span>
        <el-button
          v-if="$getPermissionByRole('36')"
          type="success"
          @click="showWarningVisible = true"
          >设置</el-button
        >
      </el-col>
      <el-col class="m-t-10">
        大屏库存数据设置：
        <span>上传数据</span>
        <el-button type="success" @click="getSettings">设置</el-button>
        <el-button type="success" @click="getview">查看</el-button>
      </el-col>

    </el-row>
    <!--库存数据设置-->
    <el-dialog
      width="600px"
      :before-close="dialogInventoryClose"
      :visible.sync="showInventoryVisible"
      title="大屏库存数据设置"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="数据类型" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio
                v-for="(item, index) in stockTypeList"
                :key="index"
                :label="item.value"
                @change="getckhe"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="选择上传数据"
            prop="uploadMessage"
            class="filefrom"
            v-if="ruleForm.resource === 2"
          >
            <!-- <el-input w v-model="fileName" :disabled="true"></el-input> -->
            <el-input
              placeholder="点击上传文件"
              v-model="ruleForm.uploadMessage"
              :disabled="true"
            ></el-input>
            <input
              id="imFile"
              class="imFile"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.pdf"
              @change="importExcel()"
            />
            <el-button type="text" @click="getImport">导入模板</el-button>
          </el-form-item>
        </el-form>
        <div>
          说明： <br />
          1. 报备数据：通过报备数据的报备重量计算货品调度、数据大屏的库存信息；<br />
          2. 鲜道赢入库批次：通过统计鲜道赢入库货品重量计算货品调度、数据大屏的库存信息；<br />
          3. 上传数据：按上传的数据提供货品调度、数据大屏的库存信息；
        </div>
      </div>
      <div slot="footer" class="lnventoryVisible-footer">
        <el-button @click="showInventoryVisible = false">取 消</el-button>
        <el-button type="primary" @click="getStockType" :loading="loadingupd">确 定</el-button>
      </div>
    </el-dialog>
    <!--库存数据-->
    <el-dialog title="库存数据" :visible.sync="lnventorydata">
      <el-table :data="list" border style="width: 100%">
        <el-table-column type="index" width="100" label="序号"></el-table-column>
        <el-table-column property="categoryName" label="品类"></el-table-column>
        <el-table-column property="weight" label="吨数"></el-table-column>
        <el-table-column property="regionName" label="区域名称"></el-table-column>
      </el-table>
    </el-dialog>
    <!--导出数据弹窗-->
    <el-dialog title="批量导入结果" :visible.sync="dialogTableVisible">
      <h4 style="color: #008b5b">批量导入成功共:{{ gridData.success }}</h4>
      <h4 style="color: #f53f3f">批量导入失败共:{{ gridData.failure }}</h4>
      <el-table :data="gridData.importResultVoList">
        <el-table-column property="failureReason" label="原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--展示市场-->
    <el-dialog
      width="600px"
      :before-close="dialogBeforeClose"
      :visible.sync="showMarketFormVisible"
      title="设置大屏展示市场">
      <div>
        <ul>
          <div class="d-flex market-list">
            <div style="width: 240px">市场</div>
            <div>大屏展示市场</div>
          </div>
          <div v-for="(market,index) in followMarketList"
               class="market-list">
            <el-select
              disabled
              class="m-r-20"
              style="width: 240px"
              v-model="market.mid"
              @change="handleSelect(market,index)"
              placeholder="请选择市场">
              <el-option
                v-for="item in followMarketList"
                :key="item.mid"
                :label="item.marketName"
                :value="item.mid"
              ></el-option>
            </el-select>

            <el-switch
              v-model="market.status"
              active-color="#45c281"
              inactive-color="#d5d5d5"
              @change="setStatus(market)"
              active-value="Y"
              inactive-value="N">
            </el-switch>
          </div>
          <div class="textCenter">
            <el-button class="m-r-20 btn-focus" @click="showMarketFormVisible = false">取消</el-button>
            <el-button class="m-l-20 btn-focus" type="success" @click="setMarketList">保存</el-button>
          </div>

        </ul>


      </div>
    </el-dialog>

    <!--设置货品-->
    <el-dialog
      width="600px"
      :close="dialogBeforeCloseGoods"
      :visible.sync="showGoodsVisible"
      title="设置大屏展示货品">
      <div class="tree-box">
        <ul style="max-height: 600px;overflow:auto;">
          <div class="d-flex market-list">
            <el-input v-model="goodsName" @input="changeInput" placeholder="请输入搜索货品"></el-input>
          </div>
          <div v-for="(market,index) in tableData"
               class="market-list">
            <el-select
              disabled
              class="m-r-20"
              style="width: 240px"
              v-model="market.goodsName"
              placeholder="请选择市场">
              <el-option
                v-for="item in followMarketList"
                :key="item.mid"
                :label="item.marketName"
                :value="item.mid"
              ></el-option>
            </el-select>

            <el-switch
              v-model="market.showStatus"
              active-color="#45c281"
              inactive-color="#d5d5d5"
              @change="setGoods(market)"
              v-if="$getPermissionByRole('37')"
              active-value="Y"
              inactive-value="N">
            </el-switch>
          </div>

        </ul>
        <!--        <div class="textCenter">-->
        <!--          <el-button class="m-r-20 btn-focus" @click="showGoodsVisible = false">取消</el-button>-->
        <!--          <el-button class="m-l-20 btn-focus" type="success" @click="setShowGoodList">保存</el-button>-->
        <!--        </div>-->
      </div>
    </el-dialog>

    <!--大屏预警设置-->
    <el-dialog
      width="600px"
      @close="dialogBeforeCloseWarn"
      :visible.sync="showWarningVisible"
      title="大屏价格预警设置">
      <div>
        <ul>
          <el-form>
            <el-form-item label="对比市场" label-width="100px" style="width: 400px">
              <el-select
                style="width: 300px"
                v-model="warningParams.warnMarketId"
                placeholder="请选择市场">
                <el-option
                  v-for="item in followMarketList"
                  :key="item.mid"
                  :label="item.marketName"
                  :value="item.mid"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="设置预警值" label-width="100px" style="width: 400px">
              <el-input placeholder="请输入内容" type="number" v-model="warningParams.warnValue">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>

          </el-form>

          <div class="textCenter">
            <el-button class="m-r-20 btn-focus" @click="showWarningVisible = false">取消</el-button>
            <el-button class="m-l-20 btn-focus" type="success" @click="setMarketWarn">保存</el-button>
          </div>

        </ul>


      </div>
    </el-dialog>

  </div>
</template>

<script>
import { ontherMarkets } from '../api'
import axios from 'axios'
import Cookies from 'js-cookie'

import { mapState } from 'vuex'

export default {
  name: "largeScreenDisplaySettings",
  data() {
    return {
      loadingupd:false,
      list: [],
      stockTypeList: [],
      nowTime: '',
      dialogTableVisible: false,
      file: {},
      fileName: '',
      ruleForm: {
        resource: null,
        uploadMessage: null
      },
      rules: {
        uploadMessage: [{ required: true, message: '请上传文件', trigger: 'blur' }]
      },
      gridData: {},
      showInventoryVisible: false,
      warningParams: {
        warnMarketId: '',
        warnValue: ''
      },
      mode: "transfer", // transfer addressList
      title: ['全部品类', '大屏展示品类'],
      fromData: [],
      toData: [],
      showWarningVisible: false,
      displayStatus: "N",
      showGoodsVisible: false,
      followMarketList: [],
      goodsName: '',
      tableData: [],
      goodsList: [],
      showMarketFormVisible: false,
      marketTotal: 0,
      did: '',
      goodsNum: 0,
      restData: [],
      params: {
        mid: this.marketId,
        type: 'goodsName',
        page_size: 10000,
        current_page: 1
      },
    }
  },
  computed: {
    ...mapState({
      marketId: (state) => state.marketId,
    }),
  },
  components: {},
  created() {
    this.getFocusMarketList();
    this.getDisplayStatus();
    this.getShowGoods();
    this.getGoodType();
    this.getMarketWarnValue()
  },
  methods: {
    async getview() {
      this.lnventorydata = true
      this.getFindCateGoryStockList()
    },
    async getFindCateGoryStockList() {
      let res = await ontherMarkets.getFindCateGoryStockList({ marketId: this.marketId })
      this.list = res.data.results.data || []
    },
    getSettings() {
      ontherMarkets.getFindCate({ marketId: this.marketId }).then((result) => {

     this.ruleForm.uploadMessage = Cookies.get('uploadMessage') || null

      this.ruleForm.resource =result.data.results
      this.getStockTypeList()
      this.showInventoryVisible = true
      })

    },
    getckhe(value) {
      //
    },
    async getStockTypeList() {
      let res = await ontherMarkets.getStockTypeList({ marketId: this.marketId })
      this.stockTypeList = res.data.results
    },
    async getStockType() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await ontherMarkets.getStockType({
            marketId: this.marketId,
            stockType: this.ruleForm.resource
          })
          if (res.data.error_code === 200) {
            this.$message({
              message: '操作成功!',
              type: 'success'
            })
            this.showInventoryVisible = false
          }
        }
      })
    },
    // 通过onchanne触发方法获得文件列表
    async importExcel() {
      const obj = event.target
      let wb
      const rABS = false
      if (!obj.files) {
        return
      }
      const f = obj.files[0]
      this.file = f
      this.ruleForm.uploadMessage = f.name
      // feeConfig.geuploadFile().then((res))
      // const { file } = this.file

      obj.value = ''
      const formData = new FormData()
      this.getNowDate()
      formData.append('file', this.file)
      formData.append('operatorId', Cookies.get('uid'))
      formData.append('marketId', this.marketId)
      formData.append('importTime', this.nowTime)
      Cookies.set('uploadMessage',this.ruleForm.uploadMessage)
      let token = Cookies.get('managerToken') || ''
      this.loadingupd=true
      axios
        .post(process.env.BASE_API + 'stockType/importExcel?', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token ? 'Bearer ' + token : '' // 请求头的规则，用你们后台给你的就是你
          }
        })
        .then((result) => {
          if (result.data.error_code === 200) {
            this.dialogTableVisible = true
            this.gridData = result.data.results
            this.loadingupd=false
            // this.$message({
            //   message: result.data.results,
            //   type: 'success'
            // })
          } else {
            this.$message.error(result.data.results)
          }
        })
        .catch((err) => {
          //
        })
    },
    getNowDate() {
      var date = new Date()
      var sign2 = ':'
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1 // 月
      var day = date.getDate() // 日
      var hour = date.getHours() // 时
      var minutes = date.getMinutes() // 分
      var seconds = date.getSeconds() //秒

      this.nowTime = year + '-' + month + '-' + day + ' ' + hour + sign2 + minutes + sign2 + seconds
    },
    getImport() {
        window.location.href = 'https://minio.52cfzy.com/market-bucket/public/template/大屏 - 库存数据模板V1.0.xlsx'
    },
    dialogInventoryClose() {
      this.showInventoryVisible = false
    },
    uploadError(e, file, fileList) {
      try {
        const res = JSON.parse(e.message)
        this.$message({
          message: res.message || '发生错误',
          type: 'error'
        })
      } catch (error) {
        console.log(error)
      }
    },
    uploadSuccess(response, file, fileList) {
      this.fileName = fileList.slice(-1)[0].name
      const formData = new FormData()
      formData.append('file', file.raw)
      this.file = formData
    },
    getList() {
      ontherMarkets.DNP_FIND_DNPMARKET_PRICE_VALUE({...this.params, mid: this.marketId}).then(response => {
        if (response.data.status === "Y") {
          this.tableData = response.data.results.dnpMarketPriceValueList;
          this.goodsList = response.data.results.dnpMarketPriceValueList;

          this.total = response.data.results.total;
          this.loading = false;
        }
      });
    },
    setMarketWarn() {
      let {warnMarketId, warnValue} = this.warningParams;
      if (!warnMarketId || !warnValue) {
        return this.$message({
          type: 'error',
          message: '请完善数据'
        })
      }
      ontherMarkets.SET_WARNING_SETTINGS({warnMarketId, warnValue}).then(res => {

        this.showWarningVisible = false;
        this.$message({
          type: 'success',
          message: '修改成功',
        })
      }).finally(res => {

      })
    },

    dialogBeforeCloseWarn() {
      this.getMarketWarnValue();
      this.showWarningVisible = false;
    },

    getMarketWarnValue() {
      ontherMarkets.GET_WARNING_SETTINGS({}).then(res => {
        let {warnMarketId, warnValue} = res.data.results;
        Object.assign(this.warningParams, {warnMarketId, warnValue})
      })
    },


    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      this.mode = this.mode === "transfer" ? 'addressList' : 'transfer';
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的        {keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表

      this.toData = toData;
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表

      this.toData = toData;
    },


    setShowGoodList() {
      let showCategoryList = [];
      this.toData.map(item => {
        item.childrens.map(goods => {
          goods.childrens.map(category => {
            let {categoryId, categoryName} = category;
            showCategoryList.push({categoryId, categoryName})
          })
        })
      });
      if (!showCategoryList.length) {
        return this.$message({
          type: 'error',
          message: '请选择货品'
        })
      }

      let params = {
        showCategoryList
      };
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      ontherMarkets.ADD_SHOW_MARKET_CATEGORY(params).then(res => {
        this.showGoodsVisible = false;
        this.getShowGoods();
        this.getDisplayStatus();
        this.$message({
          type: 'success',
          message: '添加成功',
        })
      }).finally(res => {
        loading.close()
      })

    },


    //  展示货品数据
    getShowGoods() {
      ontherMarkets.SHOW_MARKET_CATEGORY().then(res => {
        this.toData = res.data.results;

      })
    },


    // 获取树类型结构
    getGoodType() {
      ontherMarkets.getGoodCategoryTree().then(response => {
        this.fromData = response.data.results;

        this.fromData.forEach(item => {
          item.categoryId += '';
          item.parentId += '';
          item.pid = item.parentId;
          item.childrens.forEach(children => {
            children.categoryId += '';
            children.parentId += '';
            children.pid = children.parentId;
            children.childrens.forEach(goods => {
              goods.categoryId += '';
              goods.parentId += '';
              goods.pid = goods.parentId;
            })
          })

        });

        this.restData = JSON.parse(JSON.stringify(this.fromData))
      });
    },

    dialogBeforeCloseGoods() {
      this.fromData = JSON.parse(JSON.stringify(this.restData));

    },


    getDisplayStatus() {
      ontherMarkets.GET_MARKET_DISPLAY_SETTING({}).then(res => {
        let {did, displayStatus, goodsNum} = res.data.results;
        this.did = did;
        this.displayStatus = displayStatus;
        this.goodsNum = goodsNum || 0;
      })
    },

    //大屏排序页面
    toPush(data) {
      this.$router.push(
        {
          name: data,
          params: {}
        }
      )
    },

    //大屏数据展示
    handleDisplayData() {
      let {displayStatus, did} = this;
      let params = {
        displayStatus,
        did
      };
      ontherMarkets.SET_MARKET_DISPLAY_SETTING(params).then(res => {

      })
    },

    setStatus(item) {

      item.operatingType = 'modify'
    },
    setGoods(item) {
      let params = {
        showStatus: item.showStatus,
        goodsNameId: item.goodsNameId
      };
      ontherMarkets.DNP_SET_SHOW_DNPMARKET_PRICE_VALUE(params).then(res => {

        if (res.data.results) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getList()
        } else {
          this.$message({
            type: "info",
            message: "修改失败!",
          });
        }

      }).catch(err => {
        this.$message({
          type: "info",
          message: "修改失败!",
        });
      })
    },

    changeInput(val) {

      if (!val) return this.tableData = this.goodsList;
      let arr = []
      this.goodsList.map(item => {

        return item.goodsName.indexOf(val) !== -1 ? arr.push(item) : ''
      })
      this.tableData = arr;

    },

    //改变市场列表
    setMarketList() {
      let followMarketList = this.followMarketList;
      if (!followMarketList.length) {
        return this.$message({
          type: 'error',
          message: '请先选择关注市场'
        })
      }
      let params = {
        followMarketList,

      };

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      ontherMarkets.ADD_FOLLOW_MARKET(params).then(res => {
        loading.close();
        let {error_code, results} = res.data, type = error_code === 200 ? 'success' : "error";
        this.$message({
          type,
          message: results
        });
        if (error_code === 200) this.getFocusMarketList();
        this.showMarketFormVisible = false;
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        });
        loading.close()
      })
    },

    getFocusMarketList() {
      ontherMarkets.GET_FOLLOW_MARKET().then(res => {
        this.marketTotal = 0;
        let data = res.data.results || [];

        this.params.marketId = data[0].mid;
        this.getList();
        this.followMarketList = JSON.parse(JSON.stringify(data));
        this.followMarketList.map(item => {
          if (item.status === 'Y') {
            ++this.marketTotal;
          }
        })
      })
    },

    dialogBeforeClose() {
      this.getFocusMarketList();
      this.showMarketFormVisible = false;
    }
  }

}
</script>

<style lang="scss" scoped>
.page-warph {
  .imFile {
    width: 74px;
  }
  .lnventoryVisible-footer {
    text-align: center;
    .el-button {
      width: 140px;
    }
  }
  .market-list {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 10px;
  }

.btn-focus {
  margin-top: 10px;
  width: 150px;
}

.page-warp {
  min-height: 500px;
  padding: 10px 5px;
}

.tree-box {
  //ul{
  //  height: 800px;
  //  overflow: auto;
  //}

}

  > .tree-box .el-tree {
    display: block !important;
  }
  .filefrom {
    .el-input.is-disabled .el-input__inner,
    .el-input {
      width: 250px;
    }
  }
}
</style>
