import staff from './index'
import staffList from './views/staffList.vue';
import staffPermission from './views/staffPermission.vue';

export default [{
  path: 'staff',
  name: 'staff',
  component: staff,
  redirect: '/staff/staffList',
  children: [
    {
      path: 'staffList',
      name: 'staffList',
      components: {
        staff: staffList
      },
      meta: {
        title: "员工角色分配"
      }
    },
    {
      path: 'staffPermission',
      name: 'staffPermission',
      components: {
        staff: staffPermission
      },
      meta: {
        title: "员工权限分配"
      }
    }
  ]
}]
