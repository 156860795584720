import config from './config'
import store from '../store'
//  获取数据权限
let permission = store.state.permission
export const init = ({ permissionList }) => {
  config.regist('permission', permissionList || [])
}

export const getPermissionByRole = (funcId, funcCode) => {
  // return true;
  if (permission.role === 'admin') return true

  return config.get('permission').some(item => {
    if (funcId && item.funcId === funcId) {
      return true
    } else if (funcCode && item.funcCode === funcCode) {
      return true
    }
    return false
  })
}
