<template>
  <div>
    <el-menu :default-active="$route.path" mode="horizontal" @select="handleSelect">
      <el-menu-item v-if="$getPermissionByRole('20')" index="/staff/staffList">员工角色分配</el-menu-item>
      <el-menu-item v-if="$getPermissionByRole('49')" index="/staff/staffPermission">角色权限配置</el-menu-item>
    </el-menu>
    <router-view name="staff"/>
  </div>
</template>

<script>
export default {
  name: "staff",
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    handleSelect(path) {
      this.$router.push({path: path})
    }
  }
}
</script>

<style scoped>

</style>
<style scoped>
>>> .el-menu-item {
  color: rgb(51, 51, 51) !important;
}

>>> .el-menu-item.is-active {
  background-color: #fff !important;
  color: rgb(51, 51, 51) !important;
}
</style>
