
<template>
  <div class="ontherMarkets f-s-14 m-t-10 p-10">
    <div class="flex-back m-l-10 m-b-10 p-t-10">
      <el-page-header @back="goBack"/>
    </div>
    <!--标题-->
    <div class="headTiter b-c-f p-t-10 p-l-20">
      <label>人工采集集数据</label>
    </div>
    <!--内容-->
    <div class="content">
      <div class="textRight b-c-f p-b-10 p-r-10">
        <el-button type="primary" @click="dialogFormVisible=true" v-if="$getPermissionByRole('19')" size="small">
          添加数据
        </el-button>
        <el-button type="primary" class="file-box" size="small" v-if="$getPermissionByRole('115')">
          批量导入采集数据
          <input id="imFile"
                 class="imFile"
                 type="file"
                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                 @change="importExcel()"/>
        </el-button>
        <el-button type="primary" @click="exportToExcel"
                   size="small">
          下载导入模板
        </el-button>
      </div>
      <!-- 市场行情列表 -->
      <el-table
        stripe
        :data="tableData"
        header-row-class-name="table-header"
        v-if="$getPermissionByRole('114')"
        v-loading="loading"
        class="table-data-list"
      >
        <!-- 市场 -->
        <el-table-column prop="marketName" label="市场"></el-table-column>
        <!-- 时间 -->
        <el-table-column prop="importTime" label="时间"></el-table-column>
        <!-- 货品名称 -->
        <el-table-column prop="goodsName" label="货品名称"></el-table-column>
        <!-- 所属大类 -->
        <el-table-column prop="goodsType" label="所属大类"></el-table-column>
        <!-- 所属类别 -->
        <el-table-column prop="categoryName" label="所属类别"></el-table-column>
        <!-- 规格 -->
        <el-table-column prop="specifications" label="规格"></el-table-column>
        <!-- 产地 -->
        <el-table-column prop="sourceName" label="产地"></el-table-column>

        <!-- 最低价 -->
        <el-table-column prop="minPrice" style="color: black" label="最低价"></el-table-column>
        <!-- 最高价 -->
        <el-table-column prop="maxPrice" style="color: black" label="最高价"></el-table-column>
        <!-- 均价 -->
        <el-table-column prop="averagePrice" label="均价">
          <!--          <template slot-scope="scope">-->
          <!--            <span>{{ scope.row | toFixe}}</span>-->
          <!--          </template>-->
        </el-table-column>
        <!--较昨日均价涨幅-->
        <el-table-column prop="priceGrowth" label="较昨日均价涨幅">
          <template slot-scope="scope">
            <span :class="scope.row.priceGrowth>0?'c-red':'c-green'">{{
                scope.row.priceGrowth || '-' | toFixedNum
              }}</span>
          </template>
        </el-table-column>
        <!-- 销售量 (数量)-->
        <el-table-column prop="salesNum" label="销售量 (数量)"></el-table-column>
        <!-- 销售量 (公斤)-->
        <el-table-column prop="salesWeight" label="销售量 (公斤)"></el-table-column>
        <!-- 较昨日销量涨幅 -->
        <el-table-column prop="salesGrowth" label="较昨日销量涨幅">
          <template slot-scope="scope">
            <span
              :class="scope.row.salesGrowth>0?'c-red':'c-green'">{{ scope.row.salesGrowth || '-' | toFixedNum }}</span>
          </template>
        </el-table-column>
        <!--价格取值方式-->
        <!--        <el-table-column prop="salesGrowth" label="价格取值方式">-->
        <!--          <template slot-scope="scope">-->
        <!--            <span>{{ scope.row.valueType | toValueType }}</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="total"
        :page-size="params.page_size"
        :current-page="params.current_page"
        @current-change="handleCurrentChange"
      ></el-pagination>
      <!-- 添加数据弹框 -->
      <el-dialog
        title="添加数据"
        :visible.sync="dialogFormVisible"
        width="800px"
        :before-close="dialogBeforeClose"
        @close="defaultData(form)"
        v-if="dialogFormVisible"
      >
        <div>
          <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
            <el-row>


              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="时间" prop="importTime">
                    <el-date-picker
                      v-model="form.importTime"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择开始日期时间"
                      style="width: 200px"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="市场" prop="mid">
                    <el-select filterable @change="changeMarketName" v-model="form.mid" placeholder="请选择所属市场">
                      <el-option
                        v-for="item in addMarketList"
                        :key="item.mid"
                        :label="item.marketName"
                        :value="item.mid"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="货品名称" prop="goodsName">
                    <el-input
                      v-model.trim="form.goodsName"
                      placeholder="请输入货品名称"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="所属大类" prop="mid">
                    <el-select filterable @change="sel" v-model="form.goodsTypId" placeholder="请选择所属大类">
                      <el-option
                        label="水果"
                        value="308"
                      ></el-option>

                      <el-option
                        label="蔬菜"
                        value="1"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content tree_goods_type">
                  <el-form-item label="货品类别" prop="categoryId" class="goosType">
                    <el-input
                      v-model.trim="form.categoryName"
                      :maxlength="GLOBAL.maxlength"
                      placeholder="请选择货品类别"
                      style="width: 200px"
                      @focus="showTreeGoodType('set')"
                    ></el-input>
                    <el-dialog
                      class="tree_type"
                      :visible.sync="TreeGoodType"
                      v-if="TreeGoodType"
                      title="货品分类"
                      :modal="false"
                    >
                      <el-input
                        v-model="inputText1"
                        placeholder="请选择"
                        clearable
                        style="width: auto;margin-left: 10px"
                        ref="inp"
                      ></el-input>
                      <el-tree
                        :data="treeData"
                        node-key="categoryId"
                        :props="defaultProps"
                        :highlight-current="true"
                        show-checkbox
                        check-strictly
                        :filter-node-method="filterNode"
                        @check-change="handleFormClick"
                        ref="treeForm"
                      ></el-tree>
                    </el-dialog>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="规格" prop="specifications">
                    <el-input
                      type="text"
                      v-model.trim="form.specifications"
                      placeholder="请输入规格"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="产地" prop="maxPrice">
                    <el-cascader separator="" :props="props" filterable @change="getCheckedNodes" :options="options"
                                 clearable></el-cascader>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="最低价" prop="minPrice">
                    <el-input
                      type="number"
                      v-model.trim="form.minPrice"
                      :maxlength="GLOBAL.maxlength"
                      placeholder="请输入最低价"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="最高价" prop="maxPrice">
                    <el-input
                      type="number"
                      v-model.trim="form.maxPrice"
                      :maxlength="GLOBAL.maxlength"
                      placeholder="请输入最高价"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="均价" prop="averagePrice">
                    <el-input
                      type="number"
                      v-model.trim="form.averagePrice"
                      :maxlength="GLOBAL.maxlength"
                      placeholder="请输入均价"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="销售量(数量)" prop="salesNum">
                    <el-input
                      type="number"
                      v-model.trim="form.salesNum"
                      placeholder="请输入销售量(数量)"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="grid-content">
                  <el-form-item label="销售量(公斤)" prop="salesNum">
                    <el-input
                      type="number"
                      v-model.trim="form.salesWeight"
                      placeholder="请输入销售量(公斤)"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button
            type="primary"
            @click="addMarketQuotations"
            size="small"
            :loading="buttonLoading"
          >确 定
          </el-button>
        </div>
      </el-dialog>
      <!--添加关注市场-->
      <el-dialog
        width="600px"
        :visible.sync="focusMarketFormVisible"
        title="添加关注市场">
        <div>
          <ul>
            <div class="textRight">
              <el-button type="success" @click="addMarket">添加关注市场</el-button>
            </div>
            <div v-for="(market,index) in addMarketList"
                 class="market-list">
              <el-select
                class="m-r-20"
                style="width: 240px"
                v-model="market.mid"
                @change="handleSelect(market,index)"
                placeholder="请选择市场">
                <el-option
                  v-for="item in mktList"
                  :key="item.mid"
                  :label="item.marketName"
                  :value="item.mid"
                ></el-option>
              </el-select>
              <span v-if="market.mid" @click="deleteMarket(market)" class="click">
                <i class="iconfont f-s-20">&#xe611;</i>
              </span>
            </div>
            <div class="textCenter">
              <el-button class="m-r-20 btn-focus">取消</el-button>
              <el-button class="m-l-20 btn-focus" type="success" @click="setMarketList">保存</el-button>
            </div>

          </ul>


        </div>


      </el-dialog>
    </div>

  </div>
</template>
<script>
import {ontherMarkets} from "../api";
import {keyValue} from "@/services/apis/key-value";
import "@/style/ontherMarkets/ontherMarkets.scss";
import XLSX from 'xlsx';
import {approachPrepare} from "../../approachPrepare/api";

const excelKeyList = [
  {name: '所属类别', key: 'categoryName'},
  {name: '所属大类', key: 'goodsType'},
  {name: '市场', key: 'marketName'},
  {name: '时间', key: 'importTime'},
  {name: '最低价', key: 'minPrice'},
  {name: '最高价', key: 'maxPrice'},
  {name: '货品名称', key: 'goodsName'},
  {name: '较昨日均价涨幅', key: 'priceGrowth'},
  {name: '较昨日销售涨幅', key: 'salesGrowth'},
  {name: '销售量(数量)', key: 'salesNum'},
  {name: '销售量(公斤)', key: 'salesWeight'},
  {name: '均价', key: 'averagePrice'},
  {name: '规格', key: 'specifications'},
  {name: '产地', key: 'sourceName'},
  {name: '货品名ID', key: 'goodsNameId'},
  {name: '所属大类ID', key: 'goodsTypId'},
  {name: '所属类别ID', key: 'categoryId'},
  {name: '产地ID', key: 'sourceId'},
];
export default {
  name: "manualDataCollection",
  data() {
    return {
      indexList: 0,//选中的市场
      indexMarket: {},
      pricingMethod: [
        {key: '', value: '全部'},
        {key: '1', value: '系统数据（全部）'},
        {key: '2', value: '人工采集'},
        {key: '3', value: '系统数据（部分档口）'},
      ],//价格取值方式
      CategoryTree: [],//所属大类

      followMarketList: [],  //  已关注市场列表
      addMarketList: [], //  添加关注市场列表
      focusMarketFormVisible: false,
      loading: true,
      focusMarketParams: {
        mid: '',
        marketName: ''
      },
      //列表数据
      tableData: [],
      timeQuantum: [],
      mktList: [
        {
          mid: "dcc09f2d90d24ed792c36fdf3af33b96",
          marketName: process.env.MARKET_NAME
        }
      ],
      params: {
        goodsName: '',
        startTime: "", //时间
        endTime: "",
        mid: "", //范围id//江南市场
        categoryId: "", //货品类别id
        categoryName: "",
        minPrice: "",
        maxPrice: "",
        page_size: 10,
        current_page: 1
      },


      form: {
        importTime: "",
        mid: "",
        marketName: '',
        goodsName: '',
        goodsTypId: '',
        goodsType: '',
        categoryId: "",
        categoryName: "",
        averagePrice: "",
        maxPrice: "",
        minPrice: "",
        salesNum: '',
        salesWeight: '',
        salesGrowth: '',
        priceGrowth: ''
      },
      total: 0,
      dialogFormVisible: false,
      buttonLoading: false,
      TreeGoodType: false,
      inputText1: '',
      showTreeType: false,
      count: 0,
      countForm: 0,
      rules: {
        mid: [{required: true, message: "请选择市场", trigger: "blur"}],
        importTime: [{required: true, message: "请选择时间", trigger: "blur"}],
        goodsName: [{required: true, message: "请输入货品名称", trigger: "blur"}],
        categoryId: [{required: true, message: '请选择货品品类'}],
        salesNum: [{required: true, message: "请输入销量", trigger: "blur"}],
        maxPrice: [{required: true, message: "请输入最高价格", trigger: "blur"}],
        minPrice: [{required: true, message: "请输入最低价格", trigger: "blur"}],
        averagePrice: [{required: true, message: "请输入均价", trigger: "blur"}],
        salesGrowth: [{required: true, message: "请输入销量较昨日涨幅", trigger: "blur"}],
        priceGrowth: [{required: true, message: "请输入价格较昨日涨幅", trigger: "blur"}],
      },
      // 货品品类
      treeData: [
        {
          categoryId: 0,
          categoryName: "商品分类",
          disabled: true,
          childrens: []
        }
      ],
      defaultProps: {
        children: "childrens",
        label: "categoryName"
      },
      options: [{
        value: 'zhinan',
        label: '指南',
        children: [{
          value: 'shejiyuanze',
          label: '设计原则',
        }, {
          value: 'daohang',
          label: '导航',
        }]
      }, {
        value: 'zujian',
        label: '组件',
        children: [{
          value: 'basic',
          label: 'Basic',
        }, {
          value: 'form',
          label: 'Form',
        }, {
          value: 'data',
          label: 'Data',
        }, {
          value: 'notice',
          label: 'Notice',
        }, {
          value: 'navigation',
          label: 'Navigation',
        }, {
          value: 'others',
          label: 'Others',
        }]
      }, {
        value: 'ziyuan',
        label: '资源',
        children: [{
          value: 'axure',
          label: 'Axure Components'
        }, {
          value: 'sketch',
          label: 'Sketch Templates'
        }, {
          value: 'jiaohu',
          label: '组件交互文档'
        }]
      }],

      props: {
        value: 'id',
        label: 'text',
      },


    };
  },
  filters: {
    toFixedNum(val) {
      if (val == "NaN") {
        val = "0";
      }
      return val === "-" ? '-' : (Number(val || 0) * 100).toFixed(2) + '%'
    },
    toFixe(val) {
      val.averagePrice || 0
      return parseInt(val.averagePrice || 0)
    },
    toValueType(val) {
      if (val !== '') {
        return val == 'system' ? '系统数据' : val == 'manual' ? '人工采集' : '档口数据'
      }
    },
  },
  watch: {
    inputText1(val) {
      this.$refs.treeForm.filter(val);
    },
  },
  created() {
    this.getList();
    this.getGoodType();
    this.getMarketList();
    this.getFocusMarketList();
    this.goodSourceList();
  },
  methods: {
    //获取国内产地
    goodSourceList() {
      ontherMarkets.CHENGFENG_GET_GOODSOURCE_LIST({}).then(res => {

        this.options = res.data.results
      }).catch(err => {

      })
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({
        name: "ontherMarkets",
        params: {bool: this.bool},
      });
    },
    getCheckedNodes(e) {
      let strA = '';
      let strB = '';
      this.options.forEach(item => {
        if (item.id == e[0]) {
          strA = item.text;
          item.children.forEach(i => {
            if (i.id == e[1]) {
              strB = i.text
            }
          })
        }
      })
      this.form.sourceId = e[1];
      this.form.sourceName = strA + strB

    },
    changeMarketName(val) {

      this.addMarketList.forEach(item => {
        if (val == item.mid) {
          this.form.marketName = item.marketName;
        }
      })
    },
    sel(val) {

      if (val == '308') {
        this.form.goodsType = "水果";
      }
      if (val == '1') {
        this.form.goodsType = "蔬菜";
      }
    },
    toPush(data) {
      this.$router.push({
        name: data,
        params: {
          market: JSON.stringify(this.indexMarket)
        }
      })
    },
    //excel数据导出
    exportToExcel() {
      require.ensure([], async () => {
        const {export_json_to_excel} = require("@/excel/Export2Excel");
        const tHeader = [
          "市场",
          "时间",
          "货品名称",
          "最低价",
          "最高价",
          "均价",
          "销售量(数量)",
          "销售量(公斤)",
          "所属大类",
          "所属类别",
          "规格",
          "产地",
          "货品名ID",
          "所属大类ID",
          "所属类别ID",
          "产地ID",
        ];

        const filterVal = [
          "",
          "time",
          "goodsName",

          "",
          "",
          "",
          "",
          "",
          "goodsType",
          "categoryName",
          "specifications",
          "sourceName",
          "goodsNameId",
          "goodsTypId",
          "categoryId",
          "sourceId",
        ];

        let params = {
          ...this.params,
          ...{current_page: 1, page_size: 10000},
        };

        try {
          let res = await this.CHENGFENG_GET_IMPORT_TEMPLATE();

          let incomTrainsList = res.data.results.map(item => {
            return {
              ...item,
              time: this.params.startTime
            }
          })
          // return ;
          const data = this.formatJson(filterVal, incomTrainsList);
          export_json_to_excel(tHeader, data, "人工采集数据模板");
        } catch (e) {
          alert("获取接口失败");
        }
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    changeMarket(market, index) {

      this.indexMarket = market;
      this.indexList = index;
    },
    handleSelectFollow(mid) {
      let {marketName} = this.mktList.find(item => mid === item.mid);
      this.form.marketName = marketName;
    },


    //删除市场
    deleteMarket(market) {
      ontherMarkets.DEL_FOLLOW_MARKET(market).then(res => {
        let {error_code, results} = res.data, type = error_code === 200 ? 'success' : "error";
        this.$message({
          type,
          message: results
        });
        if (error_code === 200) this.getFocusMarketList();
      })
    },

    //改变市场列表
    setMarketList() {
      let followMarketList = [];
      this.addMarketList.map(item => {
        if (item.mid) {
          followMarketList.push(item)
        }
      });
      if (!followMarketList.length) {
        return this.$message({
          type: 'error',
          message: '请选择市场'
        })
      }
      let obj = {};
      followMarketList = followMarketList.reduce((cur, next) => {
        obj[next.mid] ? "" : obj[next.mid] = true && cur.push(next);
        return cur;
      }, []);


      let params = {
        followMarketList,
      };


      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      ontherMarkets.ADD_FOLLOW_MARKET(params).then(res => {
        loading.close();
        let {error_code, results} = res.data, type = error_code === 200 ? 'success' : "error";
        this.$message({
          type,
          message: results
        });
        if (error_code === 200) this.getFocusMarketList();
        this.focusMarketFormVisible = false;
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        });
        loading.close()
      })
    },

    // 添加市场
    addMarket() {
      let mid = '', marketName = '', abbreviation;
      this.addMarketList.unshift({
        mid, marketName, abbreviation
      })
    },

    //  选择市场
    handleSelect(market, index) {
      let {marketName, abbreviation} = this.mktList.find(item => market.mid === item.mid);
      this.addMarketList[index].marketName = marketName;
      this.addMarketList[index].abbreviation = abbreviation;
    },


    //  获取关注市场
    getFocusMarketList() {
      ontherMarkets.GET_FOLLOW_MARKET().then(res => {
        this.followMarketList = res.data.results || [];
        this.addMarketList = JSON.parse(JSON.stringify(this.followMarketList));
      })
    },


    //开始导入
    importExcel() {

      let obj = event.target, wb, rABS = false;
      if (!obj.files) {
        return;
      }
      const f = obj.files[0];
      let reader = new FileReader();
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
      reader.onload = (e) => {
        let data = e.target.result;
        if (rABS) {
          wb = XLSX.read(btoa(this.fixData(data)), {//手动转化
            type: 'base64'
          });
        } else {
          wb = XLSX.read(data, {
            type: 'binary'
          });
        }
        /**
         * wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
         * wb.Sheets[Sheet名]获取第一个Sheet的数据
         */
        let excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        this.resExcelData(excelJson)

      };

    },

    //文件流转BinaryString
    fixData(data) {
      var o = "",
        l = 0,
        w = 10240;
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w +

        w)));
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },

    //  处理excel数据
    resExcelData(data) {


      let excelData = [...data],
        keyLen = excelKeyList.length,
        dataLen = excelData.length;
      let arrData = [];
      for (let j = 0; j < dataLen; j++) {
        let rowData = {},
          item = excelData[j];
        for (let i = 0; i < keyLen; i++) {
          let key = excelKeyList[i].key,
            name = excelKeyList[i].name;

          if ((name === '市场' || name === '货品名称') && !item[name]) {
            return this.$message({
              type: 'error',
              message: `请填写正的${name}`
            })
          } else if (item[name] === '' && item[name] === undefined) {
            rowData[key] = '-'
          } else {
            if (name === '较昨日均价涨幅' || name === '较昨日销售涨幅') {
              rowData[key] = isNaN(item[name]) ? item[name] : item[name].toFixed(4)
            } else {
              rowData[key] = item[name];
            }
            if (name === '时间') {
              var reg = /^(\d{1,4})(-)(\d{1,2})\2(\d{1,2})$/;

              var r = reg.test(item[name]);

              if (!r) {
                return this.$message({
                  type: 'error',
                  message: "请填正确输入时间格式，如：xxxx-xx-xx"
                })
              }
            }
          }
        }

        arrData.push(rowData)
      }
      this.setImportPrices(arrData)
    },

    setImportPrices(importPricesList) {
      let parasm = {
        importPricesList,
      };

      // return;
      ontherMarkets.ADD_IMPORT_PRICES(parasm).then(res => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.getList()
      }).catch(err => {
        this.$message({
          message: "添加失败",
          type: ""
        });
      })
    },

    CHENGFENG_GET_IMPORT_TEMPLATE() {
      return new Promise((resolve, reject) => {
        ontherMarkets.CHENGFENG_GET_IMPORT_TEMPLATE().then(res => {

          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },


    //获取列表数据
    getList() {
      var day2 = new Date();
      day2.setTime(day2.getTime());
      var y1 = day2.getFullYear();
      var m1 = (day2.getMonth() + 1) < 10 ? "0" + (day2.getMonth() + 1) : (day2.getMonth() + 1); //获取当前月份的日期，不足10补0
      var d1 = day2.getDate() < 10 ? "0" + day2.getDate() : day2.getDate(); //获取当前几号，不足10补0
      var s2 = y1 + "-" + m1 + "-" + d1;

      this.today = s2;
      this.params.startTime = s2;
      this.params.endTime = s2;
      ontherMarkets.GET_IMPORT_PRICES_LIST(this.params).then(response => {
        if (response.data.status === "Y") {
          this.tableData = response.data.results.list;
          this.total = response.data.results.total;
          this.loading = false;
        }
      });
    },

    getMarketList() {
      ontherMarkets.getMarketList().then(response => {
        this.mktList = response.data.results;
      });
    },

    //搜索
    search() {
      this.params.current_page = 1;
      if (this.params.minPrice > this.params.maxPrice) {
        this.$message({
          message: "请输入正确的价格区间",
          type: "warning"
        });
        this.params.minPrice = "";
        this.params.maxPrice = "";

        return false;
      }

      if (this.timeQuantum == null) {
        this.params.startTime = "";
        this.params.endTime = "";
      } else {
        this.params.startTime = this.timeQuantum[0];
        this.params.endTime = this.timeQuantum[1];
      }
      if (this.params.categoryName == '') {
        this.params.categoryId = '';
      }
      this.getList();
    },
    //选择筛选时间
    setTime(e) {
      let time = new Date(this.timeQuantum[1]) - new Date(this.timeQuantum[0])
      if (time / (1000 * 60 * 60 * 24) > 90) {
        this.timeQuantum[1] = '';
        return this.$message({
          message: '筛选时间段不能大于三个月',
          type: 'warning'
        });
      }
      let arr = this.timeQuantum[1].split('-');
      let arr1 = this.timeQuantum[0].split('-');


    },
    //分页
    handleCurrentChange(val) {
      this.params.current_page = val;
      this.getList();
    },

    // 获取树类型结构
    getGoodType() {
      ontherMarkets.getGoodCategoryTree().then(response => {
        this.CategoryTree = response.data.results;
        let CategoryTree = response.data.results;
        CategoryTree.forEach(item => {
          if (item.categoryName !== "其他") {
            item.disabled = true;
            item.childrens.forEach(categoryItem => {
              categoryItem.disabled = true;
            });
          }
        });

        this.treeData[0].childrens = CategoryTree;
      });
    },

    showTreeGoodType(type) {
      if (type === "search") {
        this.showTreeType = true;
      } else {
        this.TreeGoodType = true;
      }
      this.$nextTick(() => {
        this.$refs.inp.$el.querySelector('input').focus();
      })
    },

    //   确定添加市场行情
    addMarketQuotations() {

      this.$refs.ruleForm.validate(valid => {

        if (valid) {
          if (this.form.maxPrice < this.form.minPrice) {
            this.$message({
              message: "请输入正确的品类价格",
              type: "warning"
            });
          } else {
            this.setImportPrices([this.form]);
            this.defaultData()
          }
        }
      });
    },

    dialogBeforeClose(done) {
      this.dialogFormVisible = false;
      this.defaultData();
    },

    // 取消  关闭添加市场弹框
    cancel() {
      this.defaultData(this.form);
    },

    defaultData(form) {
      this.form = {};
      this.dialogFormVisible = false;
      this.TreeGoodType = false;
    },


    handleClick(data, checked, node) {
      if (checked) {
        this.params.categoryId = data.categoryId;
        this.params.categoryName = data.categoryName;
      }

      this.count++;
      if (this.count % 2 === 0) {
        if (checked) {
          this.$refs.treeParams.setCheckedNodes([]);
          this.$refs.treeParams.setCheckedNodes([data]);
          this.params.categoryId = data.categoryId;
          this.params.categoryName = data.categoryName;
          //交叉点击节点
        } else {
          this.$refs.treeParams.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      this.showTreeType = false;
    },

    filterNode(value, data) {

      if (!value) return true;
      return data.categoryName.indexOf(value) !== -1;
    },

    handleFormClick(data, checked, node) {
      if (checked) {
        this.form.categoryId = data.categoryId;
        this.form.categoryName = data.categoryName;
      }

      this.countForm++;
      if (this.countForm % 2 === 0) {
        if (checked) {
          this.$refs.treeForm.setCheckedNodes([]);
          this.$refs.treeForm.setCheckedNodes([data]);
          this.form.categoryId = data.categoryId;
          this.form.categoryName = data.categoryName;
          //交叉点击节点
        } else {
          this.$refs.treeForm.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      this.TreeGoodType = false;
    }
  }
};
</script>

<style lang="scss" scoped>
> > > .el-table th {
  background: #E8E8E8;
}

.ontherMarkets {
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}

.file-box {
  width: 140px;
  position: relative;
}

.imFile {
  opacity: 0;
  width: 140px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.market-list {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.btn-focus {
  margin-top: 10px;
  width: 150px;
}

.other-market-list {
  width: 80%;
  background-color: #eee;
  margin: 0 auto;

  ul {
    list-style: none;
    cursor: pointer;
  }
}

.market-li {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.active {
  background-color: #05AE09;
  color: #fff;
}

.c-red {
  color: red;
}

.c-green {
  color: green;
}
</style>

