export default [{
  path: 'goodsInspection',
  name: 'goodsInspection',
  component: () => import('./views/goodsInspection'),
  meta: {
    title: "货品检验"
  }
}, {
  path: 'testSet',
  name: 'testSet',
  component: () => import('./views/testSet'),
  meta: {
    showSidebar: false,
    title: "校验设置"
  }
}]
