var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "b-c-f m-t-10 page-warp page-warph" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "m-t-10" },
            [
              _vm._v(" 大屏展示价格比较市场： "),
              _c("span", [_vm._v("已选" + _vm._s(_vm.marketTotal) + "个市场")]),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      _vm.showMarketFormVisible = true
                    },
                  },
                },
                [_vm._v("设置大屏展示市场")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.toPush("largeScreenSort")
                    },
                  },
                },
                [_vm._v("大屏展示排序")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "m-t-10" },
            [
              _vm._v(" 大屏展示货品： "),
              _c("span", [_vm._v("已选" + _vm._s(_vm.goodsNum) + "种")]),
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      _vm.showGoodsVisible = true
                    },
                  },
                },
                [_vm._v("设置大屏展示货品")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "m-t-10" },
            [
              _vm._v(" 大屏价格预警设置： "),
              _c("span", [_vm._v(_vm._s(_vm.warningParams.warnValue) + "%")]),
              _vm.$getPermissionByRole("36")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          _vm.showWarningVisible = true
                        },
                      },
                    },
                    [_vm._v("设置")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "m-t-10" },
            [
              _vm._v(" 大屏库存数据设置： "),
              _c("span", [_vm._v("上传数据")]),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.getSettings } },
                [_vm._v("设置")]
              ),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.getview } },
                [_vm._v("查看")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            "before-close": _vm.dialogInventoryClose,
            visible: _vm.showInventoryVisible,
            title: "大屏库存数据设置",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInventoryVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据类型", prop: "resource" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.resource,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "resource", $$v)
                            },
                            expression: "ruleForm.resource",
                          },
                        },
                        _vm._l(_vm.stockTypeList, function (item, index) {
                          return _c(
                            "el-radio",
                            {
                              key: index,
                              attrs: { label: item.value },
                              on: { change: _vm.getckhe },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.resource === 2
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "filefrom",
                          attrs: {
                            label: "选择上传数据",
                            prop: "uploadMessage",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "点击上传文件",
                              disabled: true,
                            },
                            model: {
                              value: _vm.ruleForm.uploadMessage,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "uploadMessage", $$v)
                              },
                              expression: "ruleForm.uploadMessage",
                            },
                          }),
                          _c("input", {
                            staticClass: "imFile",
                            attrs: {
                              id: "imFile",
                              type: "file",
                              accept:
                                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.pdf",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.importExcel()
                              },
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.getImport },
                            },
                            [_vm._v("导入模板")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", [
                _vm._v(" 说明： "),
                _c("br"),
                _vm._v(
                  " 1. 报备数据：通过报备数据的报备重量计算货品调度、数据大屏的库存信息；"
                ),
                _c("br"),
                _vm._v(
                  " 2. 鲜道赢入库批次：通过统计鲜道赢入库货品重量计算货品调度、数据大屏的库存信息；"
                ),
                _c("br"),
                _vm._v(
                  " 3. 上传数据：按上传的数据提供货品调度、数据大屏的库存信息； "
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "lnventoryVisible-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showInventoryVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingupd },
                  on: { click: _vm.getStockType },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "库存数据", visible: _vm.lnventorydata },
          on: {
            "update:visible": function ($event) {
              _vm.lnventorydata = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "100", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: { property: "categoryName", label: "品类" },
              }),
              _c("el-table-column", {
                attrs: { property: "weight", label: "吨数" },
              }),
              _c("el-table-column", {
                attrs: { property: "regionName", label: "区域名称" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "批量导入结果", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("h4", { staticStyle: { color: "#008b5b" } }, [
            _vm._v("批量导入成功共:" + _vm._s(_vm.gridData.success)),
          ]),
          _c("h4", { staticStyle: { color: "#f53f3f" } }, [
            _vm._v("批量导入失败共:" + _vm._s(_vm.gridData.failure)),
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.gridData.importResultVoList } },
            [
              _c("el-table-column", {
                attrs: { property: "failureReason", label: "原因" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            "before-close": _vm.dialogBeforeClose,
            visible: _vm.showMarketFormVisible,
            title: "设置大屏展示市场",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMarketFormVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "ul",
              [
                _c("div", { staticClass: "d-flex market-list" }, [
                  _c("div", { staticStyle: { width: "240px" } }, [
                    _vm._v("市场"),
                  ]),
                  _c("div", [_vm._v("大屏展示市场")]),
                ]),
                _vm._l(_vm.followMarketList, function (market, index) {
                  return _c(
                    "div",
                    { staticClass: "market-list" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "m-r-20",
                          staticStyle: { width: "240px" },
                          attrs: { disabled: "", placeholder: "请选择市场" },
                          on: {
                            change: function ($event) {
                              return _vm.handleSelect(market, index)
                            },
                          },
                          model: {
                            value: market.mid,
                            callback: function ($$v) {
                              _vm.$set(market, "mid", $$v)
                            },
                            expression: "market.mid",
                          },
                        },
                        _vm._l(_vm.followMarketList, function (item) {
                          return _c("el-option", {
                            key: item.mid,
                            attrs: { label: item.marketName, value: item.mid },
                          })
                        }),
                        1
                      ),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#45c281",
                          "inactive-color": "#d5d5d5",
                          "active-value": "Y",
                          "inactive-value": "N",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setStatus(market)
                          },
                        },
                        model: {
                          value: market.status,
                          callback: function ($$v) {
                            _vm.$set(market, "status", $$v)
                          },
                          expression: "market.status",
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "textCenter" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "m-r-20 btn-focus",
                        on: {
                          click: function ($event) {
                            _vm.showMarketFormVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-l-20 btn-focus",
                        attrs: { type: "success" },
                        on: { click: _vm.setMarketList },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            close: _vm.dialogBeforeCloseGoods,
            visible: _vm.showGoodsVisible,
            title: "设置大屏展示货品",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGoodsVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "tree-box" }, [
            _c(
              "ul",
              { staticStyle: { "max-height": "600px", overflow: "auto" } },
              [
                _c(
                  "div",
                  { staticClass: "d-flex market-list" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入搜索货品" },
                      on: { input: _vm.changeInput },
                      model: {
                        value: _vm.goodsName,
                        callback: function ($$v) {
                          _vm.goodsName = $$v
                        },
                        expression: "goodsName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.tableData, function (market, index) {
                  return _c(
                    "div",
                    { staticClass: "market-list" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "m-r-20",
                          staticStyle: { width: "240px" },
                          attrs: { disabled: "", placeholder: "请选择市场" },
                          model: {
                            value: market.goodsName,
                            callback: function ($$v) {
                              _vm.$set(market, "goodsName", $$v)
                            },
                            expression: "market.goodsName",
                          },
                        },
                        _vm._l(_vm.followMarketList, function (item) {
                          return _c("el-option", {
                            key: item.mid,
                            attrs: { label: item.marketName, value: item.mid },
                          })
                        }),
                        1
                      ),
                      _vm.$getPermissionByRole("37")
                        ? _c("el-switch", {
                            attrs: {
                              "active-color": "#45c281",
                              "inactive-color": "#d5d5d5",
                              "active-value": "Y",
                              "inactive-value": "N",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setGoods(market)
                              },
                            },
                            model: {
                              value: market.showStatus,
                              callback: function ($$v) {
                                _vm.$set(market, "showStatus", $$v)
                              },
                              expression: "market.showStatus",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            visible: _vm.showWarningVisible,
            title: "大屏价格预警设置",
          },
          on: {
            close: _vm.dialogBeforeCloseWarn,
            "update:visible": function ($event) {
              _vm.showWarningVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "ul",
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "400px" },
                        attrs: { label: "对比市场", "label-width": "100px" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请选择市场" },
                            model: {
                              value: _vm.warningParams.warnMarketId,
                              callback: function ($$v) {
                                _vm.$set(_vm.warningParams, "warnMarketId", $$v)
                              },
                              expression: "warningParams.warnMarketId",
                            },
                          },
                          _vm._l(_vm.followMarketList, function (item) {
                            return _c("el-option", {
                              key: item.mid,
                              attrs: {
                                label: item.marketName,
                                value: item.mid,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "400px" },
                        attrs: { label: "设置预警值", "label-width": "100px" },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入内容",
                              type: "number",
                            },
                            model: {
                              value: _vm.warningParams.warnValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.warningParams, "warnValue", $$v)
                              },
                              expression: "warningParams.warnValue",
                            },
                          },
                          [_c("template", { slot: "append" }, [_vm._v("%")])],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "textCenter" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "m-r-20 btn-focus",
                        on: {
                          click: function ($event) {
                            _vm.showWarningVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-l-20 btn-focus",
                        attrs: { type: "success" },
                        on: { click: _vm.setMarketWarn },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }