export default [
  {
    path: 'coldOrderManagement',
    name: 'coldOrderManagement',
    component: () => import('./view/index.vue'),
    meta: {
      title: '打冷订单管理'
    }
  },{
    path: 'coldOrderManagement/cold0rderDetails/:id',
    name: 'cold0rderDetails',
    component: () => import('./../coldOrderManagement/components/cold0rderDetails.vue'),
    meta: {
      title: '打冷订单管理详情',
      // roles: [
      //   // GetPes('coldOrderManagement', 'get')
      //   this.$promissionInit({ coldOrderManagement })
      // ]
    }
  },
]
