<template>
  <div class="details">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="基本信息" name="basicinformation">
        <Basicinformation :typeName="activeName" :list="list" />
      </el-tab-pane>
      <el-tab-pane label="物业费"  name="PopertyDetails">
        <PopertyDetails :typeName="activeName" :list="list"  />
      </el-tab-pane>
      <el-tab-pane label="水电抄表" name="hydroelectric">
        <Hydroelectric :typeName="activeName" :list="list" />
      </el-tab-pane>
      <el-tab-pane label="来货交易" name="Incoming">
        <Incoming :typeName="activeName" :list="list" />
      </el-tab-pane>
      <el-tab-pane label="积分记录" name="IntegralRecord">
        <IntegralRecord :typeName="activeName" :list="list" />
      </el-tab-pane>
      <el-tab-pane label="修改记录" name="modify">
        <Modify :typeName="activeName" :list="list" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Basicinformation from '../components/basiCinformation.vue'
import Hydroelectric from '../components/hydroelectric.vue'
import Incoming from '../components/lncoming.vue'
import IntegralRecord from '../components/lntegralRecord.vue'
import Modify from '../components/modify.vue'
import PopertyDetails from '../components/popertyDetails'

export default {
  components: { Basicinformation, Hydroelectric, Incoming, IntegralRecord, Modify, PopertyDetails },
  data() {
    return {
      activeName: 'basicinformation',
      list:this.$route.query.data
    }
  }
}
</script>
<style lang="scss">
.details {
  margin-top: 5px;
}
</style>
