var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modify" },
    [
      _c("el-card", { attrs: { header: "历史入驻" } }, [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.historySettleInfoList, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "merchantName", label: "入驻时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "retreatTime", label: "退档时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "merchantName", label: "商户姓名" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "phone", label: "联系方式" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-card", { attrs: { header: "档口信息" } }, [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.changeList, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "changeField", label: "变更字段" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "oldData", label: "变更前" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "newData", label: "变更后" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "修改时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "userName", label: "修改人" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }