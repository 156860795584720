var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ontherMarkets f-s-14 m-t-10 p-10" }, [
    _c(
      "div",
      { staticClass: "flex-back m-l-10 m-b-10 p-t-10" },
      [_c("el-page-header", { on: { back: _vm.goBack } })],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "textRight b-c-f p-b-10 p-r-10" },
          [
            _vm.$getPermissionByRole("19")
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = true
                      },
                    },
                  },
                  [_vm._v(" 添加数据 ")]
                )
              : _vm._e(),
            _vm.$getPermissionByRole("115")
              ? _c(
                  "el-button",
                  {
                    staticClass: "file-box",
                    attrs: { type: "primary", size: "small" },
                  },
                  [
                    _vm._v(" 批量导入采集数据 "),
                    _c("input", {
                      staticClass: "imFile",
                      attrs: {
                        id: "imFile",
                        type: "file",
                        accept:
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.importExcel()
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.exportToExcel },
              },
              [_vm._v(" 下载导入模板 ")]
            ),
          ],
          1
        ),
        _vm.$getPermissionByRole("114")
          ? _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "table-data-list",
                attrs: {
                  stripe: "",
                  data: _vm.tableData,
                  "header-row-class-name": "table-header",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "marketName", label: "市场" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "importTime", label: "时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goodsName", label: "货品名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "goodsType", label: "所属大类" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "categoryName", label: "所属类别" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "specifications", label: "规格" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "sourceName", label: "产地" },
                }),
                _c("el-table-column", {
                  staticStyle: { color: "black" },
                  attrs: { prop: "minPrice", label: "最低价" },
                }),
                _c("el-table-column", {
                  staticStyle: { color: "black" },
                  attrs: { prop: "maxPrice", label: "最高价" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "averagePrice", label: "均价" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "priceGrowth", label: "较昨日均价涨幅" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  scope.row.priceGrowth > 0
                                    ? "c-red"
                                    : "c-green",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toFixedNum")(
                                      scope.row.priceGrowth || "-"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3609027124
                  ),
                }),
                _c("el-table-column", {
                  attrs: { prop: "salesNum", label: "销售量 (数量)" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "salesWeight", label: "销售量 (公斤)" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "salesGrowth", label: "较昨日销量涨幅" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  scope.row.salesGrowth > 0
                                    ? "c-red"
                                    : "c-green",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toFixedNum")(
                                      scope.row.salesGrowth || "-"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3631672404
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "total, prev, pager, next",
            total: _vm.total,
            "page-size": _vm.params.page_size,
            "current-page": _vm.params.current_page,
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
        _vm.dialogFormVisible
          ? _c(
              "el-dialog",
              {
                attrs: {
                  title: "添加数据",
                  visible: _vm.dialogFormVisible,
                  width: "800px",
                  "before-close": _vm.dialogBeforeClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: function ($event) {
                    return _vm.defaultData(_vm.form)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "时间",
                                        prop: "importTime",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          type: "date",
                                          placeholder: "选择开始日期时间",
                                        },
                                        model: {
                                          value: _vm.form.importTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "importTime",
                                              $$v
                                            )
                                          },
                                          expression: "form.importTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "市场", prop: "mid" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择所属市场",
                                          },
                                          on: { change: _vm.changeMarketName },
                                          model: {
                                            value: _vm.form.mid,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "mid", $$v)
                                            },
                                            expression: "form.mid",
                                          },
                                        },
                                        _vm._l(
                                          _vm.addMarketList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.mid,
                                              attrs: {
                                                label: item.marketName,
                                                value: item.mid,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "货品名称",
                                        prop: "goodsName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "请输入货品名称",
                                        },
                                        model: {
                                          value: _vm.form.goodsName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "goodsName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.goodsName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "所属大类", prop: "mid" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择所属大类",
                                          },
                                          on: { change: _vm.sel },
                                          model: {
                                            value: _vm.form.goodsTypId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "goodsTypId",
                                                $$v
                                              )
                                            },
                                            expression: "form.goodsTypId",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "水果",
                                              value: "308",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "蔬菜",
                                              value: "1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content tree_goods_type" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "goosType",
                                      attrs: {
                                        label: "货品类别",
                                        prop: "categoryId",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          maxlength: _vm.GLOBAL.maxlength,
                                          placeholder: "请选择货品类别",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.showTreeGoodType("set")
                                          },
                                        },
                                        model: {
                                          value: _vm.form.categoryName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "categoryName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.categoryName",
                                        },
                                      }),
                                      _vm.TreeGoodType
                                        ? _c(
                                            "el-dialog",
                                            {
                                              staticClass: "tree_type",
                                              attrs: {
                                                visible: _vm.TreeGoodType,
                                                title: "货品分类",
                                                modal: false,
                                              },
                                              on: {
                                                "update:visible": function (
                                                  $event
                                                ) {
                                                  _vm.TreeGoodType = $event
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                ref: "inp",
                                                staticStyle: {
                                                  width: "auto",
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.inputText1,
                                                  callback: function ($$v) {
                                                    _vm.inputText1 = $$v
                                                  },
                                                  expression: "inputText1",
                                                },
                                              }),
                                              _c("el-tree", {
                                                ref: "treeForm",
                                                attrs: {
                                                  data: _vm.treeData,
                                                  "node-key": "categoryId",
                                                  props: _vm.defaultProps,
                                                  "highlight-current": true,
                                                  "show-checkbox": "",
                                                  "check-strictly": "",
                                                  "filter-node-method":
                                                    _vm.filterNode,
                                                },
                                                on: {
                                                  "check-change":
                                                    _vm.handleFormClick,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "规格",
                                        prop: "specifications",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "text",
                                          placeholder: "请输入规格",
                                        },
                                        model: {
                                          value: _vm.form.specifications,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "specifications",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.specifications",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产地",
                                        prop: "maxPrice",
                                      },
                                    },
                                    [
                                      _c("el-cascader", {
                                        attrs: {
                                          separator: "",
                                          props: _vm.props,
                                          filterable: "",
                                          options: _vm.options,
                                          clearable: "",
                                        },
                                        on: { change: _vm.getCheckedNodes },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "最低价",
                                        prop: "minPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "number",
                                          maxlength: _vm.GLOBAL.maxlength,
                                          placeholder: "请输入最低价",
                                        },
                                        model: {
                                          value: _vm.form.minPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "minPrice",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.minPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "最高价",
                                        prop: "maxPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "number",
                                          maxlength: _vm.GLOBAL.maxlength,
                                          placeholder: "请输入最高价",
                                        },
                                        model: {
                                          value: _vm.form.maxPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "maxPrice",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.maxPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "均价",
                                        prop: "averagePrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "number",
                                          maxlength: _vm.GLOBAL.maxlength,
                                          placeholder: "请输入均价",
                                        },
                                        model: {
                                          value: _vm.form.averagePrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "averagePrice",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.averagePrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "销售量(数量)",
                                        prop: "salesNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "number",
                                          placeholder: "请输入销售量(数量)",
                                        },
                                        model: {
                                          value: _vm.form.salesNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "salesNum",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.salesNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "销售量(公斤)",
                                        prop: "salesNum",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          type: "number",
                                          placeholder: "请输入销售量(公斤)",
                                        },
                                        model: {
                                          value: _vm.form.salesWeight,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "salesWeight",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.salesWeight",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.cancel } },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.buttonLoading,
                        },
                        on: { click: _vm.addMarketQuotations },
                      },
                      [_vm._v("确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "el-dialog",
          {
            attrs: {
              width: "600px",
              visible: _vm.focusMarketFormVisible,
              title: "添加关注市场",
            },
            on: {
              "update:visible": function ($event) {
                _vm.focusMarketFormVisible = $event
              },
            },
          },
          [
            _c("div", [
              _c(
                "ul",
                [
                  _c(
                    "div",
                    { staticClass: "textRight" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.addMarket },
                        },
                        [_vm._v("添加关注市场")]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.addMarketList, function (market, index) {
                    return _c(
                      "div",
                      { staticClass: "market-list" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "m-r-20",
                            staticStyle: { width: "240px" },
                            attrs: { placeholder: "请选择市场" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSelect(market, index)
                              },
                            },
                            model: {
                              value: market.mid,
                              callback: function ($$v) {
                                _vm.$set(market, "mid", $$v)
                              },
                              expression: "market.mid",
                            },
                          },
                          _vm._l(_vm.mktList, function (item) {
                            return _c("el-option", {
                              key: item.mid,
                              attrs: {
                                label: item.marketName,
                                value: item.mid,
                              },
                            })
                          }),
                          1
                        ),
                        market.mid
                          ? _c(
                              "span",
                              {
                                staticClass: "click",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteMarket(market)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "iconfont f-s-20" }, [
                                  _vm._v(""),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "textCenter" },
                    [
                      _c("el-button", { staticClass: "m-r-20 btn-focus" }, [
                        _vm._v("取消"),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-l-20 btn-focus",
                          attrs: { type: "success" },
                          on: { click: _vm.setMarketList },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headTiter b-c-f p-t-10 p-l-20" }, [
      _c("label", [_vm._v("人工采集集数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }