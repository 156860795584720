import {dateTime} from '../../util/date'
const {endTime, startTime} = dateTime(1, false);


export const store = {
  // 状态
  state: {
    screenForm: {
      gearNumber: '',
      driverPhone: '',
      regionId: '',
      categoryName: "",
      incomStartTime_start: "",
      incomStartTime_end: "",
      incomEndTime_start: startTime,
      incomEndTime_end: endTime,
      aggregateStateList: "",
      incomType:'',
      page_size: 10,
      current_page: 1,
      trainNum: "",
      tableValue: 'all',
      ownerName: '',//档主
      number: '',//档口号
      trainModel: '',
    },
    form: {
      trainNum: "",
      incomEndTime_start: "",
      incomEndTime_end: "",
      appearanceTime_start:"",
      appearanceTime_end:"",
      number: '',//档口号
      ownerName: "",
      ownerPhone: "",
      regionId: "",
      current_page: 1,
      page_size: 10,
      goodName:'',
      aggregateState: '',//车次状态
      maxAbsWeight: '',
      minAbsWeight: '',
      absWeightType: '',
      weightFiledName:'',
      weightNull:'',
      bool: true
    },
    netWeight: {
      appearanceTime_start: '',
      appearanceTime_end: '',
      regionId: '',
      gearNumber: '',
      ownerPhone: '',
      ownerName: '',
      gearName: '',
      number: '',//档口号
      current_page: 1,
      page_size: 10,
      bool: true
    }
  },
  // 修改状态
  mutations: {
    SET_SCREEN_FORM: (state, data) => {
      state.screenForm = {...state.screenForm, ...data};
    },
    SET_FORM: (state, data) => {
      state.form = {...state.form, ...data};
    },
    SET_NETWEIGHT: (state, data) => {
      state.netWeight = {...state.netWeight, ...data};
    },


  },
  // 异步修改状态
  actions: {},
  namespaced: true
};
