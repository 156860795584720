<template>
  <div class="lntegralRecord">
    <el-card>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="支出类型:">
            <el-select v-model="formInline.type" placeholder="支出类型" clearable>
              <el-option label="支出" value="expend"></el-option>
              <el-option label="收入" value="income"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="交易类型:">
            <el-select v-model="formInline.flowType" placeholder="交易类型" clearable>
              <el-option label="全部" value=""/>
              <el-option v-for="(type,index) in typeList" :key="index" :label="type.typeName" :value="type.typeId"/>
            </el-select>
          </el-form-item>
          <el-form-item label="商户姓名:">
            <el-input v-model="formInline.merchantName" placeholder="商户姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号码:">
            <el-input v-model="formInline.phone" placeholder="手机号码" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="lntegralRecord-table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="generationTime" label="消费时间"> </el-table-column>
          <el-table-column prop="merchantName" label="商户姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号码"> </el-table-column>
          <el-table-column prop="integral" label="积分"> </el-table-column>
          <el-table-column prop="type" label="支出类型"> </el-table-column>
          <el-table-column prop="typeName" label="交易类型"> </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
export default {
  props: {
    list: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeName:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      typeList:[],
      formInline: {
        merchantName:'',
        phone:'',
        type:'',
        flowType:''
      },
      tableData: [],
       currentPage: 1,
      pageSize: 10,
      totalCount: 1, // 总条数
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      } else {
          this.getIntegral()
        this.getType()
      }
    },
    typeName(val){
      if (val==='IntegralRecord') {
        this.getIntegral()
        this.getType()
      }
    }
  },
  methods: {
    getType() {
      marketplan.FIND_SOURCE_TYPE_LIST({userType: 'gear'}).then(res => {
        this.typeList = res.data.results
      })
    },
    getIntegral(){
      let obj={
        marketId:this.$route.query.data.marketId,
        mrgId:this.$route.query.data.mrgId,
        ...this.formInline,
        current: this.currentPage,
        pageSize: this.pageSize
      }
      marketplan.getIntegralDetails({...obj}).then((result) => {
        this.tableData=result.data.results.list
        this.totalCount=res.data.results.total
      }).catch((err) => {
        console.log(err);
      });
    },
    onSubmit() {
      this.getIntegral()
    },
    handleCurrentChange(current) {
      this.currentPage=current
      this.getIntegral()
    },
    handleSizeChange(size) {
      this.pageSize=size
      this.getIntegral()
    }
  }
}
</script>
<style lang="scss">
.lntegralRecord {
  .el-form-item {
    margin-right: 10px;
  }
  .cell {
    text-align: center;
  }
  .lntegralRecord-table {
    margin-bottom: 20px;
  }
}
</style>
