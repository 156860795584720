var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ontherMarkets f-s-14 m-t-10" },
    [
      _vm._m(0),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("div", { staticClass: "left-box" }, [
                _c(
                  "span",
                  {
                    class: _vm.spanIndex == "1" ? "span-active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.setSpanIndex("1")
                      },
                    },
                  },
                  [_vm._v("今日实时数据")]
                ),
                _c(
                  "span",
                  {
                    class: _vm.spanIndex == "2" ? "span-active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.setSpanIndex("2")
                      },
                    },
                  },
                  [_vm._v("历史数据")]
                ),
              ]),
              _c(
                "el-tabs",
                {
                  staticStyle: { height: "auto" },
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.changeTabMarket },
                },
                _vm._l(_vm.addMarketList, function (market, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: market.marketName },
                  })
                }),
                1
              ),
              _vm.$getPermissionByRole("34")
                ? _c(
                    "div",
                    {
                      staticClass: "m-t-10",
                      staticStyle: {
                        "text-align": "center",
                        color: "#15b9ff",
                        cursor: "pointer",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              _vm.focusMarketFormVisible = true
                            },
                          },
                        },
                        [_vm._v("添加关注市场")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 20 } }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.spanIndex == "2"
                  ? _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "clearfix search",
                        attrs: { model: _vm.params, "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "货品名称" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品名称" },
                                      model: {
                                        value: _vm.params.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.params, "goodsName", $$v)
                                        },
                                        expression: "params.goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 9 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "selectData",
                                    attrs: { label: "选择时间" },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "daterange",
                                        "range-separator": "至",
                                        "picker-options": _vm.pickerOptions0,
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: { change: _vm.setTime },
                                      model: {
                                        value: _vm.timeQuantum,
                                        callback: function ($$v) {
                                          _vm.timeQuantum = $$v
                                        },
                                        expression: "timeQuantum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "d-flex", attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "价格取值方式" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择价格取值方式",
                                        },
                                        model: {
                                          value: _vm.params.valueType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.params,
                                              "valueType",
                                              $$v
                                            )
                                          },
                                          expression: "params.valueType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.pricingMethod,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.key,
                                            attrs: {
                                              label: item.value,
                                              value: item.key,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属大类" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择所属大类",
                                        },
                                        model: {
                                          value: _vm.params.goodsTypId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.params,
                                              "goodsTypId",
                                              $$v
                                            )
                                          },
                                          expression: "params.goodsTypId",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          key: "",
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "水果",
                                            value: "308",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "蔬菜", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "goosType",
                                    attrs: { label: "货品类别" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.showTreeGoodType("search")
                                        },
                                      },
                                      model: {
                                        value: _vm.params.categoryName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "categoryName",
                                            $$v
                                          )
                                        },
                                        expression: "params.categoryName",
                                      },
                                    }),
                                    _vm.showTreeType
                                      ? _c(
                                          "el-dialog",
                                          {
                                            staticClass: "tree_type",
                                            attrs: {
                                              visible: _vm.showTreeType,
                                              maxlength: _vm.GLOBAL.maxlength,
                                              title: "货品分类",
                                              modal: false,
                                            },
                                            on: {
                                              "update:visible": function (
                                                $event
                                              ) {
                                                _vm.showTreeType = $event
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "inp",
                                              staticStyle: {
                                                width: "auto",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.inputText1,
                                                callback: function ($$v) {
                                                  _vm.inputText1 = $$v
                                                },
                                                expression: "inputText1",
                                              },
                                            }),
                                            _c("el-tree", {
                                              ref: "treeParams",
                                              attrs: {
                                                data: _vm.treeData,
                                                "node-key": "categoryId",
                                                props: _vm.defaultProps,
                                                "highlight-current": true,
                                                "show-checkbox": "",
                                                "check-strictly": "",
                                                "check-on-click-node": true,
                                                "filter-node-method":
                                                  _vm.filterNode,
                                              },
                                              on: {
                                                "check-change": _vm.handleClick,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "规格" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品规格" },
                                      model: {
                                        value: _vm.params.specifications,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "specifications",
                                            $$v
                                          )
                                        },
                                        expression: "params.specifications",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "产地" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入产地" },
                                      model: {
                                        value: _vm.params.sourceName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "sourceName",
                                            $$v
                                          )
                                        },
                                        expression: "params.sourceName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "button", attrs: { span: 6 } },
                              [_c("el-form-item", { attrs: { label: "" } })],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "button", attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.search },
                                  },
                                  [_vm._v("筛选")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-form",
                      {
                        ref: "form",
                        staticClass: "clearfix search",
                        attrs: { model: _vm.params, "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "货品名称" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品名称" },
                                      model: {
                                        value: _vm.params.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.params, "goodsName", $$v)
                                        },
                                        expression: "params.goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "d-flex", attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "价格取值方式" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择价格取值方式",
                                        },
                                        model: {
                                          value: _vm.params.valueType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.params,
                                              "valueType",
                                              $$v
                                            )
                                          },
                                          expression: "params.valueType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.pricingMethod,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.key,
                                            attrs: {
                                              label: item.value,
                                              value: item.key,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属大类" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择所属大类",
                                        },
                                        model: {
                                          value: _vm.params.goodsTypId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.params,
                                              "goodsTypId",
                                              $$v
                                            )
                                          },
                                          expression: "params.goodsTypId",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          key: "",
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "水果",
                                            value: "308",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "蔬菜", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "goosType",
                                    attrs: { label: "货品类别" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.showTreeGoodType("search")
                                        },
                                      },
                                      model: {
                                        value: _vm.params.categoryName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "categoryName",
                                            $$v
                                          )
                                        },
                                        expression: "params.categoryName",
                                      },
                                    }),
                                    _vm.showTreeType
                                      ? _c(
                                          "el-dialog",
                                          {
                                            staticClass: "tree_type",
                                            attrs: {
                                              visible: _vm.showTreeType,
                                              maxlength: _vm.GLOBAL.maxlength,
                                              title: "货品分类",
                                              modal: false,
                                            },
                                            on: {
                                              "update:visible": function (
                                                $event
                                              ) {
                                                _vm.showTreeType = $event
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "inp",
                                              staticStyle: {
                                                width: "auto",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.inputText2,
                                                callback: function ($$v) {
                                                  _vm.inputText2 = $$v
                                                },
                                                expression: "inputText2",
                                              },
                                            }),
                                            _c("el-tree", {
                                              ref: "treeParams",
                                              attrs: {
                                                data: _vm.treeData,
                                                "node-key": "categoryId",
                                                props: _vm.defaultProps,
                                                "highlight-current": true,
                                                "show-checkbox": "",
                                                "check-strictly": "",
                                                "check-on-click-node": true,
                                                "filter-node-method":
                                                  _vm.filterNode,
                                              },
                                              on: {
                                                "check-change": _vm.handleClick,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "规格" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品规格" },
                                      model: {
                                        value: _vm.params.specifications,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "specifications",
                                            $$v
                                          )
                                        },
                                        expression: "params.specifications",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "产地" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入产地" },
                                      model: {
                                        value: _vm.params.sourceName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "sourceName",
                                            $$v
                                          )
                                        },
                                        expression: "params.sourceName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "button", attrs: { span: 6 } },
                              [_c("el-form-item", { attrs: { label: "" } })],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "button", attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.search },
                                  },
                                  [_vm._v("筛选")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "textRight b-c-f p-b-10 p-r-10" },
                  [
                    _vm.spanIndex == "1"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "file-box",
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toPush("productSettings")
                              },
                            },
                          },
                          [_vm._v(" 关注货品设置 ")]
                        )
                      : _vm._e(),
                    _vm.spanIndex == "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toPush("manualDataCollection")
                              },
                            },
                          },
                          [_vm._v(" 人工采集数据 ")]
                        )
                      : _vm._e(),
                    _vm.$getPermissionByRole("112") && _vm.spanIndex == "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.exportToExcel },
                          },
                          [_vm._v(" 导出数据 ")]
                        )
                      : _vm._e(),
                    _vm.$getPermissionByRole("113") && _vm.spanIndex == "2"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.exportToExcel },
                          },
                          [_vm._v(" 导出数据 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.$getPermissionByRole("17") && _vm.spanIndex == "1"
                  ? _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "table-data-list",
                        attrs: {
                          stripe: "",
                          data: _vm.tableData,
                          "header-row-class-name": "table-header",
                        },
                      },
                      [
                        _vm._v(" 时间 "),
                        _vm.spanIndex == "2"
                          ? _c("el-table-column", {
                              attrs: { prop: "time", label: "时间" },
                            })
                          : _vm._e(),
                        _vm._v(" 货品名称 "),
                        _c("el-table-column", {
                          attrs: { prop: "goodsName", label: "货品名称" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "goodsType", label: "所属大类" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "categoryName", label: "所属类别" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "specifications", label: "规格" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "sourceName", label: "产地" },
                        }),
                        _c("el-table-column", {
                          staticStyle: { color: "black" },
                          attrs: { prop: "minPrice", label: "最低价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.minPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1187187298
                          ),
                        }),
                        _c("el-table-column", {
                          staticStyle: { color: "black" },
                          attrs: { prop: "maxPrice", label: "最高价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.maxPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3276699068
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "avgPrice", label: "均价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.avgPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2189863512
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "priceGrowth",
                            label: "较昨日均价涨幅",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.toFiveNum(scope.row)
                                      ? _c("span", [_vm._v("-")])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.priceGrowth > 0
                                                ? "c-red"
                                                : "c-green",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toFixedNum")(
                                                  scope.row.priceGrowth || "-"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1363606064
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "salesNum", label: "销售量 (数量)" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.salesNum)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3300296155
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "salesWeight",
                            label: "销售量 (公斤)",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.salesWeight)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            904963821
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "salesGrowth",
                            label: "较昨日销量涨幅",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.toFiveNum(scope.row)
                                      ? _c("span", [_vm._v("-")])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.salesGrowth > 0
                                                ? "c-red"
                                                : "c-green",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toFixedNum")(
                                                  scope.row.salesGrowth || "-"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2038189904
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "valueType", label: "价格取值方式" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color: _vm.statusColor(scope.row),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toValueType")(
                                              scope.row.valueType
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            304783781
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$getPermissionByRole("18") && _vm.spanIndex == "2"
                  ? _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "table-data-list",
                        attrs: {
                          stripe: "",
                          data: _vm.tableData,
                          "header-row-class-name": "table-header",
                        },
                      },
                      [
                        _vm._v(" 时间 "),
                        _vm.spanIndex == "2"
                          ? _c("el-table-column", {
                              attrs: { prop: "time", label: "时间" },
                            })
                          : _vm._e(),
                        _vm._v(" 货品名称 "),
                        _c("el-table-column", {
                          attrs: { prop: "goodsName", label: "货品名称" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "goodsType", label: "所属大类" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "categoryName", label: "所属类别" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "specifications", label: "规格" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "sourceName", label: "产地" },
                        }),
                        _c("el-table-column", {
                          staticStyle: { color: "black" },
                          attrs: { prop: "minPrice", label: "最低价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.minPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1187187298
                          ),
                        }),
                        _c("el-table-column", {
                          staticStyle: { color: "black" },
                          attrs: { prop: "maxPrice", label: "最高价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.maxPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3276699068
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "avgPrice", label: "均价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.avgPrice)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2189863512
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "priceGrowth",
                            label: "较昨日均价涨幅",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.toFiveNum(scope.row)
                                      ? _c("span", [_vm._v("-")])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.priceGrowth > 0
                                                ? "c-red"
                                                : "c-green",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toFixedNum")(
                                                  scope.row.priceGrowth || "-"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1363606064
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "salesNum", label: "销售量 (数量)" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.salesNum)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3300296155
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "salesWeight",
                            label: "销售量 (公斤)",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toNaN")(scope.row.salesWeight)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            904963821
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "salesGrowth",
                            label: "较昨日销量涨幅",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.toFiveNum(scope.row)
                                      ? _c("span", [_vm._v("-")])
                                      : _c(
                                          "span",
                                          {
                                            class:
                                              scope.row.salesGrowth > 0
                                                ? "c-red"
                                                : "c-green",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toFixedNum")(
                                                  scope.row.salesGrowth || "-"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2038189904
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "valueType", label: "价格取值方式" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color: _vm.statusColor(scope.row),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toValueType")(
                                              scope.row.valueType
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            304783781
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, prev, pager, next",
                    total: _vm.total,
                    "page-size": _vm.params.size,
                    "current-page": _vm.params.current_page,
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                }),
                _vm.dialogFormVisible
                  ? _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "添加行情",
                          visible: _vm.dialogFormVisible,
                          width: "800px",
                          "before-close": _vm.dialogBeforeClose,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogFormVisible = $event
                          },
                          close: function ($event) {
                            return _vm.defaultData(_vm.form)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                attrs: {
                                  model: _vm.form,
                                  rules: _vm.rules,
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "市场",
                                                prop: "mid",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请选择市场",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleSelectFollow,
                                                  },
                                                  model: {
                                                    value: _vm.form.mid,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "mid",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.mid",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.followMarketList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.mid,
                                                      attrs: {
                                                        label: item.marketName,
                                                        value: item.mid,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "时间",
                                                prop: "importTime",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  format: "yyyy-MM-dd",
                                                  "value-format": "yyyy-MM-dd",
                                                  type: "date",
                                                  placeholder:
                                                    "选择开始日期时间",
                                                },
                                                model: {
                                                  value: _vm.form.importTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "importTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.importTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid-content tree_goods_type",
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "goosType",
                                              attrs: {
                                                label: "货品类别",
                                                prop: "categoryId",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  maxlength:
                                                    _vm.GLOBAL.maxlength,
                                                  placeholder: "请选择货品类别",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.showTreeGoodType(
                                                      "set"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form.categoryName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "categoryName",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.categoryName",
                                                },
                                              }),
                                              _vm.TreeGoodType
                                                ? _c(
                                                    "el-dialog",
                                                    {
                                                      staticClass: "tree_type",
                                                      attrs: {
                                                        visible:
                                                          _vm.TreeGoodType,
                                                        title: "货品分类",
                                                        modal: false,
                                                      },
                                                      on: {
                                                        "update:visible":
                                                          function ($event) {
                                                            _vm.TreeGoodType =
                                                              $event
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c("el-tree", {
                                                        ref: "treeForm",
                                                        attrs: {
                                                          data: _vm.treeData,
                                                          "node-key":
                                                            "categoryId",
                                                          props:
                                                            _vm.defaultProps,
                                                          "highlight-current": true,
                                                          "show-checkbox": "",
                                                          "check-strictly": "",
                                                        },
                                                        on: {
                                                          "check-change":
                                                            _vm.handleFormClick,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "货品名称",
                                                prop: "goodsName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  placeholder: "请输入货品名称",
                                                },
                                                model: {
                                                  value: _vm.form.goodsName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "goodsName",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form.goodsName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "最低价",
                                                prop: "minPrice",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  type: "number",
                                                  maxlength:
                                                    _vm.GLOBAL.maxlength,
                                                  placeholder: "请输入最低价",
                                                },
                                                model: {
                                                  value: _vm.form.minPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "minPrice",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form.minPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "最高价",
                                                prop: "maxPrice",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  type: "number",
                                                  maxlength:
                                                    _vm.GLOBAL.maxlength,
                                                  placeholder: "请输入最高价",
                                                },
                                                model: {
                                                  value: _vm.form.maxPrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "maxPrice",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form.maxPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "均价",
                                                prop: "averagePrice",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  type: "number",
                                                  maxlength:
                                                    _vm.GLOBAL.maxlength,
                                                  placeholder: "请输入均价",
                                                },
                                                model: {
                                                  value: _vm.form.averagePrice,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "averagePrice",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.averagePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "较昨日均价涨幅",
                                                prop: "priceGrowth",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    placeholder:
                                                      "请输入较昨日均价涨幅",
                                                  },
                                                  model: {
                                                    value: _vm.form.priceGrowth,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "priceGrowth",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.priceGrowth",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("%")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "销量",
                                                prop: "salesNum",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "请输入销量",
                                                },
                                                model: {
                                                  value: _vm.form.salesNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salesNum",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form.salesNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "grid-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "较昨日销量涨幅",
                                                prop: "minPrice",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    placeholder:
                                                      "请输入较昨日销量涨幅",
                                                  },
                                                  model: {
                                                    value: _vm.form.salesGrowth,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "salesGrowth",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.salesGrowth",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("%")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: { click: _vm.cancel },
                              },
                              [_vm._v("取 消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  loading: _vm.buttonLoading,
                                },
                                on: { click: _vm.addMarketQuotations },
                              },
                              [_vm._v("确 定 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      width: "600px",
                      visible: _vm.focusMarketFormVisible,
                      title: "添加关注市场",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.focusMarketFormVisible = $event
                      },
                      close: _vm.cancel,
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "ul",
                        [
                          _vm.$getPermissionByRole("34")
                            ? _c(
                                "div",
                                { staticClass: "textRight" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "success" },
                                      on: { click: _vm.addMarket },
                                    },
                                    [_vm._v("添加关注市场")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.addMarketList, function (market, index) {
                            return _c(
                              "div",
                              { staticClass: "market-list" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "m-r-20",
                                    staticStyle: { width: "240px" },
                                    attrs: { placeholder: "请选择市场" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleSelect(market, index)
                                      },
                                    },
                                    model: {
                                      value: market.mid,
                                      callback: function ($$v) {
                                        _vm.$set(market, "mid", $$v)
                                      },
                                      expression: "market.mid",
                                    },
                                  },
                                  _vm._l(_vm.mktList, function (item) {
                                    return _c("el-option", {
                                      key: item.mid,
                                      attrs: {
                                        label: item.marketName,
                                        value: item.mid,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                market.mid &&
                                market.mid != "dcc09f2d90d24ed792c36fdf3af33b96"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "click",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteMarket(market)
                                          },
                                        },
                                      },
                                      [
                                        market.mid !=
                                        "dcc09f2d90d24ed792c36fdf3af33b96"
                                          ? _c(
                                              "i",
                                              {
                                                staticClass: "iconfont f-s-20",
                                              },
                                              [_vm._v("")]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "textCenter" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "m-r-20 btn-focus",
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "m-l-20 btn-focus",
                                  attrs: { type: "success" },
                                  on: { click: _vm.setMarketList },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headTiter b-c-f p-t-10 p-l-20" }, [
      _c("label", [_vm._v("其他市场行情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }