<template>
  <div class="popertyDetails">
    <el-card header="费用信息">
      <div>
        <span>缴费时间:</span>
        <el-date-picker
          @change="getdateTime"
          v-model="dateTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM"
          format="yyyy-MM"
        >
        </el-date-picker>
        <span>状态:</span>
        <el-select v-model="states" clearable placeholder="请选择" @change="getStates">
          <el-option label="全部" value=" "></el-option>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="popertyDetails-table">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column prop="feeTypeName" label="费用类型"></el-table-column>
          <el-table-column prop="feeName" label="费用项目"> </el-table-column>
          <el-table-column prop="merchantName" label="商户姓名"> </el-table-column>
          <el-table-column prop="merchantPhone" label="联系手机"> </el-table-column>
          <el-table-column prop="startTime" label="缴费开始时间"> </el-table-column>
          <el-table-column prop="endTime" label="缴费结束时间"> </el-table-column>
          <el-table-column prop="receivableAmount" label="应收金额(元)"> </el-table-column>
          <el-table-column prop="receivedAmount" label="已收金额(元)"> </el-table-column>
          <el-table-column prop="uncollectAmount" label="未收金额(元)"> </el-table-column>
          <el-table-column prop="stateName" label="状态"></el-table-column>
          <el-table-column prop="operateTime" label="最新修改时间"> </el-table-column>
          <el-table-column prop="feeId" label="收费单号"> </el-table-column>
          <el-table-column prop="billNo" label="账单号"> </el-table-column>
        </el-table>
      </div>
      <div class="popertyDetails-table">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
export default {
  props: {
    typeName: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  name: 'popertyDetails',
  data() {
    return {
      dateTime: [],
      tableData: [],
      startMonth: '',
      endMonth: '',
      states: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      options: [
        {
          value: 0,
          label: '已缴'
        },
        {
          value: 1,
          label: '正常/未缴'
        },
        {
          value: 2,
          label: '欠费'
        },
        {
          value: 3,
          label: '部分缴费'
        },
        {
          value: 4,
          label: '部分欠费'
        }
      ],
      receivableTotalAmount: '', //应收
      receivedTotalAmount: '', //已收
      uncollectTotalAmount: '' //未收
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      }else{
          this.getboothFeeForeignList()
         
      }
    },
    typeName(val) {
      if (val === 'PopertyDetails') {
        this.getboothFeeForeignList()
      }
    }
  },
  methods: {
    getStates() {
      this.getboothFeeForeignList()
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 6) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = this.receivableTotalAmount
          } else {
            sums[index] = ''
          }
        }
        if (index === 7) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = this.receivedTotalAmount
          } else {
            sums[index] = ''
          }
        }
        if (index === 8) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = this.uncollectTotalAmount
          } else {
            sums[index] = ''
          }
        }
      })

      return sums
    },
    handleCurrentChange(current) {
      this.currentPage = current
      this.getboothFeeForeignList()
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.getboothFeeForeignList()
    },
    getboothFeeForeignList() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true,
        current: this.currentPage,
        pageSize: this.pageSize,
        startMonth: this.startMonth,
        regionId: this.$route.query.data.regionId,
        boothId:this.$route.query.data.mrgId,
        states: this.states,
        endMonth: this.endMonth
      }
      marketplan
        .getboothFeeForeignList({ ...obj })
        .then(result => {
          this.tableData = result.data.data.page.data
          this.total = result.data.data.page.total
          this.receivableTotalAmount = result.data.data.receivableTotalAmount
          this.receivedTotalAmount = result.data.data.receivedTotalAmount
          this.uncollectTotalAmount = result.data.data.uncollectTotalAmount
        })
        .catch(err => {})
    },
    getdateTime(val) {
      if (!val) {
        this.startMonth = ''
        this.endMonth = ''
        this.getboothFeeForeignList()
      } else {
        this.startMonth = val[0]
        this.endMonth = val[1]
        this.getboothFeeForeignList()
      }
    }
  }
}
</script>
<style lang="scss">
.popertyDetails {
  .cell {
    text-align: center;
  }
  .popertyDetails-table {
    margin-top: 30px;
  }
  .el-table__footer-wrapper tbody td {
    color: red;
  }
}
</style>
