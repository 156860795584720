import Cookies from 'js-cookie'

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

export function dateTime(day = 1, hour = true) {
  let startTime, endTime;
  if (hour) {

    let hours = JSON.parse(Cookies.get("mouthsInfo")).statisticalTime; //系统时间
    let timeTmp = hours.split(":")[0];
    let curHours = formatDate(new Date(), "hh"); // 当前时间

    //  当前时间大于系统时间 结束时间加一
    if (+curHours >= timeTmp) {
      startTime = formatDate(new Date(new Date().getTime() - day * 86400000 + 86400000), "yyyy-MM-dd") + " " + hours + ":00";
      endTime = formatDate(new Date(new Date().getTime() + 86400000), "yyyy-MM-dd") + " " + hours + ":00";
    } else {
      // 小于系统时间结束时间不变
      startTime = formatDate(new Date(new Date().getTime() - day * 86400000), "yyyy-MM-dd") + " " + hours + ":00";
      endTime = formatDate(new Date(), "yyyy-MM-dd") + " " + hours + ":00";
    }
  } else {
    startTime = formatDate(new Date(new Date().getTime() - (day - 1) * 86400000), "yyyy-MM-dd") + " " + "00:00:00";
    endTime = formatDate(new Date(new Date().getTime()), "yyyy-MM-dd") + " " + "23:59:59";
  }

  return {startTime, endTime}
}

export function dataFormat(date) {
  if (!date) {
    return ''
  }
  const t = new Date(date)
  const year = t.getFullYear()
  const month = t.getMonth() + 1
  const day = t.getDate()
  const hour = t.getHours()
  const min = t.getMinutes()
  const sec = t.getSeconds()
  const newTime =
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (day < 10 ? '0' + day : day) +
    ' ' +
    (hour < 10 ? '0' + hour : hour) +
    ':' +
    (min < 10 ? '0' + min : min) +
    ':' +
    (sec < 10 ? '0' + sec : sec)
  return newTime
}

