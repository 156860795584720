<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* 菜篮子icon引入 */
@font-face {
  font-family: 'iconfont';  /* Project id 3381946 */
  src: url('//at.alicdn.com/t/c/font_3381946_fcpx40pjvqd.woff2?t=1662433306185') format('woff2'),
  url('//at.alicdn.com/t/c/font_3381946_fcpx40pjvqd.woff?t=1662433306185') format('woff'),
  url('//at.alicdn.com/t/c/font_3381946_fcpx40pjvqd.ttf?t=1662433306185') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-daochu:before {
  content: '\e61b';
}

html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}

body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
}

#app {
  min-width: 1200px;
  height: 100%;
  overflow-x: hidden;
}

/*市场价格分析--货品价格列表 应用样式*/
#lineeChart > div {
  width: 100% !important;
}

/*排序箭头颜色*/
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #33d57c;
}

.el-table .descending .sort-caret.descending {
  border-top-color: #33d57c;
}

/*.scroll::-webkit-scrollbar {*/
/*  width: 10px;*/
/*  height: 10px;*/
/*}*/
</style>
