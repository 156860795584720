import axios from '@/services'
import { serverMoudle } from '@/util/fetch.js'

export const
  districtManagement = {
    //添加区域
    ADD_REGION_INFO(data) {
      return axios({
        url: 'DNP_ADD_REGION_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //编辑区域
    DIET_REGION_INFO(data) {
      return axios({
        url: 'DNP_DIET_REGION_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //删除区域
    DEL_REGION_INFO(data) {
      return axios({
        url: 'DNP_DEL_REGION_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取区域档口编号
    GET_REGION_NUMBER_LIST(data) {
      return axios({
        url: 'DNP_GET_REGION_NUMBER_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //新增档口号
    ADD_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_ADD_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //编辑档口号
    DIET_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_DIET_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //删除档口号
    DEL_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_DEL_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //绑定区域档口号
    ADD_BIND_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_ADD_BIND_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //删除区域档口号
    DEL_BIND_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_DEL_BIND_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取区域档口绑定数据列表
    GET_REGION_GEAR_NUMBER_LIST(data) {
      return axios({
        url: 'DNP_GET_REGION_GEAR_NUMBER_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取未归类档口列表
    GET_NOT_DIVIDE_GEAR_LIST(data) {
      return axios({
        url: 'DNP_GET_NOT_DIVIDE_GEAR_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 添加标签
    ADD_REGION_LABEL(data) {
      return axios({
        url: 'DNP_ADD_REGION_LABEL',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 编辑标签
    DIET_REGION_LABEL(data) {
      return axios({
        url: 'DNP_DIET_REGION_LABEL',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 删除标签
    DEL_REGION_LABEL(data) {
      return axios({
        url: 'DNP_DEL_REGION_LABEL',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取商业类型数据
    GET_BUSINESS_TYPE_LIST(data) {
      return axios({
        url: 'DNP_GET_BUSINESS_TYPE_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 新区域管理获取数据
    GET_NEW_REGION_NUMBER_LIST(data) {
      return axios({
        url: 'DNP_GET_NEW_REGION_NUMBER_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  获取编辑档口信息
    GET_REGION_GEAR_NUMBER_INFO(data) {
      return axios({
        url: 'DNP_GET_REGION_GEAR_NUMBER_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },

    RequestMapping(data) {
      return axios({
        url: 'findForeignMctChargingNumByRegionId',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //查询删除的区域档口号列表
    regionNumberDto(data) {
      return axios({
        url: 'findDeleteRegionNumberList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    findRegionManagementNumberVoListByRegionId(data) {
      return axios({
        url: 'findRegionManagementNumberVoListByRegionId',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //恢复删除的物理档口号
    regionGearNumberDto(data) {
      return axios({
        url: 'rollBackDeleteRegionNumberByMrgId',
        method: 'post',
        data: serverMoudle(data)
      })
    },

    // 更新水电表状态
    updataMeterState(data) {
      return axios({
        url: '/meterWaterEle/devOnOff',
        method: 'post',
        data: serverMoudle(data)
      })
    },

    //
    getMaxPremiseIndex(data) {
      return axios({
        url: 'findMaxNumberPremiseIndex',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取区域信息

    getSELECT_MARK_REGION(data) {
      return axios({
        url: 'SELECT_MARK_REGION',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  标签列表
    GET_REGION_LABEL_LIST(data) {
      return axios({
        url: 'DNP_GET_REGION_LABEL_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    SAVE_MERCHANT_RETREAT_GEAR(data) {
      return axios({
        url: 'DNP_SAVE_MERCHANT_RETREAT_GEAR',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //商户
    DNP_FIND_MERCHANT_INFO_LIST(data) {
      return axios({
        url: 'DNP_FIND_MERCHANT_INFO_LIST',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //
    DNP_GEAR_LIST_OF_PHONE(data) {
      return axios({
        url: 'DNP_GEAR_LIST_OF_PHONE',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //绑定

    DNP_ADD_MERCHANT_SETTLE_INFO(data) {
      return axios({
        url: 'DNP_ADD_MERCHANT_SETTLE_INFO',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    DNP_ADD_BIND_REGION_GEAR_NUMBER(data) {
      return axios({
        url: 'DNP_ADD_BIND_REGION_GEAR_NUMBER',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //  获取货品类型
  getGoodCategoryTree(data) {
    return axios({
      url: 'CHENGFENG_GET_GOODS_CATEGORY_TREE',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  //  添加和修改商户信息
  SAVE_MERCHANT_INFO(data) {
    return axios({
      url: 'DNP_SAVE_MERCHANT_INFO',
      method: 'post',
      data: serverMoudle(data),

    })
  },
  };
