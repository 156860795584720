var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card mt-4" },
        [
          _c(
            "el-form",
            {
              ref: "screenForm",
              staticClass: "demo-form-inline cardForm",
              attrs: {
                inline: true,
                model: _vm.screenForm,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "carNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.screenForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.screenForm, "name", $$v)
                      },
                      expression: "screenForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.screenForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.screenForm, "phone", $$v)
                      },
                      expression: "screenForm.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色", prop: "carNumber" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.screenForm.roleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.screenForm, "roleId", $$v)
                        },
                        expression: "screenForm.roleId",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.roleId,
                        attrs: { label: item.roleName, value: item.roleId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "input",
                  attrs: { label: "区域大类", prop: "accountRegionName" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.screenForm.regionTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.screenForm, "regionTypeId", $$v)
                        },
                        expression: "screenForm.regionTypeId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._l(_vm.regionalData, function (item, index) {
                        return _c("el-option", {
                          key: index.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "incomStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.screenForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.screenForm, "status", $$v)
                        },
                        expression: "screenForm.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _c("el-option", { attrs: { label: "启用", value: "Y" } }),
                      _c("el-option", { attrs: { label: "停用", value: "N" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "m-l-40",
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCurrentChange(1)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "m-l-40",
                  attrs: { plain: "" },
                  on: { click: _vm.restScreenForm },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _vm.$getPermissionByRole("21")
            ? _c(
                "el-row",
                [
                  _c("el-col", [
                    _c(
                      "div",
                      { staticClass: "textRight" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", plain: "" },
                            on: { click: _vm.openAddForm },
                          },
                          [_vm._v("添加员工")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "table-conter b-c-f m-t-20" },
        [
          _vm.$getPermissionByRole("20")
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "96%", margin: "0 auto" },
                  attrs: { data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "姓名" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "roleName", label: "角色" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "regionTypesStr", label: "区域大类" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status === "Y"
                                ? _c("span", [_vm._v("正常")])
                                : _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("停用")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      24698702
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.$getPermissionByRole("22")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditStaff(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2774948496
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.screenForm.current,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.screenForm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogFormVisible, width: "35%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticStyle: { width: "400px" },
              attrs: { model: _vm.addForm, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    "label-width": _vm.formLabelWidth,
                    prop: "userName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.addForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "userName", $$v)
                      },
                      expression: "addForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    "label-width": _vm.formLabelWidth,
                    prop: "phone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.addForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "phone", $$v)
                      },
                      expression: "addForm.phone",
                    },
                  }),
                ],
                1
              ),
              !_vm.isEdit
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "登录密码",
                        "label-width": _vm.formLabelWidth,
                        prop: "password",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入登录密码" },
                        model: {
                          value: _vm.addForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "password", $$v)
                          },
                          expression: "addForm.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "登录密码",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入登录密码" },
                        model: {
                          value: _vm.addForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "password", $$v)
                          },
                          expression: "addForm.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "input",
                  attrs: {
                    label: "归属区域大类",
                    "label-width": _vm.formLabelWidth,
                    prop: "addForm.regionTypeIds",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", multiple: "" },
                      on: { change: _vm.regionTypeIdsChange },
                      model: {
                        value: _vm.addForm.regionTypeIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "regionTypeIds", $$v)
                        },
                        expression: "addForm.regionTypeIds",
                      },
                    },
                    _vm._l(_vm.regionalData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色",
                    "label-width": _vm.formLabelWidth,
                    prop: "addForm.roleList",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.roleChange },
                      model: {
                        value: _vm.addForm.roleList,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "roleList", $$v)
                        },
                        expression: "addForm.roleList",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return item.roleId
                        ? _c(
                            "el-checkbox",
                            { key: item.roleId, attrs: { label: item.roleId } },
                            [_vm._v(" " + _vm._s(item.roleName) + " ")]
                          )
                        : _vm._e()
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.tempStatus,
                        callback: function ($$v) {
                          _vm.tempStatus = $$v
                        },
                        expression: "tempStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "正常", value: "Y" } }),
                      _c("el-option", { attrs: { label: "停用", value: "N" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deldetVisible, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.deldetVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prompt" }, [
            _c("p", { staticClass: "m-b-20" }, [_vm._v("确认删除该员工吗?")]),
            _c("p", { staticClass: "color87" }, [
              _vm._v("删除后将无法登录系统"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deldetVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteStaff } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }