<template>
  <div class="lncoming">
    <el-card header="费用信息">
      <div>
        <span>进场时间:</span>
        <el-date-picker
          @change="getdateTime"
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div>
        <el-row class="lncoming-row">
          <el-col :span="4">来货车次数:{{ totalTrainsCount }}</el-col>
          <el-col :span="4">报备重量:{{ weight }}吨</el-col>
          <el-col :span="4">真实来货重量:{{ cargoWeight }}吨</el-col>
          <el-col :span="4">实收金额:{{ actuallyAmount }}元</el-col>
        </el-row>
      </div>
      <div class="lncoming-table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="collectionTime" label="进场时间"> </el-table-column>
          <el-table-column prop="trainNum" label="车牌号码"></el-table-column>
          <el-table-column prop="incomType" label="报备情况"> </el-table-column>
          <el-table-column prop="merchantName" label="商户"> </el-table-column>
          <el-table-column prop="phone" label="联系手机"> </el-table-column>
          <el-table-column prop="weight" label="称重"> </el-table-column>
          <el-table-column prop="tareWeight" label="皮重(车重)"> </el-table-column>
          <el-table-column prop="cargoWeight" label="计费货重"> </el-table-column>
          <el-table-column prop="actuallyAmount" label="实收金额(元)"> </el-table-column>
          <el-table-column prop="payMethod" label="收款方式"> </el-table-column>
          <el-table-column prop="payType" label="支付方式"> </el-table-column>
          <el-table-column prop="payeeName" label="收款人"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="pushUrl(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
export default {
  props: {
    list: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateTime: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 1, // 总条数
      startTime: '',
      endTime: '',
      tableData: [],
      totalTrainsCount: 0,
      weight: 0,
      cargoWeight: 0,
      actuallyAmount: 0
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      } else {
        this.getIncomingTransactionDetails()
      }
    },
    typeName(val) {
      if (val === 'Incoming') {
        this.getIncomingTransactionDetails()
      }
    }
  },
  methods: {
    getdateTime(val) {
      if (!val) {
        this.startTime = ''
        this.endTime = ''
        this.getIncomingTransactionDetails()
      } else {
        this.startTime = val[0]
        this.endTime = val[1]
        this.getIncomingTransactionDetails()
      }
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.getIncomingTransactionDetails()
    },
    handleCurrentChange(curren) {
      this.currentPage = curren
      this.getIncomingTransactionDetails()
    },
    pushUrl(item) {
      let incomTid = item.incomTid
      //来货车跳转报备详情
      this.$router.push({
        name: 'trainInfo',
        params: {
          incomTid
        }
      })
    },
    getIncomingTransactionDetails() {
      let obj = {
        mrgId: this.$route.query.data.mrgId,
        startTime: this.startTime,
        endTime: this.endTime,
        marketId: this.$route.query.data.marketId,
        current: this.currentPage,
        pageSize: this.pageSize
      }
      marketplan
        .getIncomingTransactionDetails({ ...obj })
        .then((result) => {
          this.tableData = result.data.results.list
          this.totalCount = result.data.results.total
          this.totalTrainsCount = result.data.results.totalList[0].totalTrainsCount
          this.weight = result.data.results.totalList[0].weight
          this.cargoWeight = result.data.results.totalList[0].cargoWeight
          this.actuallyAmount = result.data.results.totalList[0].actuallyAmount
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCurrentChange() {},
    handleSizeChange() {}
  }
}
</script>
<style lang="scss">
.lncoming {
  font-size: 15px;
  .el-date-editor .el-range-separator {
    width: 7%;
  }
  .lncoming-row {
    margin: 40px 0px 40px 0px;
  }
  .lncoming-table {
    .cell {
      text-align: center;
    }
    margin-bottom: 20px;
  }
}
</style>
