export function format(num) {
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num))
    num = "0";
  var sign = (num == (num = Math.abs(num)));
  num = Math.floor(num * 100 + 0.50000000001);
  var cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10)
    cents = "0" + cents;
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
      num.substring(num.length - (4 * i + 3));

  // return (((sign)?'':'-') + num + '.' + cents);
  return (((sign) ? '' : '-') + num);
}

//

export function ADecimal(val) {
  if (typeof val !== 'number') {
    val = isNaN(Number(val)) ? 0 : Number(val);
  }
  return val.toFixed(1)
}

export function floor(val) {
  if (typeof val !== 'number') {
    val = isNaN(Number(val)) ? 0 : Number(val);
  }
  return Math.floor(val)
}

export function sex(sex) {

  return sex === 0 ? '女' : '男'
}

export function tonne(val) {
  return ((val || 0) / 1000).toFixed(2)
}

export function rechargeChannel(val) {
  const rechargeChannelObj = {
    dnp: '管理平台',
    app: '鲜道赢',
    weChat: '小程序',
  }
  return rechargeChannelObj[val.rechargeChannel]
}

export function setType(val) {
  switch (val) {
    case 'Y':
      return '已入驻'
    case 'N':
      return '未入驻'
  }
}

export function stateType(val) {
  switch (val) {
    case 'Y':
      return '否'
    case 'N':
      return '是'
  }
}

export function chargingStatus(val) {
  // 收费状态
  switch (val) {
    case '1':
      return '未收费'
    case '2':
      return '收费中'
    case '3':
      return '收费结束'
  }
}

export function typeCharge(val) {
  // 费用新增
  switch (val) {
    case '1':
      return '物业费'
    case '2':
      return '押金'
    case '3':
      return '租金'
    case '4':
      return '卫生费'
    case '5':
      return '管理费'
    case '6':
      return '水费'
    case '7':
      return '电费'
    case '8':
      return '加建设施费'
    case '9':
      return '其他'
  }
}


