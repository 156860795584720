<template>
  <div>
    <div class="header">
      <slot name="title"></slot>
      <slot name="submit" :dataList="dataList"></slot>
    </div>
    <ul>
      <li
        v-for="(item,index) in dataList" :key="index"
        class="list-border m-t-20 m-b-20 m-l-20 m-r-20">
        <h5 class="m-b-20">{{ item.moduleName }}</h5>
        <ul class="m-b-20 module-item">
          <li class="m-r-20 func-item"
              :class="{active : item.effect === 'Y'}"
              @click="setAllFunc(index,item.effect)">
            <i class="iconfont" :class="{active : item.effect === 'Y'}">&#xe632;</i>
            <i>全选</i>
          </li>
          <li class="m-r-20 func-item"
              v-for="(func,idx) in item.funcId"
              :key='func.funcId'
              :class="{active : func.effect === 'Y'}"
              @click="setFuncId(index,idx, func.effect)">
            <i class="iconfont" :class="{active : func.effect === 'Y'}">&#xe632;</i>
            <i>{{ func.funcName }}</i>
          </li>
        </ul>

      </li>
    </ul>
  </div>
</template>

<script>
import {staff} from '../api'

export default {
  name: "permissionList",
  data() {
    return {
      dataList: [],
      roleData: [],
    }
  },
  props: {
    permissionData: {
      type: Array,
      required: false
    },
    roleRermission: {
      type: Array,
      required: false
    }
  },
  created() {
  },
  mounted() {
    this.dataList = JSON.parse(JSON.stringify(this.permissionData))
  },
  methods: {
    setFuncId(index, idx, effect) {
      if (!effect) {
        this.$set(this.dataList[index].funcId[idx], 'effect', 'Y');
      } else {
        let off = effect === 'Y' ? 'N' : 'Y';
        if (off === 'N') {
          this.dataList[index].effect = 'N';
        }
        this.dataList[index].funcId[idx].effect = off;
      }
      let falg = false;
      let len = this.dataList[index].funcId.length;
      for (let i = 0; i < len; i++) {
        let off = this.dataList[index].funcId[i].effect;
        if (off === 'N' || !off) {
          falg = true;
          return
        }
      }

      if (!falg) {
        this.dataList[index].effect = 'Y';
      }
    },
    setAllFunc(index, effect) {
      if (!effect) {
        this.$set(this.dataList[index], 'effect', 'Y');
        this.dataList[index].funcId.forEach(item => {
          this.$set(item, 'effect', 'Y');
        })
      } else {
        this.dataList[index].effect = effect === 'Y' ? 'N' : 'Y';
        this.dataList[index].funcId.forEach(item => {
          item.effect = effect === 'Y' ? 'N' : 'Y';
        })
      }

    },
    setRolePer() {
      if (this.roleData.length) {
        this.roleData.forEach(role => {
          this.dataList.forEach((item, index) => {
            item.funcId.forEach((func, idx) => {
              if (func.funcId === role.funcId) {
                this.setFuncId(index, idx)
              }
            })
          })
        })

      }
    },
    submit() {
      this.$emit('submit', this.dataList)
    },
  },
  watch: {
    permissionData() {
      this.dataList = JSON.parse(JSON.stringify(this.permissionData));
    },
    roleRermission() {
      this.roleData = JSON.parse(JSON.stringify(this.roleRermission));
      this.setRolePer()
    },
  },
}
</script>

<style scoped>
li {
  list-style: none;
}

.module-item {
  display: flex;
  flex-wrap: wrap;
}

.func-item {
  cursor: pointer;
}

.list-border {
  border-bottom: 1px solid #ccc;
}

.iconfont {
  color: #cccccc;
}

.active {
  color: #33D57C !important;
}

.header {
  display: flex;
  justify-content: space-between;
}
</style>
