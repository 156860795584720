export const store = {
  // 状态
  state: {
    screenForm: {
      operator: "",
      tpId: "",
      purchaseTypeId: "",
      userName: "",
      phone: "",
      trainNum: '',
      startTime: "",
      endTime: "",
      goodsType: "",
      page_size: 10,
      current_page: 1,
      status: "",
      bool: true,
    },
  },
  // 修改状态
  mutations: {
    SET_SCREEN_FORM: (state, data) => {
      state.screenForm = {...state.screenForm, ...data};
    },


  },
  // 异步修改状态
  actions: {},
  namespaced: true
};
