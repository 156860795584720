export default [{
    path: 'testReport',
    name: 'testReport',
    component: () => import('./views/testReportList'),
    meta: {
      title: "检测报告列表"
    }
  },
  {
    path: 'certificatePrinting',
    name: 'certificatePrinting',
    component: () => import('./components/certificatePrinting.vue'),
    meta: {
      title: "打印"
    }
  }]
