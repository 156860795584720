var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "basicinformation" } },
            [
              _c("Basicinformation", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "物业费", name: "PopertyDetails" } },
            [
              _c("PopertyDetails", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "水电抄表", name: "hydroelectric" } },
            [
              _c("Hydroelectric", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "来货交易", name: "Incoming" } },
            [
              _c("Incoming", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分记录", name: "IntegralRecord" } },
            [
              _c("IntegralRecord", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "修改记录", name: "modify" } },
            [
              _c("Modify", {
                attrs: { typeName: _vm.activeName, list: _vm.list },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }