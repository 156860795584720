export default [
  {
    path: 'purchaserManagement',
    name: 'purchaserManagement',
    component: () => import('./views/purchaserManagement'),
    meta: {
      title: "积分用户管理"
    }
  },{
    path: 'purchaserCreditsLog/:uid',
    name: 'purchaserCreditsLog',
    component: () => import('./views/purchaserCreditsLog'),
    meta: {
      title: "积分用户管理记录"
    }
  },
  {
    path: 'purchaserWeighingRecord/:trainNum',
    name: 'purchaserWeighingRecord',
    component: () => import('./views/purchaserWeighingRecord'),
    meta: {
      title: "采购商过磅记录"
    }
  },
  {
    path: 'purchaserView',
    name: 'purchaserView',
    component: () => import('./views/purchaserView'),
    meta: {
      title: "采购商基础信息"
    }
  }

]
