var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-menu",
        {
          attrs: { "default-active": _vm.$route.path, mode: "horizontal" },
          on: { select: _vm.handleSelect },
        },
        [
          _vm.$getPermissionByRole("20")
            ? _c("el-menu-item", { attrs: { index: "/staff/staffList" } }, [
                _vm._v("员工角色分配"),
              ])
            : _vm._e(),
          _vm.$getPermissionByRole("49")
            ? _c(
                "el-menu-item",
                { attrs: { index: "/staff/staffPermission" } },
                [_vm._v("角色权限配置")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("router-view", { attrs: { name: "staff" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }