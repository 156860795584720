var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lncoming" },
    [
      _c("el-card", { attrs: { header: "费用信息" } }, [
        _c(
          "div",
          [
            _c("span", [_vm._v("进场时间:")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "value-format": "yyyy-MM-dd HH:mm:ss",
                format: "yyyy-MM-dd HH:mm:ss",
              },
              on: { change: _vm.getdateTime },
              model: {
                value: _vm.dateTime,
                callback: function ($$v) {
                  _vm.dateTime = $$v
                },
                expression: "dateTime",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-row",
              { staticClass: "lncoming-row" },
              [
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("来货车次数:" + _vm._s(_vm.totalTrainsCount)),
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("报备重量:" + _vm._s(_vm.weight) + "吨"),
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("真实来货重量:" + _vm._s(_vm.cargoWeight) + "吨"),
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("实收金额:" + _vm._s(_vm.actuallyAmount) + "元"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "lncoming-table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "collectionTime", label: "进场时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "trainNum", label: "车牌号码" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "incomType", label: "报备情况" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "merchantName", label: "商户" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "phone", label: "联系手机" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "weight", label: "称重" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "tareWeight", label: "皮重(车重)" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "cargoWeight", label: "计费货重" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "actuallyAmount", label: "实收金额(元)" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "payMethod", label: "收款方式" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "payType", label: "支付方式" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "payeeName", label: "收款人" },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.pushUrl(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                "current-page": _vm.currentPage,
                "page-sizes": [10, 20, 30, 40],
                "page-size": _vm.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.totalCount,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }