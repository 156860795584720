import axios from '@/services'
import { serverMoudle } from '@/util/fetch.js'

export const approachPrepare = {
  //  停车费收费记录-列表查询
  getParkingFeeList(data) {
    return axios({
      url: '/car/parkingFeeList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //交易情况分析  获取市场交易情况---品类销售列表
  INCOM_TRAINSTATISTICS_TITLE(data) {
    return axios({
      url: 'DNP_INCOM_TRAINSTATISTICS_TITLE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //新增查询,返回查询结果
  queryTransportTraceability(data) {
    return axios({
      url: 'queryTransportTraceability',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  getTransportTimeManageInfo(data) {
    return axios({
      url: 'getTransportTimeManageInfo',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //导出
  exportRecordList(data) {
    return axios({
      url: 'exportRecordList',
      method: 'post',
      data: serverMoudle(data),
      responseType: 'blob'
    })
  },
  //获取查询城市溯源详情
  getTransportAreaTraceInfo(data) {
    return axios({
      url: 'getTransportAreaTraceInfo',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  INCOM_TRAINSTATISTICS_BYDAY(data) {
    return axios({
      url: 'DNP_INCOM_TRAINSTATISTICS_BYDAY',
      method: 'post',
      data: serverMoudle(data)
    })
  },
   //  标签列表
   GET_REGION_LABEL_LIST(data) {
    return axios({
      url: 'DNP_GET_REGION_LABEL_LIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  GET_INCOM_TRAINSLIST(data) {
    return axios({
      url: 'DNP_GET_INCOM_TRAINSLIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  TRAIN_ARRIVAL_DETAILS(data) {
    return axios({
      url: 'DNP_GET_TRAIN_ARRIVAL_DETAILS',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  INCOM_TRAINSLIST_COMFITM(data) {
    return axios({
      url: 'DNP_GET_INCOM_TRAINSLIST_COMFITM_TO_NO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  区域列表
  SELECT_MARK_REGION(data) {
    return axios({
      url: 'SELECT_MARK_REGION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  商户管理页面指定区域列表
  SELECT_MARK_REGION_BY_MERCHANT(data) {
    return axios({
      url: 'SELECT_MARK_REGION_BY_MERCHANT',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  获取区域的物理档口列表
  PHYSICAL_GEAR_LIST(data) {
    return axios({
      url: 'DNP_GET_PHYSICAL_GEAR_LIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
//  商户管理页面指定获取区域的物理档口列表
  DNP_GET_MARK_REGION_NUMBER_LIST_BY_MERCHANT(data) {
    return axios({
      url: 'DNP_GET_MARK_REGION_NUMBER_LIST_BY_MERCHANT',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  编辑档口区域
  INCOMTRAINS_GEARNUMBER(data) {
    return axios({
      url: 'DNP_EDIR_INCOMTRAINS_GEARNUMBER',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  //车辆报备数据日报表

  //  车辆报备数据日报表---数据总览接口
  DATA_OVERVIEW(data) {
    return axios({
      url: 'DNP_GET_THE_DATA_OVERVIEW',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  车辆报备数据日报表---车次报备状态信息
  STATUS_INFO(data) {
    return axios({
      url: 'DNP_GET_INCOMTRAIN_STATUS_INFO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  区域重量信息
  INCOMTRAINS_AREAEIGHT(data) {
    return axios({
      url: 'DNP_GET_INCOMTRAINS_AREAEIGHT',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  报备进场-产地来源分部信息
  ORIGIN_SUBINFORMATION(data) {
    return axios({
      url: 'DNP_GET_ORIGIN_SUBINFORMATION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  区域车次分布信息
  DISTRIBUTION_INFORMATION(data) {
    return axios({
      url: 'DNP_REGIONAL_TRAINNUMBER_DISTRIBUTION_INFORMATION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  今日已进场车型分部
  VEHICLESENTERING_THEAMARKETTODAY(data) {
    return axios({
      url: 'DNP_DISTRIBUTION_OF_VEHICLESENTERING_THEAMARKETTODAY',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //   品类分部情况
  AND_DIVISION(data) {
    return axios({
      url: 'DNP_CATEGORY_AND_DIVISION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  市场留存进货量表格 tableOfRetainedStockInMarket
  STOCK_INMARKET(data) {
    return axios({
      url: 'DNP_TABLE_OF_RETAINED_STOCK_INMARKET',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取报备预警值

  GET_WARNING_VALUE(data) {
    return axios({
      url: 'DNP_GET_WARNING_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //添加和更新报备预警值
  SET_WARNING_VALUE(data) {
    return axios({
      url: 'DNP_SET_WARNING_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取报备预警值列表
  GET_INCOMTRAINS_WARNVALUE(data) {
    return axios({
      url: 'DNP_GET_INCOMTRAINS_WARNVALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //车次优化
  GET_INCOMING_TRAINS_REPORT_LIST(data) {
    return axios({
      url: '/incomingTrainsReportList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  来货查询列表
  GET_INCOMING_NETWEIGHT_QUERY(data) {
    return axios({
      url: 'DNP_GET_INCOMING_NETWEIGHT_QUERY',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  GET_INCOMING_NETWEIGHT_INFO(data) {
    return axios({
      url: 'DNP_GET_INCOMING_NETWEIGHT_INFO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //编辑车次详情
  DNP_UPDATE_INCOMTRAINSINFO(data) {
    return axios({
      url: 'DNP_UPDATE_INCOMTRAINSINFO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //编辑车次详情
  updateIncomTrains(data) {
    return axios({
      url: 'updateIncomTrains',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  获取车型数据
  CHENGFENG_FIND_MARKET_TRAINSMODEL(data) {
    return axios({
      url: 'CHENGFENG_FIND_MARKET_TRAINSMODEL',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  //  修改新增车型
  MARKET_TRAINSMODEL_INFO(data, type) {
    return axios({
      url: 'CHENGFENG_SAVEORUPDATE_MARKET_TRAINSMODEL_INFO/' + type,
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //  CHENGFENG_UPDATE_MARKET_EFFECTIVETIME
  //  修改有效报备信息时间
  CHENGFENG_UPDATE_MARKET_EFFECTIVETIME(data) {
    return axios({
      url: 'CHENGFENG_UPDATE_MARKET_EFFECTIVETIME',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取报备费用信息(交易费，停车费)
  DNP_FIND_INCOMING_FREE(data) {
    return axios({
      url: 'DNP_FIND_INCOMING_FREE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取区域汇总
  findMerchantIncomingCollectByRegionList(data) {
    return axios({
      url: 'merchant/findMerchantIncomingCollectByRegionList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取区域汇总
  findRegionIncomingCollectInfoList(data) {
    return axios({
      url: 'merchant/findRegionIncomingCollectInfoList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取区域汇总
  findMerchantIncomingInfoByNumber(data) {
    return axios({
      url: 'merchant/findMerchantIncomingInfoByNumber',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取档口号
  findMarkRegionNumberListByRegionIdAndLikeNumber(data) {
    return axios({
      url: 'merchant/findMarkRegionNumberListByRegionIdAndLikeNumber',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 导出商户数据
  findAllRegionIncomingCollectInfoList(data) {
    return axios({
      url: 'merchant/findAllRegionIncomingCollectInfoList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 获取防疫报备列表
  getInRegList(data) {
    return axios({
      url: 'tripReport/findTripReports',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  // 获取防疫报备列表
  getInRegDetailBySort(data) {
    return axios({
      url: 'tripReport/findTripReportBySort',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  getInRegDetail(data) {
    return axios({
      url: 'tripReport/findTripReportById',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  getInRegLogs(data) {
    return axios({
      url: 'tripReport/findTripReportLogsByReportId',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  updateInRegDetail(data) {
    return axios({
      url: 'tripReport/save',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  exportInRegExcel(data) {
    return axios({
      url: 'tripReport/excelExport',
      method: 'post',
      data: serverMoudle(data),
      responseType: 'blob'
    })
  }
}


export const regionalInformation = {
  list(data) {
    return axios({
      url: 'incoming/regionTrain/rule/list',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  add(data) {
    return axios({
      url: 'incoming/regionTrain/rule/save',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  des(data) {
    return axios({
      url: 'incoming/regionTrain/rule/find',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  status(data) {
    return axios({
      url: 'incoming/regionTrain/rule/enable',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  edit(data) {
    return axios({
      url: 'incoming/regionTrain/rule/save',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  delete(data) {
    return axios({
      url: 'market/area/delete',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  regionSelect(data) {
    return axios({
      url: 'SELECT_MARK_REGION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  getCarTypes(data) {
    return axios({
      url: 'incoming/regionTrain/rule/getCarTypes',
      method: 'post',
      data: serverMoudle(data)
    })
  },
}


export const epManage = {
  add(data) {
    return axios({
      url: 'epidemic/personnel/list/addUser',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  edit(data) {
    return axios({
      url: 'epidemic/personnel/list/updateUser',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  list(data) {
    return axios({
      url: 'epidemic/personnel/list',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  select(data) {
    return axios({
      url: 'epidemic/personnel/getUserTypes',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  export(data) {
    return axios({
      url: 'epidemic/personnel/list/export',
      method: 'post',
      data: serverMoudle(data),
      responseType: 'blob'
    })
  },
}


export const UserInformationAudit = {
  edit(data) {
    return axios({
      url: 'epidemic/personnel/list/updateAudit',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  list(data) {
    return axios({
      url: 'epidemic/personnel/findUserAuditInfo',
      method: 'post',
      data: serverMoudle(data)
    })
  },
}

export const edipeMic ={
  // 修改用户信息审核
  updateAudit(data) {
    return axios({
      url: '/epidemic/personnel/list/updateAudit',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 打卡记录人员信息修改
  updateUser(data) {
    return axios({
      url: '/epidemic/personnel/list/updateUser',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 获取审核的用户信息
  findUserAuditInfo(data) {
    return axios({
      url: '/epidemic/personnel/findUserAuditInfo',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 查询字典
  findDictList(data) {
    return axios({
      url: '/epidemic/dict/list',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 查询字典
  getUserTypes(data) {
    return axios({
      url: '/epidemic/personnel/getUserTypes',
      method: 'post',
      data: serverMoudle(data)
    })
  },
}

export const epMList={

  // 获取列表
  tripUserList(data) {
    return axios({
      url: '/epidemic/personnel/tripUser/list',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 导出
  export(data) {
    return axios({
      url: '/epidemic/personnel/tripUser/list/export',
      method: 'post',
      data: serverMoudle(data),
      responseType: 'blob'
    })
  },

}

export const dataAuthManagement = {
  add(data) {
    return axios({
      url: 'epidemic/authorizedUser/save',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  edit(data) {
    return axios({
      url: 'epidemic/authorizedUser/update',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  list(data) {
    return axios({
      url: 'epidemic/authorizedUser/list',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  status(data) {
    return axios({
      url: 'epidemic/authorizedUser/update',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  select(data) {
    return axios({
      url: 'epidemic/gate/getGates',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  des(data) {
    return axios({
      url: 'epidemic/authorizedUser/findById',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  userSelect(data) {
    return axios({
      url: 'getDnpUsers',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  export(data) {
    return axios({
      url: 'epidemic/authorizedUser/list/export',
      method: 'post',
      data: serverMoudle(data),
      responseType: 'blob'
    })
  }
}
