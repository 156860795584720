import axios from '@/services'
import {serverMoudle} from '@/util/fetch.js'

export const integralManagement = {
    //查询积分流水列表总计
    getPeasFlowFlistTotal(data) {
      return axios({
        url: '/peasFlow/list/total',
        method: 'post',
        data: serverMoudle(data)
      })
    },
     //查询流水发放状态列表
     getPeasFlowFindFlowStatus(data) {
      return axios({
        url: '/peasFlow/findFlowStatus',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //查询货物类型列表
    getPeasFlowGoodsTypes(data) {
      return axios({
        url: '/peasFlow/goodsTypes',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-手动派发
    getManualDistribution(data) {
      return axios({
        url: '/peas/manualDistribution',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-规则计算派发鲜豆数量
    getRuleCalculation(data) {
      return axios({
        url: '/peas/ruleCalculation',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-获取过磅称重列表
    getWeightList(data) {
      return axios({
        url: '/peas/weightList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-获取采购商列表
    getPurchaserList(data) {
      return axios({
        url: '/peas/purchaserList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //积分流水列表导出
    getExportpeasFlow(data) {
      return axios({
        url: '/peasFlow/list/export',
        method: 'post',
        data: serverMoudle(data),
        responseType: 'blob'
      })
    },
    //查询积分流水列表
    getpeasFlowList(data) {
      return axios({
        url: '/peasFlow/list',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-账户合计鲜豆
    getpeasTotal(data) {
      return axios({
        url: '/peas/total',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //鲜豆-详情-合计鲜豆
    getAccountInfoTotal(data) {
      return axios({
        url: '/peas/accountInfoTotal',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //导出
    getpeasExport(data) {
      return axios({
        url: '/peas/export',
        method: 'post',
        data: serverMoudle(data),
        responseType: 'blob',
      })
    },
    // 鲜豆-账户列表-详情
    getAccountInfo(data) {
      return axios({
        url: '/peas/accountInfo',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 鲜豆-账户列表-详情
    getAccountList(data) {
      return axios({
        url: '/peas/accountList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 鲜豆-鲜豆兑换记录
    getExchangeList(data) {
      return axios({
        url: '/peas/exchangeGoodsRecord',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 鲜豆-鲜豆兑换记录合计数据
    getExchangeTotalList(data) {
      return axios({
        url: '/peas/exchangeGoodsRecordTotal',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 鲜豆-鲜豆兑换记录导出
    getExchangeGoodsList(data) {
      return axios({
        url: '/peas/exportExchangeGoodsRecord',
        method: 'post',
        data: serverMoudle(data),
        responseType: 'blob',
      })
    },
    // 获取积分发放量
    OBTAIN_INTEGRAL_DISTRIBUTION_VOLUME(data) {
      return axios({
        url: 'DNP_OBTAIN_INTEGRAL_DISTRIBUTION_VOLUME',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 采购货物量
    OBTAIN_INTEGRAL_WEIGHT_DISTRIBUTION_VOLUME(data) {
      return axios({
        url: 'DNP_OBTAIN_INTEGRAL_WEIGHT_DISTRIBUTION_VOLUME',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 采购商积分top Seven
    OBTAIN_PURCHASERS_SCORE_TOPSEVEN(data) {
      return axios({
        url: 'DNP_OBTAIN_PURCHASERS_SCORE_TOPSEVEN',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取交易情况
    INTEGRAL_TRADING(data) {
      return axios({
        url: 'DNP_OBTAIN_INTEGRAL_TRADING',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取积分采购商类型统计
    BUYER_TYPE_STATISTICS(data) {
      return axios({
        url: 'DNP_OBTAIN_INTEGRAL_BUYERTYPE_STATISTICS',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //采购商数量
    QUANTITY_OF_PURCHASERS(data) {
      return axios({
        url: 'DNP_QUANTITY_OF_PURCHASERS',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //采购商活跃统计--新增采购商数量
    ADD_QUANTITY_OF_PURCHASERS(data) {
      return axios({
        url: 'DNP_ADD_QUANTITY_OF_PURCHASERS',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取派发积分的次数
    NUMBER_OF_POINTS_DISTRIBUTED(data) {
      return axios({
        url: 'DNP_NUMBER_OF_POINTS_DISTRIBUTED',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //
    BUYER_ACTIVITY_STATISTICS_TITLE(data) {
      return axios({
        url: 'DNP_BUYER_ACTIVITY_STATISTICS_TITLE',
        method: 'post',
        data: serverMoudle(data)
      })
    },


    // 获取积分配置信息
    GET_INTEGRAL_CONFIG(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_CONFIG',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取南宁积分配置信息列表
    selectDnpIntegralConfig(data) {
      return axios({
        url: 'selectDnpIntegralConfig',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 编辑积分配置信息
    DIET_INTEGRAL_CONFIG(data) {
      return axios({
        url: 'DNP_DIET_INTEGRAL_CONFIG',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 查看积分发放记录
    GET_INTEGRAL_INFO(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分发放记录列表-导出
    integralExport(data) {
      return axios({
        url: 'integral/export',
        method: 'post',
        data: serverMoudle(data)
      })
    },
  // 后端新给的查看积分发放记录
  integralPage(data) {
    return axios({
      url: 'integral/page',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 根据积分记录id查询积分用户信息
  userQueryById(id) {
    return axios({
      url: `integral/user/queryById?diId=${id}`,
      method: 'get'
    })
  },
  // 积分用户管理-分页获取积分支出类型列表
  integralTypePage(data) {
    return axios({
      url: 'integral/type/page',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 积分派发数据统计
  distributeIntegralStatistics(data) {
    return axios({
      url: 'integral/count/distributeIntegralStatistics',
      method: 'post',
      data: serverMoudle(data)
    })
  },
    // 获取积分详情
    getIntegralDetail(id) {
      return axios({
        url: `detailById?diId=${id}`,
        method: 'get'
      })
    },
    // 获取发放积分操作员
    QUERY_INTEGRAL_OPERATOR(data) {
      return axios({
        url: 'DNP_QUERY_INTEGRAL_OPERATOR',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 编辑发放积分
    DIET_INTEGRAL_INFO(data) {
      return axios({
        url: 'DNP_DIET_INTEGRAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 撤销 发放积分
    DEL_INTEGRAL_INFO(data) {
      return axios({
        url: 'DNP_DEL_INTEGRAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分查询
    QUERY_INTEGRAL_INFO(data) {
      return axios({
        url: 'DNP_QUERY_INTEGRAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //获取积分过磅数据
// /DNP_INTEGRAL_WEIGH_INFO
// serialNumber phone
    DNP_INTEGRAL_WEIGH_INFO(data) {
      return axios({
        url: 'DNP_INTEGRAL_WEIGH_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  2.1.7版本二期
    //  查询档口数据    /DNP_FIND_GEAR_INFO"
    // param : search 搜索条件
    DNP_FIND_GEAR_INFO(data) {
      return axios({
        url: 'DNP_FIND_GEAR_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  2.查询档口可用积分列表   DNP_FIND_GEAR_AVAILABLE_INTEGAL_INFO
    // param： gid

    DNP_FIND_GEAR_AVAILABLE_INTEGAL_INFO(data) {
      return axios({
        url: 'DNP_FIND_GEAR_AVAILABLE_INTEGAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  3.档主兑换档口积分 /DNP_EXCHANGE_INTEGAL_FOR_OWNER
    DNP_EXCHANGE_INTEGAL_FOR_OWNER(data) {
      return axios({
        url: 'DNP_EXCHANGE_INTEGAL_FOR_OWNER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  4.获取档主积分兑换记录    /DNP_FIND_INTEGRAL_EXCHANGE_RECORD
    DNP_FIND_INTEGRAL_EXCHANGE_RECORD(data) {
      return axios({
        url: 'DNP_FIND_INTEGRAL_EXCHANGE_RECORD',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  获取员工列表 CHENGFENG_GET_DNPUSERLIST
    CHENGFENG_GET_DNPUSERLIST(data) {
      return axios({
        url: 'CHENGFENG_GET_DNPUSERLIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  5.获取档主积分兑换详情   /DNP_FIND_GEAR_INTEGRAL_EXCHANGE_RECORD
    // param : DigeId 兑换记录id
    DNP_FIND_GEAR_INTEGRAL_EXCHANGE_RECORD(data) {
      return axios({
        url: 'DNP_FIND_GEAR_INTEGRAL_EXCHANGE_RECORD',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  6。撤销档主积分兑换 /DNP_REVOKE_GEAR_INTEGRAL_EXCHANGE_INFO
    // param : DigeId 兑换记录id
    DNP_REVOKE_GEAR_INTEGRAL_EXCHANGE_INFO(data) {
      return axios({
        url: 'DNP_REVOKE_GEAR_INTEGRAL_EXCHANGE_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  7.查询供货商消费记录 DNP_FIND_SUPPLIER_INTEGRAL_CONSUMPTION_RECORDS
    DNP_FIND_SUPPLIER_INTEGRAL_CONSUMPTION_RECORDS(data) {
      return axios({
        url: 'DNP_FIND_SUPPLIER_INTEGRAL_CONSUMPTION_RECORDS',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取海南管理平台积分充值记录
    DNP_GET_INTEGRAL_RECHARGE_LIST(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_RECHARGE_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 海南管理平台积分充值扫描二维码
    DNP_INTEGRAL_RECHARGE_SCAN_QRCODE(data) {
      return axios({
        url: 'DNP_INTEGRAL_RECHARGE_SCAN_QRCODE',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 海南管理平台积分充值
    DNP_INTEGRAL_RECHARGE(data) {
      return axios({
        url: 'DNP_INTEGRAL_RECHARGE',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  日志接口
    DNP_ADD_EXPORT_OPERATION_LOG(data) {
      return axios({
        url: 'DNP_ADD_EXPORT_OPERATION_LOG',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  获取市场积分信用额度   参数 marketId
    DNP_FIND_INTEGRAL_CREDIT_BY_MARKET_ID(data) {
      return axios({
        url: 'DNP_FIND_INTEGRAL_CREDIT_BY_MARKET_ID',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  修改市场积分额度
    DNP_SAVE_INTEGRAL_CREDIT(data) {
      return axios({
        url: 'DNP_SAVE_INTEGRAL_CREDIT',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  获取鲜豆抵扣金额比例   参数 marketId
    DNP_FIND_PEAS_PROPORTION_BY_MARKET_ID(data) {
      return axios({
        url: 'DNP_FIND_PEAS_PROPORTION_BY_MARKET_ID',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  修改鲜豆抵扣金额比例
    DNP_SAVE_PEAS_PROPORTION(data) {
      return axios({
        url: 'DNP_SAVE_PEAS_PROPORTION',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    /**
     * @params:{
     *   typeName   积分类型
     *   remark
     * }
     *
     *
     * **/
    ADD_INTEGRAL_REFUND_TYPE(data) {
      return axios({
        url: 'DNP_ADD_INTEGRAL_REFUND_TYPE',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    /**
     * @params:{
     *   peName,
     *   typeId,
     *   startTime,
     *   endTime
     * }
     * **/
    GET_INTEGRAL_REFUND_TYPE(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_REFUND_TYPE',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取积分退款记录列表
    // typeName
    // typeId
    // phone
    // startTime,endTime
    GET_REFUND_INTEGRAL_LIST(data) {
      return axios({
        url: 'DNP_GET_REFUND_INTEGRAL_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  新增积分退款
    /**
     * @params{
     *   createId 操作人id
     *   phone
     *   integral
     *   userType (档口=gear,客户=customer)
     *   userName
     *   remark
     *   typeId 退款类型id
     *   uid （gear=gid,customer=客户 uid
     *   typeName 类型名称
     * }
     * **/

    ADD_REFUND_INTEGRAL_INFO(data) {
      return axios({
        url: 'DNP_ADD_REFUND_INTEGRAL_INFO',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取积分充值档口信息
    GET_INTEGRAL_RECHARGE_GEAR(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_RECHARGE_GEAR',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取积分充值档口信息
    GET_INTEGRAL_RECHARGE_CUSTOMER(data) {
      return axios({
        url: 'DNP_GET_INTEGRAL_RECHARGE_CUSTOMER',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取档口积分信息
    //  gearName，integralStatus（Y=正常。N=透支）userName，phone
    // regionName，regionId，number（物理档口号） mrgId（物理档口号Id),page
    GET_GEAR_INTEGRAL_INFO_LIST(data) {
      return axios({
        url: 'DNP_GET_GEAR_INTEGRAL_INFO_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    GET_MARK_REGION_NUMBER_LIST(data) {
      return axios({
        url: 'DNP_GET_MARK_REGION_NUMBER_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    GET_CUSTOMER_INTEGRAL_INFO_LIST(data) {
      return axios({
        url: 'DNP_GET_CUSTOMER_INTEGRAL_INFO_LIST',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //  海南管理平台积分充值撤销
    INTEGRAL_RECHARGE_REFUNDED(data) {
      return axios({
        url: 'DNP_INTEGRAL_RECHARGE_REFUNDED',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //查询采购商活动列表
    findActivityList(data) {
      return axios({
        url: 'integral/purchasers/findActivityList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    //查询活动详情
    findActivityInfo(data) {
      return axios({
        url: '/integral/purchasers/findActivityInfo',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 单个积分派发和多个积分派发
    grantIntegral(data) {
      return axios({
        url: 'integral/purchasers/grantIntegral',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //修改活动积分发放实发积分
    saveGrantIntegralById(data) {
      return axios({
        url: '/integral/purchasers/saveGrantIntegralById',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    //  撤销积分
    cancelIntegral(data) {
      return axios({
        url: '/integral/purchasers/cancelIntegral',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    // 查询奖励对应的积分明细 and 打印信息查询
    INTEGRAL_PURCHASERS_FIND_INTEGRAL_LIST_BY_ID(data) {
      return axios({
        url: 'integral/purchasers/findIntegralListById',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 查询活动详情
    INTEGRAL_PURCHASERS_FIND_ACTIVITY_INFO(data) {
      return axios({
        url: 'integral/purchasers/findActivityInfo',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分充值支付动态获取支付方式下拉列表
    listByType(data) {
      return axios({
        url: 'paymentPatternSetting/way/listByType',
        method: 'post',
        data: serverMoudle(data)
      })
    },

    /**
     * 积分兑换
     * @param data
     * @returns {*}
     */
  // 采购商信息查询
    scanPurchase(data) {
      return axios({
        url: 'integral/exchange/scanPurchase',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 兑换积分-查询可兑换积分列表
    integralEffectiveRecord(data) {
        return axios({
          url: 'integral/exchange/integralEffectiveRecord',
          method: 'post',
          data: serverMoudle(data)
        })
      },
    // 积分兑换-凭证
    integralExchangeCertificate(data) {
      return axios({
        url: 'integral/exchange/integralExchangeCertificate',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 采购商积分兑换
    purchaserExchange(data) {
      return axios({
        url: 'integral/exchange/purchaser/exchange',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 确认兑换
    completedExchange(data) {
      return axios({
        url: 'integral/exchange/completedExchange',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 取消兑换
    cancelExchange(data) {
      return axios({
        url: 'integral/exchange/cancelExchange',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 查询兑换记录状态
    queryExchangeStatus(data) {
      return axios({
        url: 'integral/exchange/queryExchangeStatus',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 兑换积分记录列表
    integralPurchaserExchangeList(data) {
      return axios({
        url: 'integral/exchange/integralPurchaserExchangeList',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 兑换积分记录列表--导出
    integralPurchaserExchangeExport(data) {
      return axios({
        url: 'integral/exchange/integralPurchaserExchangeExport',
        method: 'post',
        data: serverMoudle(data),
      })
    },
    // 积分货品分类列表
    categoryPage(data) {
      return axios({
        url: 'integral/goods/category/page',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分货品分类列表
    categoryList(data) {
      return axios({
        url: 'integral/goods/category/list',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 启用停用货品分类
    categoryEnable(data) {
      return axios({
        url: 'integral/goods/category/enable',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 添加积分货品分类
    categorySave(data) {
      return axios({
        url: 'integral/goods/category/save',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 修改积分货品分类
    categoryUpdate(data) {
      return axios({
        url: 'integral/goods/category/update',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 添加积分限制信息
    quotaAdd(data) {
      return axios({
        url: 'dnpIntegral/quota/add',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取积分限制明细
    quotaDetail(data) {
      return axios({
        url: 'dnpIntegral/quota/detail',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 更新积分限制信息
    quotaUpdate(data) {
      return axios({
        url: 'dnpIntegral/quota/update',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 更新积分配置启用状态
    updateState(data) {
      return axios({
        url: 'dnpIntegral/manage/updateState',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 添加积分配置信息
    manageAdd(data) {
      return axios({
        url: 'dnpIntegral/manage/add',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取积分配置信息明细
    manageDetail(data) {
      return axios({
        url: 'dnpIntegral/manage/detail',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 更新积分配置信息
    manageUpdate(data) {
      return axios({
        url: 'dnpIntegral/manage/update',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 获取车型列表 1停车费
    getFeeVehicleModelListByType() {
      return axios({
        url: 'market/parking/rule/feeVehicleModel/getFeeVehicleModelListByType/1',
        method: 'get'
      })
    },
  // 获取车型列表 2交易费
    getFeeVehicleModelListByType2() {
      return axios({
        url: 'market/parking/rule/feeVehicleModel/getFeeVehicleModelListByType/2',
        method: 'get'
      })
    },
    // 获取积分配置列表
    manageLits(data) {
      return axios({
        url: 'dnpIntegral/manage/list',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 查询车辆进场信息及积分
    integralRecordPrint(data) {
      return axios({
        url: 'integralRecordPrint',
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 查询车辆进场信息及积分
    findUserWaitIntegral(scanCode) {
      return axios({
        url: `integral/distribute/findUserWaitIntegral?scanCode=${scanCode}`,
        method: 'get',
      })
    },
    // 积分用户管理-查询 用户 积分当日派发次数和最近派发时间
    findIntegralUserDayCount(uid) {
      return axios({
        url: `integral/user/findIntegralUserDayCount?uid=${uid}`,
        method: 'get',
      })
    },
    // 积分用户管理-查询 车次 积分当日派发次数和最近派发时间
    findIntegralCarDayCount(trainNum) {
      return axios({
        url: `integral/user/findIntegralCarDayCount?trainNum=${trainNum}`,
        method: 'get',
      })
    },
    // 查询并注册采购商信息
    queryAndRegister(data) {
      return axios({
        url: "purchasers/queryAndRegister",
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 计算用户积分可获得的积分
    calcDistributeIntegral(data) {
      return axios({
        url: "integral/distribute/calcDistributeIntegral",
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 按用户类型,获取积分配置列表
    manageListByType(userType) {
      return axios({
        url: `dnpIntegral/manage/listByType?userType=${userType}`,
        method: 'get',
      })
    },
    // 积分派发车牌号查询车辆进场信息
    findInCarRecordByCarNumber(data) {
      return axios({
        url: `integral/distribute/findInCarRecordByCarNumber?carNumber=${data.carNumber}&inParkSerial=${data.inParkSerial}`,
        method: 'get',
      })
    },
    // 扫码查询用户已派发积分数据
    findUserCompletedIntegral(scanCode) {
      return axios({
        url: `integral/distribute/findUserCompletedIntegral?scanCode=${scanCode}`,
        method: 'get',
      })
    },
    // 查询用户登记积分数据
    findUserWaitIntegralCurrency(data) {
      return axios({
        url: `integral/distribute/findUserWaitIntegralCurrency`,
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分核验派发
    integralCheckDistribute(data) {
      return axios({
        url: "integral/distribute/integralCheckDistribute",
        method: 'post',
        data: serverMoudle(data)
      })
    },
    // 积分兑换数据统计
    exchangeIntegralStatistics(data) {
      return axios({
        url: "integral/count/exchangeIntegralStatistics",
        method: 'post',
        data: serverMoudle(data)
      })
    },

  };
