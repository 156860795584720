// mac 会导致自动返回乱码，如下，暂时还没解决
// var a = 'attachment;filename=åºåè´¹ç¨20210702042556.xlsx'
// window 会这样返回
// var b = `filename=%E6%B0%B4%E7%94%B5%E6%A8%A1%E6%9D%BF20210702035356.xlsx`
// 使用 decodeURIComponent
// 目前对window可以解析成功：filename=水电模板20210702035356.xlsx

export const excelDownload = (res, fileName) => {
  console.log('🚀 ~ excelDownload = res', res)
  let file = fileName
  if (file === undefined) {
    const attachment = res.headers['content-disposition']
     file = attachment.split('=')[1]
    file = decodeURIComponent(file)
  }


  const data = res.data

  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  })

  const objectUrl = URL.createObjectURL(blob)
  const elink = document.createElement('a')

  elink.download = file // 下载文件名称,

  elink.style.display = 'none'
  elink.href = objectUrl

  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
