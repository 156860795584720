export default [
  {
    path: 'queryrecords',
    name: 'queryrecords',
    component: () => import('./views/index'),
    meta: {
      title: '查询记录'
    }
  }
]
