var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newContract" },
    [
      _c(
        "el-card",
        [
          _c("div", [_vm._v("合同详情")]),
          _c("div", { staticClass: "newContract-Information" }, [
            _c("span", [
              _vm._v(
                "基本信息 合同编号:" +
                  _vm._s(_vm.contractTempleteNum) +
                  " (发起人:" +
                  _vm._s(_vm.updateByName) +
                  "/发起时间:" +
                  _vm._s(_vm.createTime) +
                  "16:24 :03)"
              ),
            ]),
            _c("span", { staticClass: "staus" }, [
              _vm._v(_vm._s(_vm.status(_vm.statusr))),
            ]),
          ]),
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合同名称 :" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "审批人", disabled: true },
                    model: {
                      value: _vm.formInline.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "name", $$v)
                      },
                      expression: "formInline.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同模板 :" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.formInline.contractTempleteId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "contractTempleteId", $$v)
                        },
                        expression: "formInline.contractTempleteId",
                      },
                    },
                    _vm._l(_vm.feeConfigs, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模板属性 :" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "模板属性", disabled: true },
                      model: {
                        value: _vm.formInline.attribute,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "attribute", $$v)
                        },
                        expression: "formInline.attribute",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "电子合同", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "线下合同", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同年限 :" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "审批人", disabled: true },
                    model: {
                      value: _vm.formInline.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "period", $$v)
                      },
                      expression: "formInline.period",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开始时间 :" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "审批人", disabled: true },
                    model: {
                      value: _vm.formInline.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "startTime", $$v)
                      },
                      expression: "formInline.startTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "结束时间 :" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "审批人", disabled: true },
                    model: {
                      value: _vm.formInline.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "endTime", $$v)
                      },
                      expression: "formInline.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签订时间 :" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "审批人", disabled: true },
                    model: {
                      value: _vm.formInline.signTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "signTime", $$v)
                      },
                      expression: "formInline.signTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "newContract-Information" }, [
              _c(
                "span",
                [
                  _vm._v("甲方信息 是否是市场方: "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: true },
                      on: { change: _vm.valueSelect },
                      model: {
                        value: _vm.valueA,
                        callback: function ($$v) {
                          _vm.valueA = $$v
                        },
                        expression: "valueA",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "Y" } }),
                      _c("el-option", { attrs: { label: "否", value: "N" } }),
                    ],
                    1
                  ),
                  _vm.valueA !== "Y"
                    ? _c(
                        "span",
                        { staticClass: "Identity" },
                        [
                          _vm._v("身份性质: "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", disabled: true },
                              model: {
                                value: _vm.natureA,
                                callback: function ($$v) {
                                  _vm.natureA = $$v
                                },
                                expression: "natureA",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "个人", value: "N" },
                              }),
                              _c("el-option", {
                                attrs: { label: "企业", value: "Y" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "newContract-form" },
              [
                _c("PatyAform", {
                  attrs: {
                    init: _vm.valueA !== "N" || _vm.natureA !== "N",
                    firstContractUser: _vm.firstContractUser,
                    auditDetails: _vm.audit,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "newContract-Information" }, [
              _c(
                "span",
                [
                  _vm._v("乙方信息 是否是市场方: "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: true },
                      on: { change: _vm.valueSelectB },
                      model: {
                        value: _vm.valueB,
                        callback: function ($$v) {
                          _vm.valueB = $$v
                        },
                        expression: "valueB",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "Y" } }),
                      _c("el-option", { attrs: { label: "否", value: "N" } }),
                    ],
                    1
                  ),
                  _vm.valueB !== "Y"
                    ? _c(
                        "span",
                        { staticClass: "Identity" },
                        [
                          _vm._v("身份性质: "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", disabled: true },
                              model: {
                                value: _vm.natureB,
                                callback: function ($$v) {
                                  _vm.natureB = $$v
                                },
                                expression: "natureB",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "个人", value: "N" },
                              }),
                              _c("el-option", {
                                attrs: { label: "企业", value: "Y" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c("PatyBform", {
                  attrs: {
                    init: _vm.natureB === "Y",
                    modify: this.$route.query.contractId,
                    auditDetails: _vm.audit,
                    secondContractUser: _vm.secondContractUser,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "stall" }, [
            _c("div", { staticClass: "newContract-Information" }, [
              _c("span", [_vm._v("档口信息")]),
            ]),
            _c(
              "div",
              { staticClass: "stall-Information" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: { inline: true, model: _vm.stallfFrom },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "档口号 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.boothNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "boothNum", $$v)
                            },
                            expression: "stallfFrom.boothNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "楼栋编号 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.floorNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "floorNum", $$v)
                            },
                            expression: "stallfFrom.floorNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "楼层 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.floorLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "floorLevel", $$v)
                            },
                            expression: "stallfFrom.floorLevel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "区域名称 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.regionName,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "regionName", $$v)
                            },
                            expression: "stallfFrom.regionName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "建筑面积 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.constructionArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "constructionArea", $$v)
                            },
                            expression: "stallfFrom.constructionArea",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: { inline: true, model: _vm.stallfFrom },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "使用性质 :" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", disabled: true },
                          model: {
                            value: _vm.stallfFrom.useNature,
                            callback: function ($$v) {
                              _vm.$set(_vm.stallfFrom, "useNature", $$v)
                            },
                            expression: "stallfFrom.useNature",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "cost" }, [
            _c("div", { staticClass: "newContract-Information" }, [
              _c("span", [_vm._v("费用信息")]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.listAllData, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "feeTypeCd", label: "费用类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getTypefee(scope.row.feeTypeCd)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "feeName", label: "费用项目" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cycle", label: "费用标识" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              parseInt(scope.row.cycle) === 1
                                ? _c("div", [_vm._v("周期性费用")])
                                : _c("div", [_vm._v("一次性费用")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "startTime", label: "计费开始时间" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "endTime", label: "费用结束时间" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "unitPrice", label: "单价" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "additionalFee", label: "(附加/固定)费" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "increaseTime", label: "递增开始时间" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "increaseCycle", label: "递增周期" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "increaseRate", label: "递增率" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCostList(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看费用清单")]
                              ),
                              _c(
                                "div",
                                { staticClass: "eldialog" },
                                [
                                  _c("el-dialog", {
                                    attrs: {
                                      visible: _vm.costListdialog,
                                      title: "费用清单明细",
                                    },
                                    on: {
                                      "update:visible": function ($event) {
                                        _vm.costListdialog = $event
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c("span", [
                                                  _vm._v("费用项 :"),
                                                ]),
                                                _c("span", [
                                                  _vm._v("应收总额:"),
                                                ]),
                                              ]),
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    data: _vm.tableData,
                                                    border: "",
                                                  },
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      type: "index",
                                                      label: "序号",
                                                      width: "180",
                                                    },
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "contractFeeCycle",
                                                      label: "周期号",
                                                      width: "180",
                                                    },
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "contractFeeMonthAmount",
                                                      label: "金额(每月)",
                                                    },
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "contractFeeStartTime",
                                                      label: "计费开始时间",
                                                    },
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "contractFeeEndTime",
                                                      label: "计费结束时间",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "other" }, [
            _c("div", { staticClass: "newContract-Information" }, [
              _c("span", [_vm._v("其他")]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-form",
                  { attrs: { model: _vm.extension, inline: true } },
                  _vm._l(_vm.extension, function (item, index) {
                    return _c(
                      "el-form-item",
                      { key: index, attrs: { label: item.specName } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "annex" }, [
            _c("div", { staticClass: "newContract-Information" }, [
              _c("span", [_vm._v("附件信息")]),
            ]),
            _c(
              "div",
              [
                _c("el-upload", {
                  staticClass: "upload-demo",
                  attrs: {
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    multiple: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                  },
                }),
              ],
              1
            ),
          ]),
          this.$route.query.status === "2"
            ? _c("div", { staticClass: "application" }, [
                _c("div", { staticClass: "newContract-Information" }, [
                  _c("span", [_vm._v("申请结果")]),
                ]),
                _c("div", { staticClass: "audit" }, [
                  _c(
                    "div",
                    [
                      _vm._v(" 申请结果 : "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1", disabled: true },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("通过")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2", disabled: true },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v("不通过")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "audit-text" }, [
                    _vm._v(" 审核意见 : "),
                    _c(
                      "div",
                      { staticClass: "textarea" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "最多是200个字",
                            disabled: true,
                          },
                          model: {
                            value: _vm.textarea,
                            callback: function ($$v) {
                              _vm.textarea = $$v
                            },
                            expression: "textarea",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  this.$route.query.status === "6"
                    ? _c("div", { staticClass: "audit-text" }, [
                        _vm._v(" 撤销原因 : "),
                        _c(
                          "div",
                          { staticClass: "textarea" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                placeholder: "最多是200个字",
                                disabled: true,
                              },
                              model: {
                                value: _vm.termination,
                                callback: function ($$v) {
                                  _vm.termination = $$v
                                },
                                expression: "termination",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "operationKey" },
            [
              _c(
                "el-button",
                {
                  staticClass: "left",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }