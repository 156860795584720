const maxlength = 50;
const maxTextare = 420;
const maxNumber = 12;
const versionNumber = (rule, value, callback) => {
  if (value != '') {
    var myreg = /^\d[\d\.]+\d$/;
    if (!myreg.test(value)) {
      callback(new Error('请输入正确版本名称、版本号，例如：0.0或者0.0.0'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

const applink = (rule, value, callback) => {
  if (value != '') {
    var myreg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
    if (!myreg.test(value)) {
      callback(new Error(' 请输入有效的链接！'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};


export default {
  maxlength,
  maxTextare,
  maxNumber,
  versionNumber,
  applink
}
