import axios from '@/services'
import {serverMoudle} from '@/util/fetch.js'

export const staff = {
  //支付通道下拉列表
  getchannelSelectList(data) {
    return axios({
      url: '/financial/paymentChannel/channel/selectList',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //支付通道下拉列表
  findSysDictData(data) {
    return axios({
      url: '/sys/dict/findSysDictData',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!添加员工
  ADD_DNPUSER(data) {
    return axios({
      url: 'CHENGFENG_ADD_DNPUSER',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!编辑员工
  EDIT_DNPUSER(data) {
    return axios({
      url: 'CHENGFENG_EDIT_DNPUSER',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取所有可授权的区域列表
  findAllRegionTypes(data) {
    return axios({
      url: '/sys/dict/findAllRegionTypes',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!删除员工
  DEL_DNPUSER(data) {
    return axios({
      url: 'CHENGFENG_DEL_DNPUSER',
      method: 'post',
      data: serverMoudle(data)
    })
  },
//!    获取员工列表
  GET_DNPUSERLIST(data) {
    return axios({
      url: 'CHENGFENG_GET_DNPUSERLIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },


  //!    获取功能列表
  GET_DNPFUNCTIONLIST(data) {
    return axios({
      url: 'CHENGFENG_GET_DNPFUNCTIONLIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!    获取角色权限
  GET_DNPROLE_PERMISSIONLIST(data) {
    return axios({
      url: 'CHENGFENG_GET_DNPROLE_PERMISSIONLIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!    获取员工角色权限
  GET_DNPUSER_PERMISSIONLIST(data) {
    return axios({
      url: 'CHENGFENG_GET_DNPUSER_PERMISSIONLIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!   绑定角色权限
  SAVE_DNPFUNCTION(data) {
    return axios({
      url: 'CHENGFENG_SAVE_DNPFUNCTION',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!    添加角色
  ADD_DNPROLE(data) {
    return axios({
      url: 'CHENGFENG_ADD_DNPROLE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!    编辑角色
  EDIT_DNPROLE(data) {
    return axios({
      url: 'CHENGFENG_EDIT_DNPROLE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //!    删除角色
  DEL_DNPROLE(data) {
    return axios({
      url: 'CHENGFENG_DEL_DNPROLE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //! 查询角色
  GET_DNPROLELIST(data) {
    return axios({
      url: 'CHENGFENG_GET_DNPROLELIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },


}
