export default [
  {
    path: 'purchasingInformation',
    name: 'purchasingInformation',
    component: () => import('./views/purchasingInformation'),
    meta: {
      title: "采购过磅"
    }
  },
  {
    path: 'InformationDetails',
    name: 'InformationDetails',
    component: () => import('./views/InformationDetails'),
  },
  {
    path: 'purchaseReport',
    name: 'purchaseReport',
    component: () => import('./views/purchaseReport'),
    meta: {
      title: "采购数据报表"
    }
  },

]
