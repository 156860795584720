export default [{
  path: 'market',
  name: 'market',
  component: () => import('./views/MarketAnalysis.vue'),
  redirect: '/market/situationAnalysis',
  children: [
    {
      path: 'situationAnalysis',
      name: 'situationAnalysis',
      component: () => import('./views/SituationAnalysis.vue'),
      meta: {
        title: '交易情况分析'
      }
    },
    {
      path: 'priceAnalysis',
      name: 'priceAnalysis',
      component: () => import('./views/PriceAnalysis.vue'),
      meta: {
        title: '市场价格分析'
      }
    },
    {
      path: 'goodsAnalysis',
      name: 'goodsAnalysis',
      component: () => import('./views/GoodsAnalysis.vue'),
      meta: {
        title: '货品来源分析'
      }
    },
    {
      path: 'businessAnalysis',
      name: 'businessAnalysis',
      component: () => import('./views/BusinessAnalysis.vue'),
      meta: {
        title: '档口经营分析'
      }
    },
    {
      path: 'riskAnalysis',
      name: 'riskAnalysis',
      component: () => import( './views/RiskAnalysis.vue'),
      meta: {
        title: '市场风险分析'
      }
    }
  ]
}]
