//
import marketsTab from './views/index';
import largeScreenDisplaySettings from './views/largeScreenDisplaySettings'
// 市场行情页面
import ontherMarkets from './views/ontherMarkets'
// 海南大数据价格上传
import uploadThePrice from './views/uploadThePrice'
//关注货品设置页面
import productSettings from './views/productSettings'
//人工采集集数据页面
import manualDataCollection from './views/manualDataCollection'
//大屏展示排序页面
import largeScreenSort from './views/largeScreenSort'

export default [
  {
    path: 'marketsTab',
    name: 'marketsTab',
    component: marketsTab,
    redirect: '/marketsTab/ontherMarkets',
    children: [
      {
        path: 'ontherMarkets',
        name: 'ontherMarkets',
        components: {
          marketsTabView: ontherMarkets
        },
        meta: {
          title: "其他市场行情"
        }
      },
      {
        path: 'largeScreenDisplaySettings',
        name: 'largeScreenDisplaySettings',
        components: {
          marketsTabView: largeScreenDisplaySettings
        },
        meta: {
          title: "大屏展示设置"
        }
      },
    ]
  },
  {
    path: 'uploadThePrice',
    name: 'uploadThePrice',
    component: uploadThePrice,
    meta: {
      title: "海南大数据价格上传"
    }
  },
  {
    path: 'productSettings',
    name: 'productSettings',
    component: productSettings,
    meta: {
      title: "市场价格取值"
    }
  },
  {
    path: 'manualDataCollection',
    name: 'manualDataCollection',
    component: manualDataCollection,
    meta: {
      title: "人工采集数据"
    }
  },
  {
    path: 'largeScreenSort',
    name: 'largeScreenSort',
    component: largeScreenSort,
    meta: {
      title: "大屏展示排序"
    }
  },
]
