export default [{
    path: 'merchantManagement',
    name: 'merchantManagement',
    component: () => import('./views/merchantManagement'),
    meta: {
      title: "商户管理"
    }
  },
  {
    path: 'merchantManagement/settledMerchant/:miId&:merchantName',
    name: 'settledMerchant',
    component: () => import('./views/settledMerchant'),
    meta: {
      title: "变更入驻档口"
    }
  },
  {
    path: 'statisticsEcharts',
    name: 'statisticsEcharts',
    component: () => import('./views/statisticsEcharts'),
    meta: {
      title: "统计图表"
    }
  },
  {
    path: 'historySettled/:mrgId&:settleStatus&:number&:regionName',
    name: 'historySettled',
    component: () => import('./views/historySettled'),
    meta: {
      title: "历史变更"
    }
  },
  {
    path: 'batchImportMerchants',
    name: 'batchImportMerchants',
    component: () => import('./views/batchImportMerchants'),
    meta: {
      title: "批量导入商户"
    }
  },
  {
    path: 'merchantInfo',
    name: 'merchantInfo',
    component: () => import('./views/merchantInfo'),
    redirect: '/merchantInfo/basicInformation/:miId',
    children: [{
        path: 'basicInformation/:miId',
        name: 'basicInformation',
        component: () => import('./views/basicInformation'),
        meta: {
          title: "商户基础信息"
        }
      },
      {
        path: 'echarts/:miId',
        name: 'echarts',
        component: () => import('./views/echarts'),
        meta: {
          title: "统计报表"
        }
      },
      {
        path: 'argoRecord/:miId',
        name: 'argoRecord',
        component: () => import('./views/argoRecord'),
        meta: {
          title: "商户来货记录"
        }
      }, {
        path: 'creditsLog/:miId',
        name: 'creditsLog',
        component: () => import('./views/creditsLog'),
        meta: {
          title: "商户积分记录"
        }
      }
    ]
  }


]
