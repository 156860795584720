<template>
  <div class="modify">
    <el-card header="历史入驻">
      <div>
        <el-table :data="historySettleInfoList" border style="width: 100%">
          <el-table-column prop="merchantName" label="入驻时间"> </el-table-column>
          <el-table-column prop="retreatTime" label="退档时间"> </el-table-column>
          <el-table-column prop="merchantName" label="商户姓名"> </el-table-column>
          <el-table-column prop="phone" label="联系方式"> </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card header="档口信息">
      <div>
        <el-table :data="changeList" border style="width: 100%">
          <el-table-column prop="changeField" label="变更字段"> </el-table-column>
          <el-table-column prop="oldData" label="变更前"> </el-table-column>
          <el-table-column prop="newData" label="变更后"> </el-table-column>
          <el-table-column prop="createTime" label="修改时间"> </el-table-column>
          <el-table-column prop="userName" label="修改人"> </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
export default {
  props: {
    list: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      historySettleInfoList: [],
      changeList: []
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      } else {
        this.getModifyList()
      }
    },
    typeName(val) {
      if (val === 'modify') {
        this.getModifyList()
      }
    }
  },
  methods: {
    getModifyList() {
      let obj = {
        mrgId: this.$route.query.data.mrgId,
        marketId: this.$route.query.data.marketId
      }
      marketplan
        .getRegionNumberChangeInfoList({ ...obj })
        .then((result) => {
          this.historySettleInfoList = result.data.results.historySettleInfoList
          this.changeList = result.data.results.changeList
        })
        .catch((err) => {})
    }
  }
}
</script>
<style lang="scss">
.modify {
  .cell {
    text-align: center;
  }
}
</style>
