export const store = {
  // 状态
  state: {
    screenForm: {
      operator: "",
      operatorId: '',
      tpId: "",
      purchaseTypeId: "",
      userName: "",
      phone: "",
      trainNum: '',
      carNumber: '',
      startTime: "",
      endTime: "",
      goodsType: "",
      page_size: 10,
      current_page: 1,
      pageSize: 10,
      current: 1,
      status: "",
      bool: true,
      userType: '',
      distributeStatus: '',
      serialNumber:'',
      validStatus: ''
    },
    IntegralQuery: {
      userName: "",
      startTime: "",
      endTime: "",
      userPhone: '',
      trainNum: "",
      activeIndex: '1',
      current_page: 1,
      page_size: 10,
      bool: true
    },
    pointsExchangeRecord: {
      startTime: "",
      endTime: "",
      regionId: '',
      number: "",
      ownerName: '',
      status: '',
      phone: '',
      operatorId: '',
      current_page: 1,
      page_size: 10,
      timeIndex: 0,

      bool: true
    }
  },
  // 修改状态
  mutations: {
    SET_SCREEN_FORM: (state, data) => {
      state.screenForm = {...state.screenForm, ...data};
    },
    SET_INTEGRALQUERY: (state, data) => {

      state.IntegralQuery = {...state.IntegralQuery, ...data};
    },
    SET_pointsExchangeRecord: (state, data) => {

      state.pointsExchangeRecord = {...state.pointsExchangeRecord, ...data};
    },


  },
  // 异步修改状态
  actions: {},
  namespaced: true
};
