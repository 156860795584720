import Vue from 'vue'
import Cookies from 'js-cookie'
import Router from 'vue-router'
//市场分析路由
import Market from './module/market/router'
// 进场报备
import approachPrepare from './module/approachPrepare/router'
// 进场报备
import couponManagement from './module/couponManagement/router'
// 货品检验
import goodsInspection from './module/goodsInspection/router'
//货品调度
import goodsDispatch from './module/goodsDispatch/router'
// 其他市场行情页面路由
import ontherMarkets from './module/otherMarket/router'
// 员工管理
import staff from './module/staff/router'
//全屏数据
import fullScreenData from './module/fullScreenData/router'
// 市场大屏
import domesticMarket from './module/domesticMarket/router'
// 操作日志
import operationLog from './module/operationLog/router'
//  区域管理
import districtManagement from './module/districtManagement/router'
//区域管理删除页面
// 积分管理
import IntegralManagement from './module/IntegralManagement/router.js'
// 修改密码
import changePassword from './module/changePassword/router.js'
// 采购过磅
import purchasingInformation from './module/purchasingInformation/router.js'
// 进出场收费
import entranceAndExitCharges from './module/entranceAndExitCharges/router.js'
//市场平面图
import marketplan from './module/marketplane/router'
//采购商管理 purchaserManagement
import purchaserManagement from './module/purchaserManagement/router'
// 商户管理
import merchantManagement from './module/merchantManagement/router'
//溯源管理
import traceability from './module/traceability/router'
//检测报告列表
import  testReport from './module/testReport/router'
//
import vehicleManagement from './module/vehicleManagement/router'
// 协议管理
import agreementManagement from './module/agreementManagement/router'
// 查询记录
import queryrecords from './module/queryrecords/router'
//充值服务
import recharge from './module/recharge/router'
//财务管理
import financialManagement from './module/financialManagement/router'
//支付方式管理
import paymentPatternManage from './module/paymentPatternManage/router.js'
// 三轮车管理
import tricycleManagement from './module/tricycleManagement/router.js'
// 积木
import jmreportRouter from './module/jmreport/router.js'

/**
 * 打冷系列
 */
// 收费规则管理
import chargingRule from './module/coolingDown/chargingRule/router';
//打冷收费报表
import coldChargingReport from './module/coolingDown/coldChargingReport/router';
// 设备管理
import deviceManagement from './module/coolingDown/deviceManagement/router';
// 报表统计
import reportStatistics from './module/reportStatistics/router.js';
// 费用管理
import costManagement from './module/costManagement/router.js';
// 打冷订单管理
import coldOrderManagement from './module/coolingDown/coldOrderManagement/router';

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login'),
    },
    {
      path: '/',
      name: 'tab',
      component: () => import('@/views/tab'),
      redirect: '/login',
      children: [
        {
          name: 'blank',
          path: '/blank'
        },
        ...Market,
        ...staff,
        ...ontherMarkets,
        ...approachPrepare,
        ...couponManagement,
        ...operationLog,
        ...goodsInspection,
        ...goodsDispatch,
        ...districtManagement,
        ...IntegralManagement,
        ...changePassword,
        ...purchasingInformation,
        ...entranceAndExitCharges,
        ...purchaserManagement,
        ...merchantManagement,
        ...vehicleManagement,
        ...marketplan,
        ...agreementManagement,
        ...queryrecords,
        ...recharge,
        ...traceability,
        ...testReport,
        ...financialManagement,
        ...paymentPatternManage,
        ...tricycleManagement,
        ...jmreportRouter,
        ...chargingRule,
        ...coldChargingReport,
        ...deviceManagement,
        ...reportStatistics,
        ...costManagement,
        ...coldOrderManagement,
        {
          path: 'notFound',
          name: 'notFound',
          component: () => import('@/views/notFound'),
        }
      ],
      meta: {
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的,暂时设置为false，否则不能进入home
      }
    },
    ...fullScreenData,
    ...domesticMarket
  ]
})

router.beforeEach((to, from, next) => {
  let token = Cookies.get('managerToken')
  if (to.matched.some(record => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (token) {
      // 判断当前的token是否存在
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath } // 将跳转���路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})

export default router
