<template>
  <div>
    <div>
      <el-card>
        <el-tabs v-model="activeName">
          <el-tab-pane label="档口情况" name="first">
            <StallSituation />
          </el-tab-pane>
          <el-tab-pane label="入驻情况" name="second">
            <OccupationSituation :second="activeName" />
          </el-tab-pane>
          <el-tab-pane label="合同情况" name="contractStatus">
            <Contract :name="activeName" />
          </el-tab-pane>
          <el-tab-pane label="收费情况" name="charges">
            <Charges :charg="activeName" />
          </el-tab-pane>
          <el-tab-pane label="来货情况" name="situation">
            <Situation :situation="activeName" />
          </el-tab-pane>
          <el-tab-pane label="交易情况" name="trading">
            <Trading :situation="activeName" />
          </el-tab-pane>
          <!-- <el-tab-pane label="收费情况" name="charges">
            <Charges/>
          </el-tab-pane> -->
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
import OccupationSituation from '../components/occupationSituation.vue'
import StallSituation from '../components/stallSituation.vue'
import Charges from '../components/charges.vue'
import Contract from '../components/contract.vue'
import Situation from '../components/situation.vue'
import Trading from '../components/trading.vue'
export default {
  name: 'marketplan',
  components: {
    OccupationSituation,
    StallSituation,
    Charges,
    Contract,
    Situation,
    Trading
  },
  data() {
    return {
      activeName: 'first'
    }
  }
}
</script>
