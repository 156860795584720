export default [
  {
    path: 'chargeReportSummary',
    name: 'chargeReportSummary',
    component: () => import('./views/chargeReportSummary'),
    meta: {
      title: '收费报表汇总'
    }
  },
  {
    path: 'summaryOfPayment',
    name: 'summaryOfPayment',
    component: () => import('./views/summaryOfPayment'),
    meta: {
      title: '缴费清单汇总'
    }
  },
  {
    path: 'regionalRentalInfo',
    name: 'regionalRentalInfo',
    component: () => import('./views/regionalRentalInfo'),
    meta: {
      title: '区域出租信息汇总表'
    }
  },
  {
    path: 'miscellaneousCharges',
    name: 'miscellaneousCharges',
    component: () => import('./views/miscellaneousCharges'),
    meta: {
      title: '杂费管理'
    }
  },
  {
    path: 'tricycleAccountManagement',
    name: 'tricycleAccountManagement',
    component: () => import('./views/tricycleAccountManagement'),
    meta: {
      title: '三轮车账户管理'
    }
  },
]
