class Config {
  constructor() {
    this._config = {};
  }

  // 注册
  regist(type, value) {
    this._config[type] = value;
  }

  get(type) {
    return this._config[type]
  }
}


// 返回一个config单例
export default new Config();
