// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router.js'
import store from './store';
import scroll from 'vue-seamless-scroll';
import FileSaver from 'file-saver';
import XLSX from 'xlsx'
Vue.use(scroll, {componentName: 'scrollSeamless'});

// 将excel表格模板设置全局
Vue.prototype.$FileSaver = FileSaver;
Vue.prototype.$XLSX = XLSX;


// Vue.prototype.$math = math
//  初始化权限列表
import {init as promissionInit, getPermissionByRole} from './config/permission';

Vue.prototype.$promissionInit = promissionInit;
//  拿到权限判断值
Vue.prototype.$getPermissionByRole = getPermissionByRole;


let Base64 = require('js-base64').Base64;
import ElementUI from 'element-ui'; //ElementUI
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'

//组件
import TitleBar from '@/components/TitleBar';
//过滤器
import * as filters from '@/filters'; // 全局vue filter

//设置全局输入框限制长度
import global_ from './util/setInput';

Vue.prototype.GLOBAL = global_;//挂载到Vue实例上面

//引入公共样式 公共部门放入入口不要和其它组件耦合在一起
import '@/style/ui-box.css';
import '@/style/common.scss';
import '@/style/elementUI.scss';
import 'bootstrap/dist/css/bootstrap.css';

//注册ElementUI主键
Vue.use(ElementUI, { locale })

//注册标题主键
Vue.component('title-bar', TitleBar);

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

// 引入echarts
import echarts from 'echarts'

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});

