export default [
  {
    path: 'paymentPatternSetting',
    name: 'paymentPatternSetting',
    component: () => import('./views/paymentPatternSetting'),
    meta: {
      title: '支付方式设置'
    }
  },
]
