import axios from "@/services";
import {serverMoudle} from '@/util/fetch'

export const ontherMarkets = {
  //查询当前的库存取值类型
  getFindCate(data) {
    return axios({
      url: '/stockType/findCurrent',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  getFindCateGoryStockList(data) {
    return axios({
      url: '/stockType/findCateGoryStockList',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  //查询当前的库存取值类型
  getStockTypeList(data) {
    return axios({
      url: '/stockType/list',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  //保存市场库存取值选项
  getStockType(data) {
    return axios({
      url: '/stockType/save',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  //获取货品分类
  getGoodCategoryTree(data) {
    return axios({
      url: 'CHENGFENG_GET_GOODS_CATEGORY_TREE',
      method: 'post',
      data: serverMoudle(data)
    });
  },

  // 获取行情列表
  GET_IMPORT_PRICES_LIST(data) {
    return axios({
      url: 'DNP_GET_IMPORT_PRICES_LIST',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  getimportExcel(data) {
    return axios({
      url: '/stockType/importExcel',
      method: 'post',
      data: serverMoudle(data),
    })
  },

  //新增市场行情
  addMarketSale(data) {
    return axios({
      url: 'XDY_ADD_MARKET_GOODS_SALE',
      method: 'post',
      data: serverMoudle(data)
    });
  },

  // 获取市场列表
  getMarketList(data) {
    return axios({
      url: 'CHENGFENG_GET_SAAS_MARKET',
      method: 'post',
      data: serverMoudle(data)
    });
  },

  //  添加市场行情
  ADD_IMPORT_PRICES(data) {
    return axios({
      url: 'DNP_ADD_IMPORT_PRICES',
      method: 'post',
      data: serverMoudle(data)
    });
  },

// 获取关注市场
  GET_FOLLOW_MARKET(data) {
    return axios({
      url: 'DNP_GET_FOLLOW_MARKET',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  // 获取关注市场
  DEL_FOLLOW_MARKET(data) {
    return axios({
      url: 'DNP_DEL_FOLLOW_MARKET',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  // 获取关注市场
  ADD_FOLLOW_MARKET(data) {
    return axios({
      url: 'DNP_SAVE_FOLLOW_MARKET',
      method: 'post',
      data: serverMoudle(data)
    });
  },


  // 大屏展示系统来源
  GET_MARKET_DISPLAY_SETTING(data) {
    return axios({
      url: 'DNP_GET_MARKET_DISPLAY_SETTING',
      method: 'post',
      data: serverMoudle(data)
    });
  },

  // 获取展示货品
  SHOW_MARKET_CATEGORY(data) {
    return axios({
      url: 'DNP_GET_SHOW_MARKET_CATEGORY',
      method: 'post',
      data: serverMoudle(data)
    });
  },
//  设置大屏数据展示
  SET_MARKET_DISPLAY_SETTING(data) {
    return axios({
      url: 'DNP_SET_MARKET_DISPLAY_SETTING',
      method: 'post',
      data: serverMoudle(data)
    });
  },

// 添加展示货品
  ADD_SHOW_MARKET_CATEGORY(data) {
    return axios({
      url: 'DNP_ADD_SHOW_MARKET_CATEGORY',
      method: 'post',
      data: serverMoudle(data)
    });
  },

//    查询大屏预警
  GET_WARNING_SETTINGS(data) {
    return axios({
      url: 'DNP_GET_LARGESCREEN_WARNING_SETTINGS',
      method: 'post',
      data: serverMoudle(data)
    });
  },
  // 大屏预警设置 -添加和更新
  SET_WARNING_SETTINGS(data) {
    return axios({
      url: 'DNP_LARGESCREEN_WARNING_SETTINGS',
      method: 'post',
      data: serverMoudle(data)
    });
  },

  //  2.1.9
  //CHENGFENG_GET_GOODSOURCE_LIST获取国内产地列表
  CHENGFENG_GET_GOODSOURCE_LIST(data) {
    return axios({
      url: 'CHENGFENG_GET_GOODSOURCE_LIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
//市场排序
  DNP_SAVE_FOLLOW_MARKET_SEQUENCE(data) {
    return axios({
      url: 'DNP_SAVE_FOLLOW_MARKET_SEQUENCE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
//添加关注货品
  DNP_SAVE_DNPMARKET_PRICE_VALUE(data) {
    return axios({
      url: 'DNP_SAVE_DNPMARKET_PRICE_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //市场关注货品值
  DNP_FIND_DNPMARKET_PRICE_VALUE(data) {
    return axios({
      url: 'DNP_FIND_DNPMARKET_PRICE_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //删除关注货品值
  DNP_DEL_DNPMARKET_PRICE_VALUE(data) {
    return axios({
      url: 'DNP_DEL_DNPMARKET_PRICE_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取市场价格取值模式和所属的货品
  DNP_FIND_DNPMARKET_PRICE_VALUE_INFO(data) {
    return axios({
      url: 'DNP_FIND_DNPMARKET_PRICE_VALUE_INFO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取市场所有的档口
  DNP_FIND_MARKET_GEAR(data) {
    return axios({
      url: 'DNP_FIND_MARKET_GEAR',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //获取档口所有的货品
  DNP_FIND_MARKET_GEAR_GOODS(data) {
    return axios({
      url: 'DNP_FIND_MARKET_GEAR_GOODS',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //保存市场价格取值模式和所属的货品
  DNP_SAVE_DNPMARKET_PRICE_VALUE_INFO(data) {
    return axios({
      url: 'DNP_SAVE_DNPMARKET_PRICE_VALUE_INFO',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //删除平均价的关注值
  DNP_DELETE_DNPMARKET_PRICE_VALUE_GOODS(data) {
    return axios({
      url: 'DNP_DELETE_DNPMARKET_PRICE_VALUE_GOODS',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //校验选择的档口货品是否重复
  DNP_CHECK_DNPMARKET_PRICE_VALUE_GOODS(data) {
    return axios({
      url: 'DNP_CHECK_DNPMARKET_PRICE_VALUE_GOODS',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  //修改大屏货品的展示
  DNP_SET_SHOW_DNPMARKET_PRICE_VALUE(data) {
    return axios({
      url: 'DNP_SET_SHOW_DNPMARKET_PRICE_VALUE',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 其他市场行情今日实时
  DNP_GET_MARKET_GOODS_PRICE_LIST(data) {
    return axios({
      url: 'DNP_GET_MARKET_GOODS_PRICE_LIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 其他市场行情历史记录
  DNP_GET_MARKET_GOODS_HISTORY_PRICE_LIST(data) {
    return axios({
      url: 'DNP_GET_MARKET_GOODS_HISTORY_PRICE_LIST',
      method: 'post',
      data: serverMoudle(data)
    })
  },
  // 导入人工采集数据模板
  CHENGFENG_GET_IMPORT_TEMPLATE(data) {
    return axios({
      url: 'CHENGFENG_GET_IMPORT_TEMPLATE',
      method: 'post',
      data: serverMoudle(data)
    })
  },

//    价格数据上传
  uploadGoodsPriceReCord(data){
    return axios({
      url: 'upload/GoodsPriceReCord',
      method: 'post',
      data: serverMoudle(data)
    })
  },

  //    价格数据上传
  findGoodsPriceReCord(data){
    return axios({
      url: 'find/GoodsPriceReCordList',
      method: 'post',
      data: serverMoudle(data)
    })
  }


};
