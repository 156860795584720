import axios from '@/services'
import { serverMoudle } from '@/util/fetch.js'
export const marketplan = {
    getSettledInfoByRegionView(data) {
        return axios({
            url: '/marketPlan/getSettledInfoByRegionView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //档口视图
    getSettledInfoByGearNumberView(data) {
        return axios({
            url: '/marketPlan/getSettledInfoByGearNumberView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //  区域列表
    SELECT_MARK_REGION(data) {
        return axios({
            url: 'SELECT_MARK_REGION',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //收费情况
    getRegionFeeList(data) {
        return axios({
            url: '/foreign/fee/queryRegionFeeList',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    // 市场平面图 -区域视图模板
    getRegionViewTemplate(data) {
        return axios({
            url: '/marketPlan/getSettledInfoByRegionViewTemplate',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //市场平面图 -档口视图模板
    getSettledInfoByGearNumberViewTemplate(data) {
        return axios({
            url: '/marketPlan/getSettledInfoByGearNumberViewTemplate',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //市场平面图
    getboothPayfeeReportList(data) {
        return axios({
            url: '/foreign/fee/boothPayfeeReportList',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    // 合同模板
    getlistAll(data) {
        return axios({
            url: '/foreign/contract/templateAllList',
            method: 'post',
            data
        })
    },
    //市场平面图-合同情况-区域视图
    getContractByRegionView(data) {
        return axios({
            url: '/marketPlan/getContractByRegionView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //市场平面图-合同情况-档口视图
    getContractByGearView(data) {
        return axios({
            url: '/marketPlan/getContractByGearView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //基本信息
    DNP_GET_NEW_REGION_NUMBER_LIST(data) {
        return axios({
            url: 'DNP_GET_NEW_REGION_NUMBER_LIST',
            method: 'post',
            data: serverMoudle(data)
        })
    },

    //进出情况
    getGoodsWeightByRegionView(data) {
        return axios({
            url: '/marketPlan/getGoodsWeightByRegionView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    getGoodsWeightByGearView(data) {
        return axios({
            url: '/marketPlan/getGoodsWeightByGearView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //交易
    getTransactionByRegionView(data) {
        return axios({
            url: '/marketPlan/getTransactionByRegionView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    getTransactionByGearView(data) {
        return axios({
            url: '/marketPlan/getTransactionByGearView',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //入驻商户信息
    DNP_FIND_MERCHANT_INFO(data) {
        return axios({
            url: 'DNP_FIND_MERCHANT_INFO',
            method: 'post',
            data: serverMoudle(data)

        })
    },
    //
    getqueryContractPageData(data) {
        return axios({
            url: 'foreign/contract/queryContractPageData',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    getDetails(data) {
        return axios({
            url: `foreign/contract/detail/${data.id}`,
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //市场平面图详情-水电抄表(市场PC端)
    getAutoAndManualMeter(data) {
        return axios({
            url: '/foreign/meterWaterElectricDevice/autoAndManualMeter/list',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //市场平面图 来货详情
    getIncomingTransactionDetails(data) {
        return axios({
            url: '/marketPlan/getIncomingTransactionDetails',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //档口导出
    getexportSettledInfoByRegion(data) {
        return axios({
            url: '/marketPlan/exportSettledInfoByRegion',
            method: 'post',
            data: serverMoudle(data),
            responseType: 'blob'
        })
    },
    //收费导出
    queryRegionFeeExport(data) {
        return axios({
            url: '/foreign/fee/queryRegionFeeExport',
            method: 'post',
            data: serverMoudle(data),
            responseType: 'blob'
        })
    },
    //积分
    getIntegralDetails(data) {
        return axios({
            url: '/marketPlan/getIntegralDetails',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    FIND_SOURCE_TYPE_LIST(data) {
        return axios({
            url: 'DNP_FIND_SOURCE_TYPE_LIST',
            method: 'post',
            data: serverMoudle(data),
        })
    },
    //修改记录
    getRegionNumberChangeInfoList(data) {
        return axios({
            url: '/marketPlan/getRegionNumberChangeInfoList',
            method: 'post',
            data: serverMoudle(data),
        })
    },
    //来货导出
    exportGoodsWeightByRegion(data) {
        return axios({
            url: '/marketPlan/exportGoodsWeightByRegion',
            method: 'post',
            data: serverMoudle(data),
            responseType: 'blob'
        })
    },
    //合同详情导出
    exportContractInfoByRegion(data) {
        return axios({
            url: '/marketPlan/exportContractInfoByRegion',
            method: 'post',
            data: serverMoudle(data),
            responseType: 'blob'
        })
    },
    //交易导出
    exportSettledInfoByRegion(data) {
        return axios({
            url: '/marketPlan/exportSettledInfoByRegion',
            method: 'post',
            data: serverMoudle(data),
            responseType: 'blob'
        })
    },
    //排序档口列表
    getRegionNumberPremiseIndexList(data) {
        return axios({
            url: '/marketPlan/getRegionNumberPremiseIndexList',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //排序
    getinstallRegionNumberPremiseIndex(data) {
        return axios({
            url: '/marketPlan/installRegionNumberPremiseIndex',
            method: 'post',
            data: serverMoudle(data)
        })
    },
    //物业详情
    getboothFeeForeignList(data) {
        return axios({
            url: '/foreign/fee/boothFeeForeignList',
            method: 'post',
            data: serverMoudle(data)
        })
    },
}
