<template>
  <div class="productSettings">
    <!--标题-->
    <div class="flex-back m-l-10 m-b-10 p-t-10">
      <el-page-header @back="goBack"/>
    </div>
    <div class="headTiter b-c-f p-t-20 p-l-20 p-b-20">
      <label>
        市场价格取值设置-{{ market.marketName }}
      </label>
    </div>
    <div class="table-productSettings content">
      <div class="redio-box m-l-20 m-t-10 m-r-20">
          <span>
            <span class="p-r-10">排序</span>
            <el-radio @change="search" v-model="params.type" label="goodsName">按名称</el-radio>
            <el-radio @change="search" v-model="params.type" label="goodsType">按货品类型</el-radio>
          </span>

        <span class="m-r-10">
            <el-button v-if="$getPermissionByRole('116')" @click="dialogFormVisible=true"
                       type="primary">添加关注货品</el-button>
          </span>
      </div>
      <div class="">
        <el-table
          stripe
          :data="tableData"
          header-row-class-name="table-header"
          v-loading="loading"
          class="table-data-list"
          v-if="$getPermissionByRole('35')"
        >
          <!-- 货品名称 -->
          <el-table-column prop="goodsName" label="货品名称">
            <template slot-scope="scope">
              <span v-if="!scope.row.bool">{{ scope.row.goodsName }}</span>
              <el-input
                v-else
                v-model.trim="scope.row.goodsName"
                placeholder="请输入货品名称"
                style="width: 200px"
              ></el-input>
            </template>
          </el-table-column>
          <!-- 所属大类 -->
          <el-table-column prop="marketName" label="所属大类">
            <template slot-scope="scope">
              <span v-if="!scope.row.bool">{{ scope.row.goodsType }}</span>
              <el-select v-else style="width: 300px" @change="sel(scope.row)" v-model="scope.row.goodsType"
                         placeholder="请选择所属大类">
                <el-option
                  label="水果"
                  value="水果"
                ></el-option>

                <el-option
                  label="蔬菜"
                  value="蔬菜"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 所属类别 -->
          <el-table-column class-name="" prop="categoryName" label="所属类别">
            <template slot-scope="scope">
              <span v-if="!scope.row.bool">{{ scope.row.categoryName }}</span>
              <el-input
                v-else
                v-model="scope.row.categoryName"
                placeholder="请选择"
                @focus="showTreeGoodType('search',scope.$index)"
                clearable
                style="width: auto"
              ></el-input>
              <el-dialog
                :visible.sync="scope.row.editShow"
                class="tree_type"
                v-if="scope.row.editShow"
                title="货品分类"
                :modal="false"
              >
                <el-input
                  v-model="inputText"
                  placeholder="请选择"
                  clearable
                  ref="inp"
                  style="width: auto"
                ></el-input>
                <el-tree
                  :data="treeData"
                  node-key="categoryId"
                  :props="defaultProps"
                  :highlight-current="true"
                  :filter-node-method="filterNode"
                  default-expand-all
                  show-checkbox
                  check-strictly

                  :check-on-click-node="true"
                  @check-change="handleClick"
                  ref="treeParams"
                ></el-tree>
              </el-dialog>
            </template>
          </el-table-column>
          <!-- 规格 -->
          <el-table-column prop="categoryName" label="规格">
            <template slot-scope="scope">
              <span v-if="!scope.row.bool">{{ scope.row.specifications }}</span>
              <el-input
                v-else
                v-model.trim="scope.row.specifications"
                placeholder="请输入货品规格"
                style="width: 200px"
              ></el-input>
            </template>
          </el-table-column>
          <!-- 产地 -->
          <el-table-column prop="categoryName" label="产地">
            <template slot-scope="scope">
              <span v-if="!scope.row.bool">{{ scope.row.sourceName }}</span>
              <el-cascader v-model="scope.row.cascade" :show-all-levels='bool' :props="props" separator="" filterable
                           v-else @change="getCheckedNodesTow(scope.row)" :options="options" clearable></el-cascader>
            </template>
          </el-table-column>
          <!--价格取值方式-->
          <el-table-column prop="valueType" label="价格取值方式">
            <template slot-scope="scope">
              <span :style="{color:statusColor(scope.row)}">{{ scope.row.valueType | value }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="400">
            <template slot-scope="scope">
              <el-button @click="del(scope.row)" class="btn">删除</el-button>
              <el-button @click="setPrice(scope.row)" class="btn">价格设置</el-button>
              <el-button v-if="!scope.row.bool" @click="edit(scope.row)" class="btn">编辑</el-button>
              <el-button v-else @click="save(scope.row)" class="btn" style="color: #05AE09">保存</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="total"
          :page-size="params.page_size"
          :current-page="params.current_page"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加关注货品 -->
    <el-dialog
      title="添加关注货品"
      :visible.sync="dialogFormVisible"
      width="50vw"
      :before-close="dialogBeforeClose"
      @close="defaultData(form)"
      v-if="dialogFormVisible"
    >
      <div>
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-row>
            <el-col :span="12">
              <div class="grid-content">
                <el-form-item label="货品名称" prop="mid">
                  <el-input
                    v-model.trim="form.goodsName"
                    placeholder="请输入货品名称"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="grid-content">
                <el-form-item label="所属大类" prop="importTime">
                  <el-select style="width: 300px" @change="selectGoodsTypId" v-model="form.goodsTypId"
                             placeholder="请选择所属大类">
                    <el-option
                      label="水果"
                      value="308"
                    ></el-option>

                    <el-option
                      label="蔬菜"
                      value="1"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="grid-content tree_goods_type content">
                <el-form-item label="所属货品类别" prop="categoryId" class="goosType">
                  <el-input
                    v-model.trim="form.categoryName"
                    :maxlength="GLOBAL.maxlength"
                    placeholder="请选择所属货品类别"
                    style="width: 300px;"
                    @focus="showTreeGoodType('set')"
                  ></el-input>
                  <el-dialog
                    class="tree_type"
                    :visible.sync="TreeGoodType"
                    v-if="TreeGoodType"
                    title="货品分类"
                    :modal="false"
                  >
                    <el-input
                      v-model="inputText1"
                      placeholder="请选择"
                      clearable
                      ref="inp1"
                      style="width: auto;margin-left: 10px"
                    ></el-input>
                    <el-tree
                      :data="treeData"
                      node-key="categoryId"
                      :props="defaultProps"
                      :highlight-current="true"
                      :filter-node-method="filterNode"
                      default-expand-all
                      show-checkbox
                      check-strictly
                      @check-change="handleFormClick"
                      ref="treeForm"
                    ></el-tree>
                  </el-dialog>
                </el-form-item>
              </div>
            </el-col>


            <el-col :span="12">
              <div class="grid-content">
                <el-form-item label="货品规格" prop="priceGrowth">
                  <el-input
                    v-model.trim="form.specifications"
                    placeholder="请输入货品规格"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="grid-content">
                <el-form-item label="产地" prop="salesNum">
                  <el-cascader separator="" :props="props" filterable style="width: 300px" @change="getCheckedNodes"
                               :options="options" clearable></el-cascader>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="addMarketQuotations('add')"
          size="small"
          :loading="buttonLoading"
        >确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 价格设置 -->
    <el-dialog
      title="价格设置"
      :visible.sync="focusMarketFormVisible"
      :before-close="dialogBeforeClose"
      @close="defaultData(form)"
      width="60vw"
      v-if="focusMarketFormVisible"
      class=" content"
    >
      <div class="m-b-10" style="display: flex;justify-content: space-between;font-size: 18px;font-weight: 600">
        <span>{{ goodsName }}</span>
        <span>市场：{{ market.marketName }}</span>
      </div>
      <div class="m-b-10">
        <p class="m-b-10">价格取值方式</p>
        <div class="redio-box m-l-20 m-t-10 m-r-20">
          <span>
            <el-radio v-model="valueType" label="system">系统数据(全部)</el-radio>
            <el-radio v-model="valueType" label="manual">人工采集</el-radio>
            <el-radio v-model="valueType" label="gear">系统数据(部分档口)</el-radio>
          </span>
          <span v-if="valueType=='gear'" class="m-r-10">
            <el-button @click="addPriceData()" type="primary">新增</el-button>
          </span>
        </div>
      </div>
      <div v-if="valueType=='gear'" style="height: 50vh;overflow: auto">
        <el-table
          stripe
          :data="priceTableData"
          header-row-class-name="table-header"
          v-loading="loading"
          class="table-data-list"
        >

          <!-- 档口名称 -->
          <el-table-column prop="gearName" label="档口名称">
            <template slot-scope="scope">
              <el-select filterable @change="changeGear(scope.row)" v-model="scope.row.gearName" placeholder="请选择档口名称">
                <el-option
                  v-for="item in gearList"
                  :key="item.gid"
                  :label="item.gearName"
                  :value="item.gid"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 货品名称 -->
          <el-table-column prop="goodsName" label="货品名称">
            <template slot-scope="scope">
              <el-select filterable @change="changeGoods(scope.row)" v-model="scope.row.goodsName"
                         placeholder="请输入货品名称">
                <el-option
                  v-for="item in goodsTree"
                  :key="item.goodId"
                  :label="item.goodName"
                  :value="item.goodId"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 所属类别 -->
          <el-table-column class-name="" prop="categoryName" label="所属类别">
            <template slot-scope="scope">

              <el-input
                v-model="scope.row.categoryName"
                placeholder="请选择"
                @focus="showTreeGoodType('searchPrice',scope.$index)"
                disabled
                clearable
              ></el-input>
              <el-dialog
                :visible.sync="scope.row.editBool"
                class="tree_type"
                v-if="scope.row.editBool"
                title="货品分类"
                :modal="false"
              >
                <el-input
                  v-model="inputText2"
                  placeholder="请选择"
                  clearable
                  ref="inp2"
                  style="width: auto"
                ></el-input>
                <el-tree
                  :data="treeData"
                  node-key="categoryId"
                  :props="defaultProps"
                  :highlight-current="true"
                  :filter-node-method="filterNode"
                  default-expand-all
                  show-checkbox
                  check-strictly
                  :check-on-click-node="true"
                  @check-change="handlePriceClick"
                  ref="treePriceParams"
                ></el-tree>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="priceDel(scope.$index,scope.row)" class="btn">删除</el-button>
              <el-button @click="priceEmpty(scope.row)" class="btn">清空</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPrice" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="savePrice"
          size="small"
          :loading="buttonLoading"
        >保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ontherMarkets} from "../api";
import "@/style/ontherMarkets/ontherMarkets.scss";
import {integralManagement} from "../../IntegralManagement/api";

export default {
  name: "productSettings",
  data() {
    return {
      a: ['1', '35'],
      bool: true,
      props: {
        value: 'id',
        label: 'text',
      },

      buttonLoading: false,
      dialogFormVisible: false,
      focusMarketFormVisible: false,
      market: {},
      goodsName: '',
      radio: '1',
      valueType: ' system ',
      params: {
        type: 'goodsName',
        page_size: 10,
        current_page: 1
      },
      tableData: [],
      priceTableData: [],//价格设置数据
      total: 0,
      loading: false,
      form: {
        goodsName: '',
        goodsTypId: '',
        goodsType: '',
        categoryId: '',
        categoryName: '',
        specifications: '',
        sourceId: '',
        sourceName: '',
        type: '',
      },
      TreeGoodType: false,
      showTreeType: false,
      showPriceTreeType: false,
      activeindex: '',//点击列表获取的当前列的下标
      defaultProps: {
        children: "childrens",
        label: "categoryName"
      },
      // 货品品类
      treeData: [
        {
          categoryId: 0,
          categoryName: "商品分类",
          disabled: true,
          childrens: []
        }
      ],
      inputText: '',//类型筛选值
      inputText1: '',
      inputText2: '',
      CategoryTree: {},
      options: [{
        value: 'zhinan',
        label: '指南',
        children: [{
          value: 'shejiyuanze',
          label: '设计原则',
        }, {
          value: 'daohang',
          label: '导航',
        }]
      }, {
        value: 'zujian',
        label: '组件',
        children: [{
          value: 'basic',
          label: 'Basic',
        }, {
          value: 'form',
          label: 'Form',
        }, {
          value: 'data',
          label: 'Data',
        }, {
          value: 'notice',
          label: 'Notice',
        }, {
          value: 'navigation',
          label: 'Navigation',
        }, {
          value: 'others',
          label: 'Others',
        }]
      }, {
        value: 'ziyuan',
        label: '资源',
        children: [{
          value: 'axure',
          label: 'Axure Components'
        }, {
          value: 'sketch',
          label: 'Sketch Templates'
        }, {
          value: 'jiaohu',
          label: '组件交互文档'
        }]
      }],
      gearList: [],//市场档口
      goodsTree: [],//档口货品
      selectPriceList: {},//选中的货品
    }
  },
  filters: {
    value(val) {
      if (val == 'system') {
        return '系统数据'
      }
      ;
      if (val == 'manual') {
        return '人工采集'
      }
      ;
      if (val == 'gear') {
        return '档口数据'
      }
      ;
    }
  },
  watch: {
    inputText(val) {
      this.$refs.treeParams.filter(val);
      this.$refs.treeForm.filter(val);
      this.$refs.treePriceParams.filter(val);
    },
    inputText1(val) {
      this.$refs.treeForm.filter(val);
    },
    inputText2(val) {
      this.$refs.treePriceParams.filter(val);
    },

    '$route.params': function(){
      this.init()
    }

  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      this.market = JSON.parse(this.$route.params.market);

      this.getList();
      this.getGoodType();
      this.goodSourceList();//产地
      this.getGearList();
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({
        name: "ontherMarkets",
        params: {bool: this.bool},
      });
    },
    //获取市场所有档口
    getGearList() {

      ontherMarkets
        .DNP_FIND_MARKET_GEAR({mid: this.market.mid})
        .then((res) => {

          this.gearList = res.data.results;
          // this.$message({
          //   type: "success",
          //   message: "撤销成功!",
          // });
          // this.getList();
        })
        .catch((err) => {
        });
    },
    //改变档口获取档口的货品
    changeGear(val) {

      this.gearList.forEach(item => {
        if (item.gid == val.gearName) {
          val.gid = item.gid;
          val.gearName = item.gearName;
          val.goodsName = '';
          val.goodsNum = '';
          val.categoryName = '';
          val.categoryId = '';
        }
      })
      // return ;
      ontherMarkets
        .DNP_FIND_MARKET_GEAR_GOODS({mid: this.market.mid, gid: val.gid})
        .then((res) => {

          this.goodsTree = res.data.results;
          // this.$message({
          //   type: "success",
          //   message: "撤销成功!",
          // });
          // this.getList();
        })
        .catch((err) => {
        });
    },
    //改变货品时校验，原数据里面是否有相同的货品
    changeGoods(val, e) {

      this.goodsTree.forEach(item => {
        if (item.goodId == val.goodsName) {
          val.goodId = item.goodId;
          val.goodsName = item.goodName;
          val.categoryId = item.categoryId;
          val.categoryName = item.categoryName;
        }
      })
      ontherMarkets
        .DNP_CHECK_DNPMARKET_PRICE_VALUE_GOODS({mid: this.market.mid, goodId: val.goodId})
        .then((res) => {

          if (res.data.results) {
            this.$message({
              type: "success",
              message: "货品相同!",
            });
          }

        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "数据请求失败",
          });
        });
    },
    sel(e) {

      if (e.goodsType == '水果') {
        e.goodsTypId = 308;
      }
      if (e.goodsType == '蔬菜') {
        e.goodsTypId = 1;
      }

    },
    getCheckedNodes(e) {

      let strA = '';
      let strB = '';
      this.options.forEach(item => {
        if (item.id == e[0]) {
          strA = item.text;
          item.children.forEach(i => {
            if (i.id == e[1]) {
              strB = i.text
            }
          })
        }
      })
      this.form.sourceId = e[1];
      this.form.sourceName = strA + strB

    },
    getCheckedNodesTow(e, index) {

      let strA = '';
      let strB = '';
      this.options.forEach(item => {
        if (item.id == e.cascade[0]) {
          strA = item.text;
          item.children.forEach(i => {
            if (i.id == e.cascade[1]) {
              strB = i.text
            }
          })
        }
      })
      e.sourceId = e.cascade[1];
      e.sourceName = strA + strB

    },
    statusColor(val) {
      if (val.valueType === 'gear') {
        return '#ff9900'
      }
      return val.valueType === "system" ? "#3399ff" : "#00cc66";
    },
    //新增价格设置数据
    addPriceData() {
      this.priceTableData.push({
        gearName: '',
        gid: '',
        categoryName: '',
        categoryId: '',
        goodsName: '',
        goodId: '',
        editBool: false,
        add: 'add',
        marketId: this.market.mid
      })

    },
    //价格设置删除
    priceDel(index, data) {
      if (data.add) {
        this.$confirm("该条数据是新增的，是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.priceTableData.splice(index, 1)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });

      } else {
        this.$confirm("是否删除该条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            ontherMarkets
              .DNP_DELETE_DNPMARKET_PRICE_VALUE_GOODS(data)
              .then((res) => {

                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.setPrice(this.selectPriceList);
              })
              .catch((err) => {
                this.$message({
                  type: "info",
                  message: "删除失败",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }

    },
    //价格设置清空
    priceEmpty(index) {

      index.gearName = "";
      index.goodsName = '';

    },
    // 取消  关闭添加市场弹框
    cancel() {
      this.defaultData(this.form);
    },
    //取消  关闭价格设置弹框
    cancelPrice() {
      this.focusMarketFormVisible = false
    },
    //添加价格设置
    savePrice() {
      // 校验货品是否有元素未填
      if (this.priceTableData.length > 0) {

        let listShow = this.priceTableData.some(item => {
          return item.gearName == '' || item.goodsName == '' || item.categoryName == ''
        })
        if (listShow) {
          return this.$message({
            type: "info",
            message: "请将数据填完!",
          });
        }
      }

      this.selectPriceList = {
        ...this.selectPriceList,
        dnpMarketPriceValueGoodsDtoList: this.priceTableData,
        valueType: this.valueType,
        mid: this.market.mid
      }
      ontherMarkets
        .DNP_SAVE_DNPMARKET_PRICE_VALUE_INFO(this.selectPriceList)
        .then((res) => {

          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.focusMarketFormVisible = false
          this.getList();
        })
        .catch((err) => {
        });
    },
    //添加关注货品确定
    addMarketQuotations(item) {

      let data = {
        ...this.form,
        type: item
      }
      if (this.form.goodsName == '') {
        return this.$message({
          type: "info",
          message: "请填写货品名!",
        });
      } else if (this.form.goodsType == '') {
        return this.$message({
          type: "info",
          message: "请填写所属大类!",
        });
      } else if (this.form.categoryName == '') {
        return this.$message({
          type: "info",
          message: "请填写货品类别!",
        });
      } else if (this.form.specifications == '') {
        return this.$message({
          type: "info",
          message: "请填写货品规格!",
        });
      } else if (this.form.sourceName == '') {
        return this.$message({
          type: "info",
          message: "请填写产地!",
        });
      }
      ontherMarkets.DNP_SAVE_DNPMARKET_PRICE_VALUE(data).then(res => {

        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.dialogFormVisible = false;
        this.form = {
          goodsName: '',
          goodsTypId: '',
          goodsType: '',
          categoryId: '',
          categoryName: '',
          specifications: '',
          sourceId: '',
          sourceName: '',
          type: '',
        };
        this.getList()
      }).catch(err => {
        this.$message({
          type: "info",
          message: "添加失败",
        });
      })

    },
    //排序
    search() {

      this.getList()
    },
    //删除
    del(item) {
      this.$confirm("是否删除该条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          ontherMarkets
            .DNP_DEL_DNPMARKET_PRICE_VALUE({...item, mid: this.market.mid})
            .then((res) => {

              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            })
            .catch((err) => {
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //价位设置
    setPrice(val) {
      this.loading = true;
      this.goodsName = val.goodsName
      this.focusMarketFormVisible = true;
      this.valueType = val.valueType

      this.selectPriceList = val;
      let data = {
        mid: this.market.mid,
        mpvsId: val.mpvsId
      }
      ontherMarkets
        .DNP_FIND_DNPMARKET_PRICE_VALUE_INFO(data)
        .then((res) => {

          this.priceTableData = res.data.results.dnpMarketPriceValueGoodsList || [];
          this.loading = false;
          // this.$message({
          //   type: "success",
          //   message: "撤销成功!",
          // });
          // this.getList();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "数据请求失败!",
          });
          this.loading = false;
        });
    },
    //编辑
    edit(data) {

      this.options.forEach(item => {
        item.children.forEach(i => {
          if (i.id == data.cascade[1]) {

            data.cascade = [item.id.toString(), data.cascade[1]]
          }
        })
      })

      data.bool = true;
    },
    //保存
    save(data) {
      data.bool = false;

      let item = {
        ...data,
        type: "modify"
      }
      ontherMarkets.DNP_SAVE_DNPMARKET_PRICE_VALUE(item).then(res => {

        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.dialogFormVisible = false;
        this.getList()
      }).catch(err => {
        this.$message({
          type: "info",
          message: "修改失败",
        });
      })
    },
    //分页
    handleCurrentChange(val) {
      this.params.current_page = val;
      this.getList();

    },
    //获取国内产地
    goodSourceList() {
      ontherMarkets.CHENGFENG_GET_GOODSOURCE_LIST({}).then(res => {

        this.options = res.data.results
        // .map(item=>{
        //   return {
        //   ...item,
        //   label:item.text,
        //   value:item.id,
        //   children: item.children.map(i=>{
        //     return {
        //       ...i,
        //       label:i.text,
        //       value:i.id,
        //     }
        //   })
        // }
        // })
      }).catch(err => {

      })
    },
    //获取列表数据
    getList() {

      ontherMarkets.DNP_FIND_DNPMARKET_PRICE_VALUE({mid: this.market.mid, ...this.params}).then(response => {
        if (response.data.status === "Y") {
          let list = response.data.results.dnpMarketPriceValueList;
          let str = '';
          list.forEach(item => {
            this.options.forEach(i => {
              i.children.forEach(j => {
                if (item.sourceId == j.id) {
                  str = i.id;
                }

              })
            })
          })
          this.tableData = list.map(item => {
            return {
              ...item,
              bool: false,
              editShow: false,
              cascade: [str, item.sourceId.toString()]
            };
          })

          this.total = response.data.results.total;
          this.loading = false;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.categoryName.indexOf(value) !== -1;
    },
    // 获取树类型结构
    getGoodType() {
      ontherMarkets.getGoodCategoryTree().then(response => {
        this.CategoryTree = response.data.results;
        let CategoryTree = response.data.results;
        CategoryTree.forEach(item => {
          if (item.categoryName !== "其他") {
            item.disabled = true;
            item.childrens.forEach(categoryItem => {
              categoryItem.disabled = true;
            });
          }
        });

        this.treeData[0].childrens = CategoryTree;
      });
    },
    handlePriceClick(data, checked, node) {

      if (checked) {
        // this.params.categoryId = data.categoryId;
        // this.params.categoryName = data.categoryName;
        this.priceTableData[this.activeindex].categoryName = data.categoryName;
        this.priceTableData[this.activeindex].categoryId = data.categoryId;
      }

      this.count++;
      if (this.count % 2 === 0) {
        if (checked) {
          this.$refs.treePriceParams.setCheckedNodes([]);
          this.$refs.treePriceParams.setCheckedNodes([data]);
          // this.params.categoryId = data.categoryId;
          // this.params.categoryName = data.categoryName;
          this.priceTableData[this.activeindex].categoryName = data.categoryName;
          this.priceTableData[this.activeindex].categoryId = data.categoryId;
          //交叉点击节点
        } else {
          this.$refs.treePriceParams.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      // this.showPriceTreeType = false;
      this.priceTableData[this.activeindex].editBool = false;

    },
    handleClick(data, checked, node) {

      if (checked) {
        // this.params.categoryId = data.categoryId;
        // this.params.categoryName = data.categoryName;
        this.tableData[this.activeindex].categoryName = data.categoryName;
        this.tableData[this.activeindex].categoryId = data.categoryId;
      }

      this.count++;
      if (this.count % 2 === 0) {
        if (checked) {
          this.$refs.treeParams.setCheckedNodes([]);
          this.$refs.treeParams.setCheckedNodes([data]);
          // this.params.categoryId = data.categoryId;
          // this.params.categoryName = data.categoryName;
          this.tableData[this.activeindex].categoryName = data.categoryName;
          this.tableData[this.activeindex].categoryId = data.categoryId;
          //交叉点击节点
        } else {
          this.$refs.treeParams.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      // this.showTreeType = false;
      this.tableData[this.activeindex].editShow = false;
    },
    //添加关注货品选择货品大类
    selectGoodsTypId(val) {
      if (val == 308) {
        this.form.goodsType = '水果'
      }
      if (val == 1) {
        this.form.goodsType = '蔬菜'
      }
    },
    showTreeGoodType(type, data) {

      if (type === "search") {
        // this.showTreeType = true;
        this.tableData[data].editShow = true;

        this.$nextTick(() => {
          this.$refs.inp.$el.querySelector('input').focus();
        })


        this.activeindex = data;
      } else if (type === "searchPrice") {
        // this.showPriceTreeType=true;
        this.priceTableData[data].editBool = true;

        this.$nextTick(() => {
          this.$refs.inp2.$el.querySelector('input').focus();
        })
        this.activeindex = data;
      } else {
        this.TreeGoodType = true;

        this.$nextTick(() => {
          this.$refs.inp1.$el.querySelector('input').focus();
        })
      }
      this.inputText = '';
      this.inputText2 = '';
      this.inputText1 = '';
    },
    handleFormClick(data, checked, node) {
      if (checked) {
        this.form.categoryId = data.categoryId;
        this.form.categoryName = data.categoryName;
      }

      this.countForm++;
      if (this.countForm % 2 === 0) {
        if (checked) {
          this.$refs.treeForm.setCheckedNodes([]);
          this.$refs.treeForm.setCheckedNodes([data]);
          this.form.categoryId = data.categoryId;
          this.form.categoryName = data.categoryName;
          //交叉点击节点
        } else {
          this.$refs.treeForm.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      this.TreeGoodType = false;
    },
    dialogBeforeClose(done) {
      this.dialogFormVisible = false;
      this.focusMarketFormVisible = false;
      this.defaultData();
    },
    defaultData(form) {
      this.form = {
        goodsName: '',
        goodsTypId: '',
        goodsType: '',
        categoryId: '',
        categoryName: '',
        specifications: '',
        sourceId: '',
        sourceName: '',
        type: '',
      };
      this.priceTableData = [];
      this.radio1 = '1';
      this.dialogFormVisible = false;
      this.focusMarketFormVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.productSettings {
  width: 100%;
  background-color: #fff;

  .headTiter {
    border-bottom: 1px solid #d1d1d1;
  }

  .table-productSettings {

  }
}

.redio-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  font-size: 16px;
  color: #409eff;
}

.tree_type {
  //width: 200px !important;
}

//.goosType{
//  .el-input{
//    width: 222px;
//  }
//  position: relative;
//
//  .tree_type{
//    border-radius: 8px;
//    width: 300px;
//    position: absolute;
//    bottom: 0;
//    left: 0;
//    top:0;
//    background: #fff;
//    border: 1px solid #dcdfe6;;
//    height: 312px;
//    overflow: hidden;
//    overflow-y:auto ;
//    padding: 0;
//    box-sizing: border-box;
//    >>>.el-dialog{
//        margin-top:0 !important;
//        width: 100%;
//    }
//    >>>.el-dialog__header{
//      padding:0px 8px;
//      .el-dialog__title{
//        line-height: 18px;
//      }
//      >>>.el-dialog__headerbtn{
//        right: 5px;
//        top:5px;
//      }
//    }
//    >>>.el-dialog__body{
//      height: 265px;
//      padding: 0 20px 0 0;
//    }
//  }
//}
.tree_type > > > .el-dialog {
  margin: 0 !important;
  width: 100%;
}
</style>
