<template>
  <div>
    <el-card class='box-card mt-4'>
      <el-form :inline='true'  :model='screenForm' ref='screenForm' label-width='80px' class='demo-form-inline cardForm'>
        <el-form-item label='姓名' prop='carNumber'>
          <el-input v-model='screenForm.name' placeholder='请输入姓名'></el-input>
        </el-form-item>
        <el-form-item label='手机号'>
          <el-input v-model='screenForm.phone' placeholder='请输入姓名'></el-input>
        </el-form-item>
        <el-form-item label='角色' prop='carNumber'>
          <el-select v-model='screenForm.roleId' placeholder='请选择'>
            <el-option v-for='item in roleList' :label='item.roleName' :value='item.roleId'
                       :key='item.roleId'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='区域大类' class='input' prop='accountRegionName'>
          <el-select v-model='screenForm.regionTypeId' placeholder='请选择'>
            <el-option label='全部' value=''></el-option>
            <el-option
              v-for='(item, index) in regionalData'
              :key='index.id'
              :label='item.name'
              :value='item.id'
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='状态' prop='incomStatus'>
          <el-select v-model='screenForm.status' placeholder='请选择状态'>
            <el-option label='全部' value=''></el-option>
            <el-option label='启用' value='Y'></el-option>
            <el-option label='停用' value='N'></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type='primary' plain class='m-l-40' @click='handleCurrentChange(1)'>筛选</el-button>
        <el-button  plain class='m-l-40' @click="restScreenForm">重置</el-button>
      </div>
      <el-row v-if="$getPermissionByRole('21')">
        <el-col>
          <div class='textRight'>
            <el-button type='success' plain @click='openAddForm'>添加员工</el-button>
          </div>

        </el-col>
      </el-row>
    </el-card>

    <el-card class='table-conter b-c-f m-t-20'>
      <el-table :data='tableData' style='width: 96%;margin: 0 auto' v-if="$getPermissionByRole('20')">
        <el-table-column prop='userName' label='姓名'></el-table-column>
        <el-table-column prop='phone' label='手机号' ></el-table-column>
        <el-table-column prop='roleName' label='角色' ></el-table-column>
        <el-table-column prop="regionTypesStr" label="区域大类"></el-table-column>
        <el-table-column prop='status' label='状态'>
          <template slot-scope='scope'>
            <span v-if="scope.row.status === 'Y'">正常</span>
            <span v-else style='color: red'>停用</span>
          </template>
        </el-table-column>
        <el-table-column label='操作'>
          <template slot-scope='scope'>
            <el-button v-if="$getPermissionByRole('22')" @click='openEditStaff(scope.row)' type='text'>编辑</el-button>
<!--            <el-button v-if="$getPermissionByRole('23')" @click='openDelVis(scope.row.uid)' type='text'>删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change='handleSizeChange'
        @current-change='handleCurrentChange'
        :current-page='screenForm.current'
        :page-sizes='[10, 20, 30, 50]'
        :page-size='screenForm.pageSize'
        layout='total, sizes, prev, pager, next, jumper'
        :total='total'
      ></el-pagination>
    </el-card>

    <el-dialog :visible.sync='dialogFormVisible' width='35%'>
      <el-form :model='addForm' style='width: 400px' :rules='rules' ref='addForm'>
        <el-form-item label='姓名' :label-width='formLabelWidth' prop='userName'>
          <el-input v-model='addForm.userName' placeholder='请输入姓名'></el-input>
        </el-form-item>

        <el-form-item label='手机号' :label-width='formLabelWidth' prop='phone'>
          <el-input v-model='addForm.phone' placeholder='请输入手机号'></el-input>
        </el-form-item>
        <el-form-item label='登录密码' :label-width='formLabelWidth' prop='password' v-if='!isEdit'>
          <el-input v-model='addForm.password'
                    placeholder='请输入登录密码'></el-input>
        </el-form-item>
      <el-form-item label='登录密码' :label-width='formLabelWidth' v-if='isEdit'>
          <el-input v-model='addForm.password'
                    placeholder='请输入登录密码'></el-input>
        </el-form-item>
        <el-form-item label='归属区域大类' :label-width='formLabelWidth' class='input' prop='addForm.regionTypeIds'>
          <el-select v-model='addForm.regionTypeIds' placeholder='请选择' @change="regionTypeIdsChange" multiple>
            <el-option
              v-for='item in regionalData'
              :key='item.id'
              :label='item.name'
              :value='item.id'
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='角色' :label-width='formLabelWidth' prop='addForm.roleList'>
          <el-checkbox-group v-model='addForm.roleList' @change="roleChange">
            <el-checkbox
              v-if='item.roleId'
              v-for='item in roleList'
              :label='item.roleId'
              :key='item.roleId'>
              {{ item.roleName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
          <el-select v-model="tempStatus" placeholder="请选择状态">
            <el-option label="正常" value="Y"></el-option>
            <el-option label="停用" value="N"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="照片" :label-width="formLabelWidth" prop="status">-->
        <!--        <el-upload-->
        <!--          action="https://jsonplaceholder.typicode.com/posts/"-->
        <!--          list-type="picture-card"-->
        <!--          :on-preview="handlePictureCardPreview"-->
        <!--          :on-remove="handleRemove">-->
        <!--          <i class="el-icon-plus"></i>-->
        <!--        </el-upload>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='dialogFormVisible = false'>取 消</el-button>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync='deldetVisible' width='30%' center>
      <div class='prompt'>
        <p class='m-b-20'>确认删除该员工吗?</p>
        <p class='color87'>删除后将无法登录系统</p>
      </div>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='deldetVisible = false'>取 消</el-button>
        <el-button type='primary' @click='deleteStaff'>确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { staff } from '../api'


export default {
  name: 'staffList',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    //手机检验
    const validatePhone = (rule, value, callback) => {
      const myreg = /^1[3|4|5|7|8|6|9][0-9]{9}$/
      if (!myreg.test(value)) {
        callback(new Error(' 请输入有效的手机号码！'))
      } else {
        if (value.length !== 11) {
          callback(new Error('请输入有效的手机号码！'))
        } else {
          callback()
        }
      }
    }
    return {
      tempStatus: '',
      payChannelList:{},
      regionalData:[],//有权限的区域大类
      checkedCities: [],
      screenForm: {
        status: '',
        name: '',
        phone: '',
        roleId: '',
        regionTypeId:'',
        current: 1,
        pageSize: 10
      },
      total: 0,
      roleList: [],
      tableData: [],
      formLabelWidth: '120px',
      dialogFormVisible: false,
      addForm: {
        userName: '',
        phone: '',
        password: '',
        regionTypeIds:[],
        roleList: [],
        status: 'Y'
      },
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择员工状态', trigger: 'change' }
        ],
        roleList: [
          { type: 'array', required: true, message: '请至少选择一个角色', trigger: 'change' }
        ],
        phone: [
          { validator: validatePhone, trigger: 'blur', required: true }
        ],
        password: [
          { validator: validatePass, trigger: 'blur', required: true }
        ],
        regionTypeIds: [
          {type: 'array', required: true, message: '请选择区域大类', trigger: 'change'  }
        ]
      },
      delForm: {
        staffId: ''
      },
      submiting: false,
      deldetVisible: false,
      isEdit: false
    }
  },
  created() {
    this.getStaffList()
    this.getStaffRoleList()
    this.tempStatus = this.addForm.status;
    this.addForm.status = this.tempStatus;
  },
  mounted() {
    this.getchannelSelectList()
    this.getEnableList();//字典接口
    this.findAllRegionTypes()
  },
  methods: {
    async getEnableList(){
      let param = {
        current: 1,
        dictType: 'account_region',
        pageSize: 10,
        status: 'Y',
      };
      staff.findSysDictData(param).then(res =>{
        if (res.data.data) {
          this.enables = res.data.data;
        }
      });
    },
    regionTypeIdsChange(e){
      this.$forceUpdate();
      this.$refs.addForm.validateField('regionTypeIds');
      return
    },
    async getchannelSelectList() {
      let res = await staff.getchannelSelectList()
      this.payChannelList = res.data.results
    },
    handleSizeChange(val) {
      this.screenForm.pageSize = val
      this.getStaffList()
    },
    handleCurrentChange(val) {
      this.screenForm.current = val
      this.getStaffList()
    },

    // 获取员工权限
    getStaffRoleList() {
      staff.GET_DNPROLELIST().then(res => {
        this.roleList = res.data.results
        this.roleList.unshift({ roleName: '全部', roleId: '' })

      })
      // staffManagement.getStaffPermission({staffId: '667615c978ab42cba68e960811033782'}).then(res => {

      // })
    },

    // 获取员工列表
    getStaffList() {
      staff.GET_DNPUSERLIST(this.screenForm).then(res => {
        this.tableData = res.data.data || []
        this.total = Number(res.data.total) || 0
      })
    },
    // 获取所有可授权的区域列表
    findAllRegionTypes(){
      staff.findAllRegionTypes().then(res => {
        if(res.data.code===200){
          this.regionalData=res.data.data || []
        }
      }).catch(err=>{
        console.log('err',err);
      })
    },

    openAddForm() {
      this.restForm()
      this.dialogFormVisible = true
      this.isEdit = false

    },
    roleChange(e){
      this.$forceUpdate();
      return
    },
    submitForm() {
      this.addForm.tempStatus = this.tempStatus;
      this.addForm.status = this.tempStatus;
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (!this.isEdit) {
            this.addStaff()
          } else {
            this.editStaff()
          }
        }
      })
    },
    addStaff() {
      if (this.submiting) {
        return
      }
      this.submiting = false
      staff.ADD_DNPUSER(this.addForm).then(res => {
        this.dialogFormVisible = false
        this.submiting = false
        this.handleCurrentChange(1)
      }).catch(err => {
        this.submiting = false
      })
    },
    openEditStaff(staff) {
      this.isEdit = true
      this.dialogFormVisible = true

      // 设置回显状态2
      this.tempStatus = staff.status

      let regionData = []
      let regionNames = staff.regionTypesStr.split(',')
      this.regionalData.map(i => {
        regionNames.map(c => {
          if (c === i.name) {
            regionData.push(i.id)
          }
        })
      })
      staff.regionTypeIds = regionData

      let roleData = []
      let roleNames = staff.roleName.split(',')
      this.roleList.map(y => {
        roleNames.map(d => {
          if (d === y.roleName) {
            roleData.push(y.roleId)
          }
        })
      })
      staff.roleList = roleData
      staff.userId = staff.uid
      this.addForm = staff
      this.getStaffRoleList()
    },
    editStaff() {
      staff.EDIT_DNPUSER(this.addForm).then(res => {
        if(res.data.code===200){
          this.$message({
            type: 'success',
            message: '编辑成功'
          })
          this.dialogFormVisible = false
          this.handleCurrentChange(1)
        }
      }).catch(err=>{
        console.log('编辑保存err',err);
      })
    },
    openDelVis(uid) {
      this.delForm.staffId = uid
      this.deldetVisible = true
    },
    deleteStaff() {
      staff.DEL_DNPUSER(this.delForm).then(res => {
        let { error_code, error_msg } = res.data
        if (error_code !== 204) {
          this.$message({
            type: 'error',
            message: error_msg
          })
        } else {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.deldetVisible = false
          this.handleCurrentChange(1)
        }

      })
    },
    restForm() {
      this.addForm = {
        userName: '',
        phone: '',
        password: '',
        roleList: [],
        status: 'Y'
      }
    },
    restScreenForm(){
      this.$refs.screenForm.resetFields()
      this.screenForm={
        name: '',
        phone: '',
        roleId: '',
        regionTypeId:'',
      }
      this.getStaffList()
    }
  }
}
</script>

<style scoped lang='scss'>
.serch-header {
  .serch-form {
    .el-col {
      display: flex;
      align-items: center;
    }

    .date {
      overflow: hidden;
    }

    .lable-name {
      white-space: nowrap;
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.table-conter {
  .el-col {
    display: flex;
    align-items: center;
  }


  .lable-name {
    white-space: nowrap;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.prompt {
  text-align: center;
  font-size: 18px;
}


.color87 {
  color: #898787;
  font-size: 14px;
}


</style>
<style scoped>
>>> .el-checkbox-group {
  display: flex !important;
  flex-wrap: wrap !important;
}

>>> .el-checkbox {
  width: 50% !important;
  margin-right: 0 !important;
}
</style>




