// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/titleBar/com_gerenzhongxin_icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab .el-header[data-v-3f68fd4b] {\n  padding-right: 20px !important;\n  box-sizing: border-box;\n}\n.titleBar[data-v-3f68fd4b] {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  justify-content: space-between;\n}\n.titleBar .rightU[data-v-3f68fd4b] {\n    display: flex;\n}\ndiv[data-v-3f68fd4b] {\n  line-height: 60px;\n}\n.appName[data-v-3f68fd4b] {\n  width: 185px;\n  line-height: 60px;\n  background: #001529;\n  text-align: center;\n  font-size: 14px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: white;\n  letter-spacing: 2px;\n}\n.appName .loge img[data-v-3f68fd4b] {\n    width: 36px;\n    height: 36px;\n    margin-right: 14px;\n}\n.userName[data-v-3f68fd4b] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 24px 23px;\n  background-position: left center;\n  padding-left: 34px;\n}\n.exit[data-v-3f68fd4b] {\n  color: #05AE09;\n}\n.el-dropdown-link[data-v-3f68fd4b] {\n  cursor: pointer;\n  /*color: #409EFF;*/\n}\n.el-icon-arrow-down[data-v-3f68fd4b] {\n  font-size: 12px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
