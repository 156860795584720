var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "header" },
      [_vm._t("title"), _vm._t("submit", null, { dataList: _vm.dataList })],
      2
    ),
    _c(
      "ul",
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "list-border m-t-20 m-b-20 m-l-20 m-r-20",
          },
          [
            _c("h5", { staticClass: "m-b-20" }, [
              _vm._v(_vm._s(item.moduleName)),
            ]),
            _c(
              "ul",
              { staticClass: "m-b-20 module-item" },
              [
                _c(
                  "li",
                  {
                    staticClass: "m-r-20 func-item",
                    class: { active: item.effect === "Y" },
                    on: {
                      click: function ($event) {
                        return _vm.setAllFunc(index, item.effect)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "iconfont",
                        class: { active: item.effect === "Y" },
                      },
                      [_vm._v("")]
                    ),
                    _c("i", [_vm._v("全选")]),
                  ]
                ),
                _vm._l(item.funcId, function (func, idx) {
                  return _c(
                    "li",
                    {
                      key: func.funcId,
                      staticClass: "m-r-20 func-item",
                      class: { active: func.effect === "Y" },
                      on: {
                        click: function ($event) {
                          return _vm.setFuncId(index, idx, func.effect)
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont",
                          class: { active: func.effect === "Y" },
                        },
                        [_vm._v("")]
                      ),
                      _c("i", [_vm._v(_vm._s(func.funcName))]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }