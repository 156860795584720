<template>
  <div class="Hydroelectric">
    <el-card>
      <div>
        <span>表类型:</span>
        <el-select v-model="deviceType" placeholder="请选择" @change="getdeviceType">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="Hydroelectric-table">
        <el-table
          :data="dailyMarketListVOPageData"
          border
          style="width: 100%"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column prop="deviceTypeStr" label="表类型"> </el-table-column>
          <el-table-column prop="feeConfigName" label="费用项目"> </el-table-column>
          <el-table-column prop="preDegress" label="上期度数"> </el-table-column>
          <el-table-column prop="curDegress" label="本期度数"> </el-table-column>
          <el-table-column prop="consumption" label="实际用量"> </el-table-column>
          <el-table-column prop="preReadingDate" label="上期读表时间"> </el-table-column>
          <el-table-column prop="curReadingDate" label="本期读表时间"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间"> </el-table-column>
          <el-table-column prop="feeId" label="收费单号" width="180"> </el-table-column>
          <el-table-column prop="collectWayStr" label="采集方式"> </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { marketplan } from '../api/api'
export default {
  props: {
    typeName: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '电表'
        },
        {
          value: 2,
          label: '水表'
        }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      deviceType: '',
      dailyMarketListVOPageData: [],
      sumConsumption: 0
    }
  },
  watch: {
    '$route.query.data.mrgId'(newValue, oldValue) {
      if (!newValue) {
        //
      }else{
          this.getMarketListVOPage()
      }
    },
    typeName(val) {
      if (val === 'hydroelectric') {
        this.getMarketListVOPage()
      }
    }
  },
  methods: {
    getdeviceType() {
      this.getMarketListVOPage()
    },
    getMarketListVOPage() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true,
        current: this.currentPage,
        pageSize: this.pageSize,
        deviceType: this.deviceType,
        regionId: this.$route.query.data.regionId,
        boothId: this.$route.query.data.mrgId
      }
      marketplan
        .getAutoAndManualMeter({ ...obj })
        .then((result) => {
          this.dailyMarketListVOPageData = result.data.data.dailyMarketListVOPageData.data
          this.total = result.data.data.dailyMarketListVOPageData.total
          this.sumConsumption = result.data.data.sumConsumption
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCurrentChange(current) {
      this.currentPage = current
      this.getMarketListVOPage()
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.getMarketListVOPage()
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 4) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = this.sumConsumption
          } else {
            sums[index] = ''
          }
        }
      })

      return sums
    }
  }
}
</script>
<style lang="scss">
.Hydroelectric {
  .Hydroelectric-table {
    margin-top: 20px;
    margin-bottom: 20px;
    .cell {
      text-align: center;
    }
  }
  .el-table__footer-wrapper tbody td {
    color: red;
  }
}
</style>
