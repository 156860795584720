<template>
  <div>
    <el-row :gutter="20">
      <el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal">
        <el-menu-item index="/marketsTab/ontherMarkets">其他市场行情</el-menu-item>
        <el-menu-item index="/marketsTab/largeScreenDisplaySettings">大屏展示设置</el-menu-item>
      </el-menu>
    </el-row>
    <router-view name="marketsTabView"></router-view>
  </div>
</template>

<script>
export default {
  name: "otherMarketTab"
}
</script>

<style scoped>

</style>


<style scoped>
.el-menu-item {
  font-size: 22px;
  font-weight: 400;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom-color: #05AE09;
  color: #05AE09;
}

.el-menu--horizontal > .el-menu-item {
  color: #000;
}
</style>
<style scoped>
>>> .el-menu-item {
  color: rgb(51, 51, 51) !important;
}

>>> .el-menu-item.is-active {
  background-color: #fff !important;
  color: rgb(51, 51, 51) !important;
}
</style>

