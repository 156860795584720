export default [
  {
    path: 'tricycleAccount',
    name: 'tricycleAccount',
    component: () => import('./views/tricycleAccount.vue'),
    meta: {
      title: '三轮车账户详情'
    }
  },
  {
    path: 'vehicleManagement',
    name: 'vehicleManagement',
    component: () => import('./views/vehicleManagement.vue'),
    meta: {
      title: '车辆管理'
    }
  },
  {
    path: 'channelManagement',
    name: 'channelManagement',
    component: () => import('./views/channelManagement.vue'),
    meta: {
      title: '通道管理'
    }
  },
  {
    path: 'exitInterceptSetUp',
    name: 'exitInterceptSetUp',
    component: () => import('./views/exitInterceptSetUp.vue'),
    meta: {
      title: '出场拦截设置'
    }
  },
  {
    path: 'transactionFeeOrderForm',
    name: 'transactionFeeOrderForm',
    component: () => import('./views/transactionFeeOrderForm.vue'),
    meta: {
      title: '交易费订单'
    },
  },
  {
    path: 'parkingMonthlyReport',
    name: 'parkingMonthlyReport',
    component: () => import('./views/parkingMonthlyReport.vue'),
    meta: {
      title: '停车费月报表'
    },
  },
  {
    path: "vegetableWeighing",
    name: "vegetableWeighing",
    component: () => import('./views/vegetableWeighing.vue'),
    meta: {
      title: "交易费订单-蔬菜称重"
    }
  },
  {
    path: "vegetableCase",
    name: "vegetableCase",
    component: () => import('./views/vegetableCase.vue'),
    meta: {
      title: "交易费订单-蔬菜案件"
    }
  },
  {
    path: "fruitSection",
    name: "fruitSection",
    component: () => import('./views/fruitSection.vue'),
    meta: {
      title: "交易费订单-水果区"
    }
  },
  {
    path: 'vehicleManag',
    name: 'vehicleManag',
    component: () => import('./views/vehicleManag.vue'),
    meta: {
      title: '车辆进场管理'
    }
  },
  {
    path: 'carParkBusinessConfiguration',
    name: 'carParkBusinessConfiguration',
    component: () => import('./views/carParkBusinessConfiguration.vue'),
    meta: {
      title: '车场业务配置'
    }
  },
  {
    path: 'paymentManagement',
    name: 'paymentManagement',
    component: () => import('./views/paymentManagement.vue'),
    meta: {
      title: '缴费管理'
    }
  },
  {
    path: 'abnormalReleaseVehicles',
    name: 'abnormalReleaseVehicles',
    component: () => import('./views/abnormalReleaseVehicles.vue'),
    meta: {
      title: '异常放行车辆'
    }
  },
  {
    path: 'feeReport',
    name: 'feeReport',
    component: () => import('./views/feeReport.vue'),
    meta: {
      title: '收费报表'
    }
  },
  {
    path: 'regionalDistribution',
    name: 'regionalDistribution',
    component: () => import('./views/regionalDistribution.vue'),
    meta: {
      title: '核查车辆'
    }
  },
  {
    path: 'chargeManagement',
    name: 'chargeManagement',
    component: () => import('./views/chargeManagement.vue'),
    meta: {
      title: '收费规则管理'
    }
  },
  {
    path: 'ruleSetConfig',
    name: 'ruleSetConfig',
    component: () => import('./views/ruleSetConfig.vue'),
    meta: {
      title: '清分规则设置'
    }
  },
  {
    path: 'sortingDetail',
    name: 'sortingDetail',
    component: () => import('./views/sortingDetail.vue'),
    meta: {
      title: '清分明细'
    }
  },
  {
    path: 'enterThewhitelist',
    name: 'enterThewhitelist',
    component: () => import('./views/enterThewhitelist.vue'),
    meta: {
      title: '进出场白名单'
    }
  },
  {
    path: 'blackList',
    name: 'blackList',
    component: () => import('./views/blackList.vue'),
    meta: {
      title: '进出场黑名单'
    }
  },
  {
    path: 'ldentifyVehicle',
    name: 'ldentifyVehicle',
    component: () => import('./views/ldentifyVehicle.vue'),
    meta: {
      title: '识别车型'
    }
  },
  {
    path: 'TollModel',
    name: 'TollModel',
    component: () => import('./views/TollModel.vue'),
    meta: {
      title: '收费车型'
    }
  },
  {
    path: 'newRule',
    name: 'newRule',
    component: () => import('./views/newRule.vue'),
    meta: {
      title: '新建规则'
    }
  },
  {
    path: 'transactionFeeManagement',
    name: 'transactionFeeManagement',
    component: () => import('./views/transactionFeeManagement.vue'),
    meta: {
      title: '交易费记录'
    }
  },
  {
    path: 'stopTrainChargeRecord',
    name: 'stopTrainChargeRecord',
    component: () => import('./views/stopTrainChargeRecord.vue'),
    meta: {
      title: '停车收费记录'
    }
  },
  {
    path: 'parkingfeesRefund',
    name: 'parkingfeesRefund',
    component: () => import('./views/parkingfeesRefund.vue'),
    meta: {
      title: '停车费退款记录'
    }
  },
  {
    path: 'operatingReport',
    name: 'operatingReport',
    component: () => import('./views/entryAndExitOperatingReport.vue'),
    redirect: '/operatingReport/reportSynopsis',
    children: [
      {
        path: 'reportSynopsis',
        name: 'reportSynopsis',
        component: () => import('./views/reportSynopsis.vue'),
        meta: {
          title: '数据总览'
        }
      },
      {
        path: 'reportParkingFee',
        name: 'reportParkingFee',
        component: () => import('./views/reportParkingFee.vue'),
        meta: {
          title: '停车费'
        }
      },
      {
        path: 'reportTradingFee',
        name: 'reportTradingFee',
        component: () => import('./views/reportTradingFee.vue'),
        meta: {
          title: '交易费'
        }
      },
    ]
  },
  {
    path: 'vehicleTypeList',
    name: 'vehicleTypeList',
    component: () => import('./views/VehicleTypeList.vue'),
    meta: {
      title: '车型管理'
    }
  },
  {
    path: 'tricycleManagement',
    name: 'tricycleManagement',
    component: () => import('./views/tricycleManagement.vue'),
    meta: {
      title: '三轮车管理'
    }
  },  {
    path: 'trainNumberDetailsNotification',
    name: 'trainNumberDetailsNotification',
    component: () => import('./views/trainNumberDetailsNotification.vue'),
    meta: {
      title: '车次详情'
    }
  }, {
    path: 'refundLog',
    name: 'refundLog',
    component: () => import('./views/refundLog.vue'),
    meta: {
      title: '退款日志'
    }
  }, {
    path: 'lockRuleManagement',
    name: 'lockRuleManagement',
    component: () => import('./views/lockRuleManagement.vue'),
    meta: {
      title: '上锁规则管理'
    }
  },{
    path: 'carLockManagement',
    name: 'carLockManagement',
    component: () => import('./views/carLockManagement.vue'),
    meta: {
      title: '车辆上锁管理'
    }
  },
]
