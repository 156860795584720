export default [
  {
    path: 'deviceManagement',
    name: 'deviceManagement',
    component: () => import('./view/index.vue'),
    meta: {
      title: '设备管理'
    }
  },
]
