<!--<template>-->

<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "largeScreenSort"-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
<template>
  <div class="reportConfiguration p-b-10">
    <div class="flex-back m-l-10 m-b-10 p-t-10">
      <el-page-header @back="goBack"/>
    </div>
    <header class="header">
      <h4>大屏展示排序</h4>
    </header>
    <div class="header">
      <el-table
        :data="tableData">
        <el-table-column prop="marketName" label="市场名称"></el-table-column>
        <el-table-column prop="abbreviation" label="简称"></el-table-column>
        <el-table-column prop="number" label="排序">
          <template slot-scope="scope">
            <el-input type="number" style="width: 200px" v-if="scope.row.editShow" placeholder="请输入"
                      v-model="scope.row.sequence"></el-input>
            <span v-else>
            {{ scope.row.sequence }}
          </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div v-if="!scope.row.editShow">
              <el-button type="primary" @click="editTrainModel(scope.row)">编辑</el-button>

            </div>
            <div v-else>
              <el-button @click="cancel(scope.row)">取消</el-button>
              <el-button type="success" @click="save(scope.row)">保存</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {ontherMarkets} from "../api";
import store from '@/store'

export default {
  name: "largeScreenSort",
  data() {
    return {
      tableData: [],
      editShow: true,
      switchShow: true,
      timeShow: false,
      time: 0,
      effectiveTime: '',
      trainInfo: {
        trainModelName: '',
        upperLimitWeight: '',
        delStatus: 'Y',
        sequence: '',
        tmId: 0,
      },
      total: 0,
      typeDialogVisible: false,
      type: 'modify',//modify为修改，add为增加
      sequence: [],
    }
  },
  mounted() {
    this.getFocusMarketList()
  },
  methods: {
    goBack() {
      // this.$router.go(-1)
      this.$router.push({
        name: "largeScreenDisplaySettings",
        params: {bool: this.bool},
      });
    },
    getFocusMarketList() {
      ontherMarkets.GET_FOLLOW_MARKET().then(res => {
        let data = res.data.results || [];
        this.sequence = data.map((item, index) => {
          return {
            // sequence:item.sequence,
            mid: item.mid,
          }
        })
        this.tableData = data.map((item, index) => {
          return {
            ...item,
            editShow: false,
          }
        })
        // this.tableData = JSON.parse(JSON.stringify(data));
      })
    },
    editTime() {
      this.timeShow = true;
    },
    cancelTime() {
      this.timeShow = false;
      this.time = this.effectiveTime;
    },
    saveTime() {
      let data = {
        effectiveTime: this.time,
        mid: store.state.marketId
      }
      if (!this.time) {
        this.$message({
          message: '有效时间不能为空',
          type: 'warning'
        });
      } else {
        approachPrepare.CHENGFENG_UPDATE_MARKET_EFFECTIVETIME(data).then(res => {
          this.timeShow = false;
          this.$message({
            message: '修改成功',
            type: 'warning'
          });
        }).catch(err => {
          this.$message({
            message: '修改失败',
            type: 'warning'
          });
        })
      }
    },

    //  新增
    addTrainModel() {
      this.typeDialogVisible = true;
    },
    //编辑
    editTrainModel(item) {
      item.editShow = true;
    },
    //  取消
    cancel(item) {
      item.editShow = false
      this.getFocusMarketList()
      this.sequence.forEach(i => {
        if (i.mid == item.mid) {
          return item.sequence = i.sequence;
        }
      })

    },
    //  保存
    save(item) {
      if (item.sequence <= this.tableData.length && item.sequence > 0) {
        ontherMarkets.DNP_SAVE_FOLLOW_MARKET_SEQUENCE(item).then(res => {

          this.$message({
            message: res.data.results,
            type: 'success'
          });
          this.getFocusMarketList()
        }).catch(err => {
          this.$message.error('修改失败')
        })
        // }
      } else {
        this.$message({
          message: '请正确输入排序',
          type: 'warning'
        });
      }

    },
  }

}
</script>

<style scoped lang="scss">
.reportConfiguration {
  width: 100%;
  //height: 100vh;
  background-color: #fff;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  padding: 0 20px;

  h4 {
    padding: 20px 0;

  }

  p {
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d1d1;
  }
}

.input-item {
  width: 45%;
}

.input-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.input-label {
  width: 50%;
}

.red {
  color: red;
}
</style>

