export default [
  {
    path: 'goodsDispatchTab',
    name: 'goodsDispatchTab',
    component: () => import('./views/goodsDispatchTab'),
    redirect: '/goodsDispatchTab/goodsInventory',
    children: [
      {
        path: 'goodsInventory',
        name: 'goodsInventory',
        component: () => import('./views/goodsInventory'),
        meta: {
          title: "场内货品库存"
        }
      },
      {
        path: 'amountGoods',
        name: 'amountGoods',
        component: () => import('./views/amountGoods'),
        meta: {
          title: "预计进程货品数"
        }
      },
    ]
  }
]
