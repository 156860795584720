<template>
  <div class="page-wrapper">
    <el-form>
      <el-row>
        <el-col :span="9">
          <el-form-item label="时间">
            <el-date-picker
              style="width: 200px;"
              v-model="params.startTime"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="searchList()"
            ></el-date-picker>
            <el-date-picker
              v-model="params.endTime"
              style="width: 200px;"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="searchList()"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="上传时间">
            <el-date-picker
              style="width: 200px;"
              v-model="params.uploadTime_start"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="searchList()"
            ></el-date-picker>
            <el-date-picker
              v-model="params.uploadTime_end"
              style="width: 200px;"
              type="datetime"
              placeholder="选择时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @change="searchList()"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="textRight m-b-10">
      <el-button @click="openUploadDialog" type="primary">上传</el-button>
    </div>
    <el-table
      header-row-class-name="header-table"
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="time"
        label="时间"/>
      <el-table-column
        prop="userName"
        label="状态">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 'Y'" type="text">已上传</el-button>
          <span v-else>未上传</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operatorName"
        label="操作人"/>
      <el-table-column
        prop="uploadTime"
        label="上传时间"/>
      <el-table-column
        prop="remark"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 'Y'" type="text" @click="exportToExcelData(scope.row.uploadJson)">下载查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="m-t-10"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="params.current_page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="params.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <el-dialog :visible.sync="dialogFormVisible" width="600px">
      <el-form :model="uploadForm" style="width: 80%">
        <el-form-item label="选择时间：" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="uploadForm.uploadTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择文件：" :label-width="formLabelWidth">
          <div class="position-relative">
            <el-input placeholder="点击上传文件" v-model="uploadMessage"></el-input>

            <input id="imFile"
                   class="imFile"
                   type="file"
                   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                   @change="importExcel()"/>
          </div>

        </el-form-item>
        <el-form-item class="textRight">
          <el-button type="text" @click="exportToExcelTemplate">下载模板</el-button>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formatDate} from "../../../util/date";
import XLSX from "xlsx";
import {ontherMarkets} from "../api";

export default {
  name: "uploadThePrice",
  data() {
    return {
      uploadMessage: '',
      formLabelWidth: '120px',
      params: {
        startTime: '',
        endTime: '',
        uploadTime_start: '',
        uploadTime_end: '',
        page_size: 10,
        current_page: 1,
      },
      dialogFormVisible: false,
      tableData: [],
      total: 0,
      uploadForm: {
        uploadTime: '',
        goodsList: []
      },
      goodsData: [
        {
          key: '上海青',
          value: '',
          id: '1'
        },
        {
          key: '冬瓜',
          value: '',
          id: '2'
        },
        {
          key: '圆白菜',
          value: '',
          id: '3'
        },
        {
          key: '土豆',
          value: '',
          id: '4',
        },
        {
          key: '地瓜叶',
          value: '',
          id: '5',
        },
        {
          key: '大白菜',
          value: '',
          id: '6',
        },
        {
          key: '小白菜',
          value: '',
          id: '7',
        },
        {
          key: '尖椒',
          value: '',
          id: '8',
        },
        {
          key: '生菜',
          value: '',
          id: '9'
        },
        {
          key: '白萝卜',
          value: '',
          id: '10',
        },
        {
          key: '空心菜',
          value: '',
          id: '11',
        },
        {
          key: '胡萝卜',
          value: '',
          id: '12',
        },
        {
          key: '芹菜',
          value: '',
          id: '13',
        },
        {
          key: '苦瓜',
          value: '',
          id: '14',
        },
        {
          key: '茄子',
          value: '',
          id: '15',
        },
        {
          key: '菜心',
          value: '',
          id: '16',
        },
        {
          key: '蒜苔',
          value: '',
          id: '17',
        },
        {
          key: '西红柿',
          value: '',
          id: '18',
        },
        {
          key: '豆角',
          value: '',
          id: '19',
        },
        {
          key: '青椒',
          value: '',
          id: '20',
        },
        {
          key: '韭菜',
          value: '',
          id: '21',
        },
        {
          key: '黄瓜',
          value: '',
          id: '22',
        },
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    uploadData() {
      const {uploadTime, goodsList = []} = this.uploadForm;

      if (!uploadTime) {
        return this.$message.error('请选则时间')
      }

      if (!goodsList.length) {
        return this.$message.error('请上传正确的货品数据')
      }

      ontherMarkets.uploadGoodsPriceReCord(this.uploadForm).then(res => {
        this.dialogFormVisible = false;
        this.getList();
        this.$message.success('上传成功')
      })
    },

    getList() {
      ontherMarkets.findGoodsPriceReCord(this.params).then(res => {
        const {total, privceUploadRecordList} = res.data.results;
        this.total = total;
        this.tableData = privceUploadRecordList
      })
    },

    openUploadDialog() {
      this.uploadMessage = '';
      this.uploadForm = {
        uploadTime: ''
      }
      this.dialogFormVisible = true;
    },

    searchList() {
      this.params.current_page = 1;
      this.getList()
    },

    sizeChange(size) {
      this.params.page_size = size;
      this.getList()
    },

    currentChange(current) {
      this.params.current_page = current;
      this.getList()
    },

    importExcel() {
      let obj = event.target, wb, rABS = false;
      if (!obj.files) {
        return;
      }
      const f = obj.files[0];
      let reader = new FileReader();
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
      reader.onload = (e) => {
        let data = e.target.result;
        if (rABS) {
          wb = XLSX.read(btoa(this.fixData(data)), {//手动转化
            type: 'base64'
          });
        } else {
          wb = XLSX.read(data, {
            type: 'binary'
          });
        }
        /**
         * wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
         * wb.Sheets[Sheet名]获取第一个Sheet的数据
         */
        let excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        this.resExcelData(excelJson,f.name)

        obj.value = ''
      };

    },

    //  处理excel数据
    resExcelData(data,name) {
      let excelHeader = data[0];
      let valueKey, excelKey;
      for (const excelHeaderKey in excelHeader) {
        if (excelHeaderKey !== '时间') {
          valueKey = excelHeaderKey;
        } else {
          excelKey = excelHeaderKey;
        }
      }
      let params = {
        date: valueKey,
        exponent: data[0][valueKey],
        goodsList: [],
      }

      params.goodsList = data.splice(2, data.length).map(item => {
        return {
          goodsName: item[excelKey],
          price: item[valueKey]
        }
      });

      let isPrice
      params.goodsList.forEach(item => {
        if (!item.price) {
          isPrice = true
        }
      })
      if (isPrice) {
        return this.$message.error('请在excel内填写正确的货品价格')
      }

      if (params.goodsList.length !== 22) {
        return this.$message.error('请使用正确的数据模板')
      }

      params.goodsList.forEach(item => {
        let index = this.goodsData.findIndex(data => item.goodsName === data.key);
        item.goodsId = this.goodsData[index].id
      })

      this.uploadMessage = name;
      Object.assign(this.uploadForm, {
        ...params
      })

    },

    //excel数据导出
    exportToExcelTemplate() {
      require.ensure([], async () => {
        const {export_json_to_excel} = require("@/excel/Export2Excel");
        const tHeader = [
          "时间",
          formatDate(new Date(), 'yyyy-MM-dd')
        ];
        const filterVal = [
          "key",
          "value",
        ];
        let templateData = [
          {
            key: '总指数',
            value: ''
          },
          {
            key: '货品名',
            value: '均价'
          },
          ...this.goodsData
        ]
        const data = this.formatJson(filterVal, templateData);
        export_json_to_excel(tHeader, data, "海南大数据价格模板");

      });
    },

    exportToExcelData(data) {
      let excelData = JSON.parse(data);
      require.ensure([], async () => {
        const {export_json_to_excel} = require("@/excel/Export2Excel");
        const tHeader = [
          "时间",
          excelData.date
        ];
        const filterVal = [
          "key",
          "value",
        ];
        let goodsList = excelData.goodsList.map(item => {
          return {
            ...item,
            key: item.goodsName,
            value: item.price,

          }
        })
        let templateData = [
          {
            key: '总指数',
            value: excelData.exponent
          },
          {
            key: '货品名',
            value: '均价'
          },
          ...goodsList
        ]
        const data = this.formatJson(filterVal, templateData);
        export_json_to_excel(tHeader, data, `海南大数据价格${formatDate(new Date(excelData.uploadTime), 'yyyy-MM-dd')}`);

      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
}
</script>

<style scoped>
.page-wrapper {
  padding: 20px 30px;
  min-height: 500px;
  background: #fff;
}

>>> .el-table tr th {
  color: #333;
  background: #f5f5f5 !important;
}

.imFile {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

</style>
