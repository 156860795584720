<template>
  <div class="ontherMarkets f-s-14 m-t-10">
    <!--标题-->
    <div class="headTiter b-c-f p-t-10 p-l-20">
      <label>其他市场行情</label>
    </div>
    <!--内容-->
    <el-row>
      <el-col :span="4">
        <div class="left-box">
          <span :class="spanIndex=='1'?'span-active':''" @click="setSpanIndex('1')">今日实时数据</span>
          <span :class="spanIndex=='2'?'span-active':''" @click="setSpanIndex('2')">历史数据</span>
        </div>

        <el-tabs tab-position="left" style="height: auto;" @tab-click="changeTabMarket">
          <el-tab-pane v-for="(market,index) in addMarketList" :key="index" :label="market.marketName"></el-tab-pane>
        </el-tabs>
        <div v-if="$getPermissionByRole('34')" class="m-t-10"
             style="text-align: center;color: #15b9ff;cursor: pointer;">
          <p @click="focusMarketFormVisible=true">添加关注市场</p>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="content">
          <!--筛选条件-->
          <el-form v-if="spanIndex=='2'" ref="form" :model="params" label-width="90px" class="clearfix search">
            <el-row>
              <el-col :span="6">
                <el-form-item label="货品名称">
                  <el-input style="width: 200px;" v-model="params.goodsName" placeholder="请输入货品名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="选择时间" class="selectData">
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="timeQuantum"
                    @change="setTime"
                    type="daterange"
                    range-separator="至"
                    :picker-options="pickerOptions0"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="d-flex">
                <el-form-item label="价格取值方式">
                  <el-select v-model="params.valueType" placeholder="请选择价格取值方式">
                    <el-option
                      v-for="item in pricingMethod"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>

              <el-col :span="6">
                <el-form-item label="所属大类">
                  <el-select v-model="params.goodsTypId" placeholder="请选择所属大类">
                    <el-option
                      key=""
                      label="全部"
                      value=""
                    ></el-option>
                    <el-option
                      label="水果"
                      value="308"
                    ></el-option>
                    <el-option
                      label="蔬菜"
                      value="1"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="货品类别" class="goosType">
                  <el-input
                    v-model="params.categoryName"
                    placeholder="请选择"
                    @focus="showTreeGoodType('search')"
                    style="width: 200px"
                    clearable
                  ></el-input>
                  <el-dialog
                    :visible.sync="showTreeType"
                    class="tree_type"
                    v-if="showTreeType"
                    :maxlength="GLOBAL.maxlength"
                    title="货品分类"
                    :modal="false"
                  >
                    <el-input
                      v-model="inputText1"
                      placeholder="请选择"
                      clearable
                      style="width: auto;margin-left: 10px"
                      ref="inp"
                    ></el-input>
                    <el-tree
                      :data="treeData"
                      node-key="categoryId"
                      :props="defaultProps"
                      :highlight-current="true"
                      show-checkbox
                      check-strictly
                      :check-on-click-node="true"
                      :filter-node-method="filterNode"
                      @check-change="handleClick"
                      ref="treeParams"
                    ></el-tree>
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="规格">
                  <el-input style="width: 200px;" v-model="params.specifications" placeholder="请输入货品规格"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="产地">
                  <el-input style="width: 200px;" v-model="params.sourceName" placeholder="请输入产地"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="button">
                <el-form-item label="">
                </el-form-item>
              </el-col>
              <el-col :span="6" class="button">
                <el-button type="primary" icon="el-icon-search" @click="search" size="small">筛选</el-button>
              </el-col>
            </el-row>
          </el-form>
          <!--筛选条件-->
          <el-form v-else ref="form" :model="params" label-width="90px" class="clearfix search">
            <el-row>
              <el-col :span="6">
                <el-form-item label="货品名称">
                  <el-input style="width: 200px;" v-model="params.goodsName" placeholder="请输入货品名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="d-flex">
                <el-form-item label="价格取值方式">
                  <el-select v-model="params.valueType" placeholder="请选择价格取值方式">
                    <el-option
                      v-for="item in pricingMethod"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="所属大类">
                  <el-select v-model="params.goodsTypId" placeholder="请选择所属大类">
                    <el-option
                      key=""
                      label="全部"
                      value=""
                    ></el-option>
                    <el-option
                      label="水果"
                      value="308"
                    ></el-option>

                    <el-option
                      label="蔬菜"
                      value="1"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="货品类别" class="goosType">
                  <el-input
                    v-model="params.categoryName"
                    placeholder="请选择"
                    @focus="showTreeGoodType('search')"
                    style="width: 200px"
                    clearable
                  ></el-input>
                  <el-dialog
                    :visible.sync="showTreeType"
                    class="tree_type"
                    v-if="showTreeType"
                    :maxlength="GLOBAL.maxlength"
                    title="货品分类"
                    :modal="false"
                  >
                    <el-input
                      v-model="inputText2"
                      placeholder="请选择"
                      clearable
                      style="width: auto;margin-left: 10px"
                      ref="inp"
                    ></el-input>
                    <el-tree
                      :data="treeData"
                      node-key="categoryId"
                      :props="defaultProps"
                      :highlight-current="true"
                      show-checkbox
                      check-strictly
                      :check-on-click-node="true"
                      :filter-node-method="filterNode"
                      @check-change="handleClick"
                      ref="treeParams"
                    ></el-tree>
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="规格">
                  <el-input style="width: 200px;" v-model="params.specifications" placeholder="请输入货品规格"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="产地">
                  <el-input style="width: 200px;" v-model="params.sourceName" placeholder="请输入产地"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="button">
                <el-form-item label="">
                </el-form-item>
              </el-col>
              <el-col :span="6" class="button">
                <el-button type="primary" icon="el-icon-search" @click="search" size="small">筛选</el-button>
              </el-col>
            </el-row>
          </el-form>

          <div class="textRight b-c-f p-b-10 p-r-10">
            <el-button v-if="spanIndex=='1'" type="primary" @click="toPush('productSettings')" class="file-box"
                       size="small">
              关注货品设置
            </el-button>


            <el-button v-if="spanIndex=='1'" type="primary" @click="toPush('manualDataCollection')" size="small">
              人工采集数据
            </el-button>
            <el-button type="primary" @click="exportToExcel" v-if="$getPermissionByRole('112')&&spanIndex=='1'"
                       size="small">
              导出数据
            </el-button>
            <el-button type="primary" @click="exportToExcel" v-if="$getPermissionByRole('113')&&spanIndex=='2'"
                       size="small">
              导出数据
            </el-button>
          </div>
          <!-- 市场行情列表 -->
          <el-table
            stripe
            :data="tableData"
            header-row-class-name="table-header"
            v-loading="loading"
            v-if="$getPermissionByRole('17')&&spanIndex=='1'"
            class="table-data-list"
          >

            时间
            <el-table-column v-if="spanIndex=='2'" prop="time" label="时间"></el-table-column>
            货品名称
            <el-table-column prop="goodsName" label="货品名称"></el-table-column>
            <!-- 所属大类 -->
            <el-table-column prop="goodsType" label="所属大类"></el-table-column>
            <!-- 所属类别 -->
            <el-table-column prop="categoryName" label="所属类别"></el-table-column>
            <!-- 规格 -->
            <el-table-column prop="specifications" label="规格"></el-table-column>
            <!-- 产地 -->
            <el-table-column prop="sourceName" label="产地"></el-table-column>

            <!-- 最低价 -->
            <el-table-column prop="minPrice" style="color: black" label="最低价">
              <template slot-scope="scope">
                <span>{{ scope.row.minPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 最高价 -->
            <el-table-column prop="maxPrice" style="color: black" label="最高价">
              <template slot-scope="scope">
                <span>{{ scope.row.maxPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 均价 -->
            <el-table-column prop="avgPrice" label="均价">
              <template slot-scope="scope">
                <span>{{ scope.row.avgPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!--较昨日均价涨幅-->
            <el-table-column prop="priceGrowth" label="较昨日均价涨幅">
              <template slot-scope="scope">
                <span v-if="toFiveNum(scope.row)">-</span>
                <span v-else :class="scope.row.priceGrowth>0?'c-red':'c-green'">{{
                    scope.row.priceGrowth || '-' | toFixedNum
                  }}</span>
              </template>
            </el-table-column>
            <!-- 销售量 (数量)-->
            <el-table-column prop="salesNum" label="销售量 (数量)">
              <template slot-scope="scope">
                <span>{{ scope.row.salesNum | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 销售量 (公斤)-->
            <el-table-column prop="salesWeight" label="销售量 (公斤)">
              <template slot-scope="scope">
                <span>{{ scope.row.salesWeight | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 较昨日销量涨幅 -->
            <el-table-column prop="salesGrowth" label="较昨日销量涨幅">
              <template slot-scope="scope">
                <span v-if="toFiveNum(scope.row)">-</span>
                <span v-else
                      :class="scope.row.salesGrowth>0?'c-red':'c-green'">{{
                    scope.row.salesGrowth || '-' | toFixedNum
                  }}</span>
              </template>
            </el-table-column>
            <!--价格取值方式-->
            <el-table-column prop="valueType" label="价格取值方式">
              <template slot-scope="scope">
                <span :style="{color:statusColor(scope.row)}">{{ scope.row.valueType | toValueType }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            stripe
            :data="tableData"
            header-row-class-name="table-header"
            v-loading="loading"
            v-if="$getPermissionByRole('18')&&spanIndex=='2'"
            class="table-data-list"
          >

            时间
            <el-table-column v-if="spanIndex=='2'" prop="time" label="时间"></el-table-column>
            货品名称
            <el-table-column prop="goodsName" label="货品名称"></el-table-column>
            <!-- 所属大类 -->
            <el-table-column prop="goodsType" label="所属大类"></el-table-column>
            <!-- 所属类别 -->
            <el-table-column prop="categoryName" label="所属类别"></el-table-column>
            <!-- 规格 -->
            <el-table-column prop="specifications" label="规格"></el-table-column>
            <!-- 产地 -->
            <el-table-column prop="sourceName" label="产地"></el-table-column>

            <!-- 最低价 -->
            <el-table-column prop="minPrice" style="color: black" label="最低价">
              <template slot-scope="scope">
                <span>{{ scope.row.minPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 最高价 -->
            <el-table-column prop="maxPrice" style="color: black" label="最高价">
              <template slot-scope="scope">
                <span>{{ scope.row.maxPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 均价 -->
            <el-table-column prop="avgPrice" label="均价">
              <template slot-scope="scope">
                <span>{{ scope.row.avgPrice | toNaN }}</span>
              </template>
            </el-table-column>
            <!--较昨日均价涨幅-->
            <el-table-column prop="priceGrowth" label="较昨日均价涨幅">
              <template slot-scope="scope">
                <span v-if="toFiveNum(scope.row)">-</span>
                <span v-else :class="scope.row.priceGrowth>0?'c-red':'c-green'">{{
                    scope.row.priceGrowth || '-' | toFixedNum
                  }}</span>
              </template>
            </el-table-column>
            <!-- 销售量 (数量)-->
            <el-table-column prop="salesNum" label="销售量 (数量)">
              <template slot-scope="scope">
                <span>{{ scope.row.salesNum | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 销售量 (公斤)-->
            <el-table-column prop="salesWeight" label="销售量 (公斤)">
              <template slot-scope="scope">
                <span>{{ scope.row.salesWeight | toNaN }}</span>
              </template>
            </el-table-column>
            <!-- 较昨日销量涨幅 -->
            <el-table-column prop="salesGrowth" label="较昨日销量涨幅">
              <template slot-scope="scope">
                <span v-if="toFiveNum(scope.row)">-</span>
                <span v-else
                      :class="scope.row.salesGrowth>0?'c-red':'c-green'">{{
                    scope.row.salesGrowth || '-' | toFixedNum
                  }}</span>
              </template>
            </el-table-column>
            <!--价格取值方式-->
            <el-table-column prop="valueType" label="价格取值方式">
              <template slot-scope="scope">
                <span :style="{color:statusColor(scope.row)}">{{ scope.row.valueType | toValueType }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="total"
            :page-size="params.size"
            :current-page="params.current_page"
            @current-change="handleCurrentChange"
          ></el-pagination>
          <!-- 市场行情弹框 -->
          <el-dialog
            title="添加行情"
            :visible.sync="dialogFormVisible"
            width="800px"
            :before-close="dialogBeforeClose"
            @close="defaultData(form)"
            v-if="dialogFormVisible"
          >
            <div>
              <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
                <el-row>
                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="市场" prop="mid">
                        <el-select @change="handleSelectFollow" v-model="form.mid" placeholder="请选择市场"
                                   style="width:200px;">
                          <el-option
                            v-for="item in followMarketList"
                            :key="item.mid"
                            :label="item.marketName"
                            :value="item.mid"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="时间" prop="importTime">
                        <el-date-picker
                          v-model="form.importTime"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          type="date"
                          placeholder="选择开始日期时间"
                          style="width: 200px"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content tree_goods_type">
                      <el-form-item label="货品类别" prop="categoryId" class="goosType">
                        <el-input
                          v-model.trim="form.categoryName"
                          :maxlength="GLOBAL.maxlength"
                          placeholder="请选择货品类别"
                          style="width: 200px"
                          @focus="showTreeGoodType('set')"
                        ></el-input>
                        <el-dialog
                          class="tree_type"
                          :visible.sync="TreeGoodType"
                          v-if="TreeGoodType"
                          title="货品分类"
                          :modal="false"
                        >
                          <el-tree
                            :data="treeData"
                            node-key="categoryId"
                            :props="defaultProps"
                            :highlight-current="true"
                            show-checkbox
                            check-strictly
                            @check-change="handleFormClick"
                            ref="treeForm"
                          ></el-tree>
                        </el-dialog>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="货品名称" prop="goodsName">
                        <el-input
                          v-model.trim="form.goodsName"
                          placeholder="请输入货品名称"
                          style="width: 200px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="最低价" prop="minPrice">
                        <el-input
                          type="number"
                          v-model.trim="form.minPrice"
                          :maxlength="GLOBAL.maxlength"
                          placeholder="请输入最低价"
                          style="width: 200px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="最高价" prop="maxPrice">
                        <el-input
                          type="number"
                          v-model.trim="form.maxPrice"
                          :maxlength="GLOBAL.maxlength"
                          placeholder="请输入最高价"
                          style="width: 200px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="均价" prop="averagePrice">
                        <el-input
                          type="number"
                          v-model.trim="form.averagePrice"
                          :maxlength="GLOBAL.maxlength"
                          placeholder="请输入均价"
                          style="width: 200px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="较昨日均价涨幅" prop="priceGrowth">
                        <el-input
                          type="number"
                          v-model.trim="form.priceGrowth"
                          placeholder="请输入较昨日均价涨幅"
                          style="width: 200px"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="销量" prop="salesNum">
                        <el-input
                          type="number"
                          v-model.trim="form.salesNum"
                          placeholder="请输入销量"
                          style="width: 200px"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item label="较昨日销量涨幅" prop="minPrice">
                        <el-input
                          type="number"
                          v-model.trim="form.salesGrowth"
                          placeholder="请输入较昨日销量涨幅"
                          style="width: 200px"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancel" size="small">取 消</el-button>
              <el-button
                type="primary"
                @click="addMarketQuotations"
                size="small"
                :loading="buttonLoading"
              >确 定
              </el-button>
            </div>
          </el-dialog>
          <!--添加关注市场-->
          <el-dialog
            width="600px"
            :visible.sync="focusMarketFormVisible"
            @close="cancel"
            title="添加关注市场">
            <div>
              <ul>
                <div class="textRight" v-if="$getPermissionByRole('34')">
                  <el-button type="success" @click="addMarket">添加关注市场</el-button>
                </div>
                <div v-for="(market,index) in addMarketList"
                     class="market-list">
                  <el-select
                    class="m-r-20"
                    style="width: 240px"
                    v-model="market.mid"
                    @change="handleSelect(market,index)"
                    placeholder="请选择市场">
                    <el-option
                      v-for="item in mktList"
                      :key="item.mid"
                      :label="item.marketName"
                      :value="item.mid"
                    ></el-option>
                  </el-select>
                  <span v-if="market.mid&&market.mid!='dcc09f2d90d24ed792c36fdf3af33b96'" @click="deleteMarket(market)"
                        class="click">
                <i v-if="market.mid!='dcc09f2d90d24ed792c36fdf3af33b96'" class="iconfont f-s-20">&#xe611;</i>
              </span>
                </div>
                <div class="textCenter">
                  <el-button class="m-r-20 btn-focus" @click="cancel">取消</el-button>
                  <el-button class="m-l-20 btn-focus" type="success" @click="setMarketList">保存</el-button>
                </div>

              </ul>


            </div>


          </el-dialog>
        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script>
import {ontherMarkets} from "../api";
import "@/style/ontherMarkets/ontherMarkets.scss";
import XLSX from 'xlsx';
import {createNamespacedHelpers, mapState as state} from "vuex";

const {mapState, mapMutations} = createNamespacedHelpers("otherMarket");

const excelKeyList = [
  {name: '品类', key: 'categoryName'},
  {name: '均价', key: 'averagePrice'},
  {name: '市场', key: 'marketName'},
  {name: '时间', key: 'importTime'},
  {name: '最低价', key: 'minPrice'},
  {name: '最高价', key: 'maxPrice'},
  {name: '货品名称', key: 'goodsName'},
  {name: '较昨日均价涨幅', key: 'priceGrowth'},
  {name: '较昨日销量涨幅', key: 'salesGrowth'},
  {name: '销量', key: 'salesNum'},
];


export default {
  name: "stallownerManagement",
  data() {
    return {
      spanIndex: 1,
      indexList: 0,//选中的市场
      indexMarket: {},
      pricingMethod: [
        {key: '', value: '全部'},
        {key: 'system', value: '系统数据（全部）'},
        {key: 'manual', value: '人工采集'},
        {key: 'gear', value: '系统数据（部分档口）'},
      ],//价格取值方式
      CategoryTree: [],//所属大类

      followMarketList: [],  //  已关注市场列表
      addMarketList: [], //  添加关注市场列表
      focusMarketFormVisible: false,
      loading: true,
      focusMarketParams: {
        mid: '',
        marketName: ''
      },
      //列表数据
      tableData: [],
      arr: [],
      exportList: [],//导出数据
      timeQuantum: [],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 8.64e7;
        }
      },
      mktList: [
        {
          mid: "dcc09f2d90d24ed792c36fdf3af33b96",
          marketName: process.env.MARKET_NAME
        }
      ],
      params: {
        valueType: '',//取值类型
        goodsName: '',
        startTime: "", //时间
        endTime: "",
        mid: "", //范围id//江南市场
        categoryId: "", //货品类别id
        goodsTypId: '',//货品大类
        categoryName: "",
        minPrice: "",
        maxPrice: "",

        page_size: 10000,
        size: 10,
        current_page: 1
      },


      form: {
        importTime: "",
        mid: "",
        marketName: '',
        goodsName: '',
        categoryId: "",
        categoryName: "",
        averagePrice: "",
        maxPrice: "",
        minPrice: "",
        salesNum: '',
        salesGrowth: '',
        priceGrowth: ''
      },
      total: 0,
      dialogFormVisible: false,
      buttonLoading: false,
      TreeGoodType: false,
      showTreeType: false,
      inputText1: '',
      inputText2: '',
      count: 0,
      countForm: 0,
      rules: {
        mid: [{required: true, message: "请选择市场", trigger: "blur"}],
        importTime: [{required: true, message: "请选择时间", trigger: "blur"}],
        goodsName: [{required: true, message: "请输入货品名称", trigger: "blur"}],
        categoryId: [{required: true, message: '请选择货品品类'}],
        salesNum: [{required: true, message: "请输入销量", trigger: "blur"}],
        maxPrice: [{required: true, message: "请输入最高价格", trigger: "blur"}],
        minPrice: [{required: true, message: "请输入最低价格", trigger: "blur"}],
        averagePrice: [{required: true, message: "请输入均价", trigger: "blur"}],
        salesGrowth: [{required: true, message: "请输入销量较昨日涨幅", trigger: "blur"}],
        priceGrowth: [{required: true, message: "请输入价格较昨日涨幅", trigger: "blur"}],
      },
      // 货品品类
      treeData: [
        {
          categoryId: 0,
          categoryName: "商品分类",
          disabled: true,
          childrens: []
        }
      ],
      defaultProps: {
        children: "childrens",
        label: "categoryName"
      },
      today: '',//当天时间
      ThreeMonths: '',//前推3个月的时间
    };
  },
  computed: {
    ...mapState({
      screenForm: (state) => state.screenForm,
    }),

  },
  watch: {
    inputText1(val) {
      this.$refs.treeParams.filter(val);
    },
    inputText2(val) {
      this.$refs.treeParams.filter(val);
    },
  },
  filters: {
    toFixedNum(val) {
      if (val == "NaN") {
        val = "0";
      }
      return val === "-" ? '-' : (Number(val || 0) * 100).toFixed(2) + '%'
    },
    toNaN(val) {
      if (val === '' || Number(val) === 0) {
        return '-'
      } else {
        return val
      }
    },
    toFixe(val) {
      val.avgPrice || 0
      return (val.avgPrice || 0).toFixed(2)
    },
    toValueType(val) {
      if (val !== '') {
        return val == 'system' ? '系统数据' : val == 'manual' ? '人工采集' : '档口数据'
      }
    },

  },
  created() {
    // this.getList();
    this.getGoodType();
    this.getMarketList();
    // this.getFocusMarketList();
    this.getThreeMonths();
  },
  methods: {
    ...mapMutations({
      setScreenForm: "SET_SCREEN_FORM",
    }),
    toFiveNum(val) {

      let minPrice = val.minPrice || 0;
      let maxPrice = val.maxPrice || 0;
      let avgPrice = val.avgPrice || 0;
      let priceGrowth = val.priceGrowth || 0;
      let salesGrowth = val.salesGrowth || 0;
      if (!Number(minPrice) && !Number(maxPrice) && !Number(avgPrice) && !Number(priceGrowth) && !Number(salesGrowth)) {

        return true;
      }
    },
    statusColor(val) {
      if (val.valueType === 'gear') {
        return '#ff9900'
      }
      return val.valueType === "system" ? "#3399ff" : "#00cc66";
    },
    //
    setSpanIndex(index) {
      this.spanIndex = index;
      if (index == 2) {
        var dd = new Date();
        dd.setDate(dd.getDate() - 1); //获取AddDayCount天后的日期
        var y = dd.getFullYear();
        var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1); //获取当前月份的日期，不足10补0
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
        dd.setDate(dd.getDate() - 7); //获取AddDayCount天后的日期
        var y1 = dd.getFullYear();
        var m1 = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1); //获取当前月份的日期，不足10补0
        var d1 = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
        let yesDay = y + "-" + m + "-" + d;
        let sevDay = y1 + "-" + m1 + "-" + d1;
        this.timeQuantum = [sevDay, yesDay];
        this.params.startTime = sevDay;
        this.params.endTime = yesDay;
      }
      this.params.current_page = 1;
      this.getList();
    },
    toPush(data) {
      this.$router.push({
        name: data,
        params: {
          market: JSON.stringify(this.indexMarket)
        }
      })
    },
    //获取最近三个月的时间
    getThreeMonths() {
      var dd = new Date();
      dd.setDate(dd.getDate() - 90); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1); //获取当前月份的日期，不足10补0
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
      this.ThreeMonths = y + "-" + m + "-" + d;

      //今天
      var day2 = new Date();
      day2.setTime(day2.getTime());
      var s2 = day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
      this.today = s2;
      this.timeQuantum[0] = s2;
      this.timeQuantum[1] = s2;
      // this.search();
      this.params.startTime = s2;
      this.params.endTime = s2;
      this.getFocusMarketList();

    },
    //excel数据导出
    exportToExcel() {
      require.ensure([], async () => {
        const {export_json_to_excel} = require("@/excel/Export2Excel");
        let tHeader = [];
        let filterVal = [];
        if (this.spanIndex == '2') {
          tHeader = [
            "时间",
            "货品名",
            "所属大类",
            "所属类别",
            "规格",
            "产地",
            "最低价",
            "最高价",
            "均价",
            "较昨日均价涨幅",
            "销售量(数量)",
            "销售量(公斤)",
            "较昨日销售涨幅",
            "价格取值方式",
          ];

          filterVal = [
            "time",
            "goodsName",
            "goodsType",
            "categoryName",
            "specifications",
            "sourceName",
            "minPrice",
            "maxPrice",
            "avgPrice",
            "priceGrowth",
            "salesNum",
            "salesWeight",
            "salesGrowth",
            "valueType",
          ];
        } else {
          tHeader = [
            "货品名",
            "所属大类",
            "所属类别",
            "规格",
            "产地",
            "最低价",
            "最高价",
            "均价",
            "较昨日均价涨幅",
            "销售量(数量)",
            "销售量(公斤)",
            "较昨日销售涨幅",
            "价格取值方式",
          ];

          filterVal = [
            "goodsName",
            "goodsType",
            "categoryName",
            "specifications",
            "sourceName",
            "minPrice",
            "maxPrice",
            "avgPrice",
            "priceGrowth",
            "salesNum",
            "salesWeight",
            "salesGrowth",
            "valueType",
          ];
        }


        //当筛选时间段为空的时候，默认导出近三个月的数据
        if (this.params.startTime == '' && this.params.endTime == '') {
          this.$message({
            message: '导出数据过大，默认导出最近三个月的数据',
            type: 'warning'
          });
          this.params.startTime = this.ThreeMonths;
          this.params.endTime = this.today;

        }
        let params = {
          ...this.params,
          ...{current_page: 1, page_size: 10000},
        };

        try {
          // let res = await this.GET_INCOMING_NETWEIGHT_QUERY(params);
          // let incomTrainsList = res.data.results.list

          let incomTrainsList = this.exportList.map(item => {
            return {
              ...item,
              valueType: this.toValueType(item.valueType),
              salesGrowth: this.toFixedNum(item.salesGrowth),
              priceGrowth: this.toFixedNum(item.priceGrowth),
            }
          })
          const data = this.formatJson(filterVal, incomTrainsList);
          export_json_to_excel(tHeader, data, `${this.params.startTime.split('-').join('')}-${this.params.endTime.split('-').join('')}${this.indexMarket.marketName}货品价格数据`);
        } catch (e) {
          alert("获取接口失败");
        }
      });
    },
    toFixedNum(val) {
      if (val == "NaN") {
        val = "0";
      }
      return val === "-" ? '-' : (Number(val || 0) * 100).toFixed(2) + '%'
    },
    toFixe(val) {
      val.avgPrice || 0
      return parseInt(val.avgPrice || 0)
    },
    toValueType(val) {
      if (val !== '') {
        return val == 'system' ? '系统数据' : val == 'manual' ? '人工采集' : '档口数据'
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    changeTabMarket(item) {

      this.indexMarket = this.addMarketList[item.index];
      this.params.mid = this.addMarketList[item.index].mid;
      this.indexList = item.index;
      this.params.current_page = 1;
      this.loading = true;
      this.getList();
    },
    handleSelectFollow(mid) {
      let {marketName} = this.mktList.find(item => mid === item.mid);
      this.form.marketName = marketName;
    },


    //删除市场
    deleteMarket(market) {
      ontherMarkets.DEL_FOLLOW_MARKET(market).then(res => {
        let {error_code, results} = res.data, type = error_code === 200 ? 'success' : "error";
        this.$message({
          type,
          message: results
        });
        if (error_code === 200) this.getFocusMarketList();
      })
    },

    //改变市场列表
    setMarketList() {
      let followMarketList = [];
      this.addMarketList.map(item => {
        if (item.mid) {
          followMarketList.push(item)
        }
      });
      if (!followMarketList.length) {
        return this.$message({
          type: 'error',
          message: '请选择市场'
        })
      }
      // let obj = {};
      // followMarketList = followMarketList.reduce((cur, next) => {
      //   obj[next.mid] ? "" : obj[next.mid] = true && cur.push(next);
      //   return cur;
      // }, []);


      let params = {
        followMarketList,
      };


      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      ontherMarkets.ADD_FOLLOW_MARKET(params).then(res => {
        loading.close();
        let {error_code, results} = res.data, type = error_code === 200 ? 'success' : "error";
        this.$message({
          type,
          message: results
        });
        if (error_code === 200) this.getFocusMarketList();
        this.focusMarketFormVisible = false;
      }).catch(err => {
        // this.$message({
        //   type: 'error',
        //   message: err
        // });
        loading.close()
      })
    },

    // 添加市场
    addMarket() {
      let mid = '', marketName = '', abbreviation;
      this.addMarketList.unshift({
        mid, marketName, abbreviation,
        OperatingType: 'add'
      })
    },

    //  选择市场
    handleSelect(market, index) {
      let {marketName, abbreviation} = this.mktList.find(item => market.mid === item.mid);
      this.addMarketList[index].marketName = marketName;
      this.addMarketList[index].abbreviation = abbreviation;
    },


    //  获取关注市场
    getFocusMarketList() {
      ontherMarkets.GET_FOLLOW_MARKET().then(res => {
        this.followMarketList = res.data.results || [];
        this.addMarketList = JSON.parse(JSON.stringify(this.followMarketList));
        this.params.mid = this.addMarketList[0].mid;

        this.indexMarket = this.addMarketList[0];
        this.getList()
      })
    },


    //开始导入
    importExcel() {
      let obj = event.target, wb, rABS = false;
      if (!obj.files) {
        return;
      }
      const f = obj.files[0];
      let reader = new FileReader();
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
      reader.onload = (e) => {
        let data = e.target.result;
        if (rABS) {
          wb = XLSX.read(btoa(this.fixData(data)), {//手动转化
            type: 'base64'
          });
        } else {
          wb = XLSX.read(data, {
            type: 'binary'
          });
        }
        /**
         * wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
         * wb.Sheets[Sheet名]获取第一个Sheet的数据
         */
        let excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        this.resExcelData(excelJson)

      };

    },

    //文件流转BinaryString
    fixData(data) {
      var o = "",
        l = 0,
        w = 10240;
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w +

        w)));
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },

    //  处理excel数据
    resExcelData(data) {
      let excelData = [...data],
        keyLen = excelKeyList.length,
        dataLen = excelData.length;
      let arrData = [];
      for (let j = 0; j < dataLen; j++) {
        let rowData = {},
          item = excelData[j];
        for (let i = 0; i < keyLen; i++) {
          let key = excelKeyList[i].key,
            name = excelKeyList[i].name;

          if ((name === '市场' || name === '品类' || name === '货品名称') && !item[name]) {
            return this.$message({
              type: 'error',
              message: `请填写正的${name}`
            })
          } else if (item[name] === '' && item[name] === undefined) {
            rowData[key] = '-'
          } else {
            if (name === '较昨日均价涨幅' || name === '较昨日销量涨幅') {
              rowData[key] = isNaN(item[name]) ? item[name] : item[name].toFixed(4)
            } else {
              rowData[key] = item[name];
            }
          }
        }
        arrData.push(rowData)
      }
      this.setImportPrices(arrData)
    },

    setImportPrices(importPricesList) {
      let parasm = {
        importPricesList,
      };
      ontherMarkets.ADD_IMPORT_PRICES(parasm).then(res => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.getList()
      })
    },

    GET_INCOMING_NETWEIGHT_QUERY(params) {
      return new Promise((resolve, reject) => {
        ontherMarkets.GET_IMPORT_PRICES_LIST(params).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },


    //获取列表数据
    getList() {
      this.tableData = [];
      this.arr = [];
      if (this.spanIndex == 1) {

        ontherMarkets.DNP_GET_MARKET_GOODS_PRICE_LIST({...this.params}).then(response => {
          if (response.data.status === "Y") {
            // this.tableData = response.data.results.list;

            let list = response.data.results || [];
            let arr = list.sort(
              function compareFunction(param1, param2) {

                return param1.goodsName.localeCompare(param2.goodsName, "zh");
              }
            );

            this.dataHandling(arr)
            this.total = response.data.results.length;
            this.loading = false;
          }
        }).catch(err => {
          this.loading = false;
        });
      } else {
        ontherMarkets.DNP_GET_MARKET_GOODS_HISTORY_PRICE_LIST(this.params).then(response => {
          if (response.data.status === "Y") {
            // this.tableData = response.data.results.list;

            let list = response.data.results || [];
            list = list.sort(
              function compareFunction(param1, param2) {

                return param1.goodsName.localeCompare(param2.goodsName, "zh");
              }
            );
            this.dataHandling(list)
            this.total = response.data.results.length;
            this.loading = false;
          }
        }).catch(err => {
          this.loading = false;
        });
      }

    },

    dataHandling(list) {

      let arrLength = list.length; // 数组长度
      this.exportList = list;
      let num = 10;  // 每页显示 10 条
      let index = 0;
      for (let i = 0; i < arrLength; i++) {
        if (i % num === 0 && i !== 0) { // 可以被 10 整除
          this.arr.push(list.slice(index, i));
          index = i;

        }

        if ((i + 1) === arrLength) {

          this.arr.push(list.slice(index, (i + 1)));

        }
      }

      this.tableData = this.arr[this.params.current_page - 1]

    },

    getMarketList() {
      ontherMarkets.getMarketList().then(response => {
        this.mktList = response.data.results;
      });
    },

    //搜索
    search() {
      this.params.current_page = 1;
      if (this.params.minPrice > this.params.maxPrice) {
        this.$message({
          message: "请输入正确的价格区间",
          type: "warning"
        });
        this.params.minPrice = "";
        this.params.maxPrice = "";

        return false;
      }

      if (this.timeQuantum == null) {
        this.params.startTime = "";
        this.params.endTime = "";
      } else {
        this.params.startTime = this.timeQuantum[0];
        this.params.endTime = this.timeQuantum[1];
      }
      if (this.params.categoryName == '') {
        this.params.categoryId = '';
      }
      this.getList();
    },
    //选择筛选时间

    setTime(e) {
      if (this.timeQuantum !== null) {
        let time = new Date(this.timeQuantum[1]) - new Date(this.timeQuantum[0])
        if (time / (1000 * 60 * 60 * 24) > 90) {
          this.timeQuantum[1] = '';
          return this.$message({
            message: '筛选时间段不能大于三个月',
            type: 'warning'
          });
        }
        let arr = this.timeQuantum[1].split('-');
        let arr1 = this.timeQuantum[0].split('-');

      }


    },
    //分页
    handleCurrentChange(val) {
      this.params.current_page = val;
      this.tableData = this.arr[val - 1]

      // this.getList();
    },

    // 获取树类型结构
    getGoodType() {
      ontherMarkets.getGoodCategoryTree().then(response => {
        this.CategoryTree = response.data.results;
        let CategoryTree = response.data.results;
        CategoryTree.forEach(item => {
          if (item.categoryName !== "其他") {
            item.disabled = true;
            item.childrens.forEach(categoryItem => {
              categoryItem.disabled = true;
            });
          }
        });

        this.treeData[0].childrens = CategoryTree;
      });
    },

    showTreeGoodType(type) {
      if (type === "search") {
        this.showTreeType = true;
      } else {
        this.TreeGoodType = true;
      }
      this.$nextTick(() => {
        this.$refs.inp.$el.querySelector('input').focus();
      })
    },

    //   确定添加市场行情
    addMarketQuotations() {

      this.$refs.ruleForm.validate(valid => {

        if (valid) {
          if (this.form.maxPrice < this.form.minPrice) {
            this.$message({
              message: "请输入正确的品类价格",
              type: "warning"
            });
          } else {
            this.setImportPrices([this.form]);
            this.defaultData()
          }
        }
      });
    },

    dialogBeforeClose(done) {
      this.dialogFormVisible = false;
      this.defaultData();
    },

    // 取消  关闭添加市场弹框
    cancel() {
      // this.defaultData(this.form);
      this.getFocusMarketList()
      this.focusMarketFormVisible = false;
    },

    defaultData(form) {
      this.form = {};
      this.dialogFormVisible = false;
    },

    filterNode(value, data) {

      if (!value) return true;
      return data.categoryName.indexOf(value) !== -1;
    },

    handleClick(data, checked, node) {
      if (checked) {
        this.params.categoryId = data.categoryId;
        this.params.categoryName = data.categoryName;
      }

      this.count++;
      if (this.count % 2 === 0) {
        if (checked) {
          this.$refs.treeParams.setCheckedNodes([]);
          this.$refs.treeParams.setCheckedNodes([data]);
          this.params.categoryId = data.categoryId;
          this.params.categoryName = data.categoryName;
          //交叉点击节点
        } else {
          this.$refs.treeParams.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      this.showTreeType = false;
    },

    handleFormClick(data, checked, node) {
      if (checked) {
        this.form.categoryId = data.categoryId;
        this.form.categoryName = data.categoryName;
      }

      this.countForm++;
      if (this.countForm % 2 === 0) {
        if (checked) {
          this.$refs.treeForm.setCheckedNodes([]);
          this.$refs.treeForm.setCheckedNodes([data]);
          this.form.categoryId = data.categoryId;
          this.form.categoryName = data.categoryName;
          //交叉点击节点
        } else {
          this.$refs.treeForm.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      }
      this.TreeGoodType = false;
    }
  }
};
</script>

<style lang="scss" scoped>
> > > .el-table th {
  background: #E8E8E8;
}

.ontherMarkets {
  background-color: #fff;
}

.file-box {
  width: 140px;
  position: relative;
}

.imFile {
  opacity: 0;
  width: 140px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.market-list {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.btn-focus {
  margin-top: 10px;
  width: 150px;
}

.other-market-list {
  width: 80%;
  background-color: #eee;
  margin: 0 auto;

  ul {
    list-style: none;
    cursor: pointer;
  }
}

.market-li {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.active {
  background-color: #05AE09;
  color: #fff;
}

.c-red {
  color: red;
}

.c-green {
  color: green;
}

.tree_type {
  width: 200px !important;
}

.left-box {
  margin: 1rem;
  width: 240px;
  line-height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 50px;
    background-color: #fff;
    text-align: center;
    font-weight: bold;
    border: 1px solid #d1d1d1;
  }

  span:nth-child(2) {
    //border-left: 0;
  }

  .span-active {
    background-color: #05AE09;
    color: #fff;
    border: 1px solid #05AE09;
  }
}
</style>
