export default [
    {
        path:'recharge',
        name:'recharge',
        component: () => import('./view/index.vue'),
        meta: {
          title: "充值服务"
        }
    }
]
