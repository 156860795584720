import Vue from 'vue';
import Vuex from 'vuex';
import {staff} from './module/staff/api'
import ElTableInfiniteScroll from 'el-table-infinite-scroll';

import {store as approachPrepare} from "./module/approachPrepare/store";
import {store as purchasingInformation} from "./module/purchasingInformation/store";
import {store as IntegralManagement} from "./module/IntegralManagement/store";
import {store as otherMarket} from "./module/otherMarket/store";
import {store as entranceAndExitCharges} from "./module/entranceAndExitCharges/store";


Vue.use(Vuex);
Vue.use(ElTableInfiniteScroll);
export default new Vuex.Store({
  state: {
    //  本地市场id
    marketId: 'dcc09f2d90d24ed792c36fdf3af33b96',
    permission: {
      role: undefined,
      list: []
    }
  },
  //! 修改状态
  mutations: {
    SET_ROLE_PERMISSION: (state, {permissionList, role}) => {
      state.permission.list = permissionList;
      state.permission.role = role;
      window.localStorage.setItem('permission', JSON.stringify(state.permission));
    }
  },
  //! 异步修改状态
  actions: {
    GET_ROLE_PERMISSION: ({commit, state}, {uid}) => {
      if (uid === 'admin') {
        let permissionList = [], role = 'admin';
        commit('SET_ROLE_PERMISSION', {permissionList, role});
        return {role, permissionList};
      }
      return new Promise((resolve, reject) => {
        staff.GET_DNPUSER_PERMISSIONLIST({uid}).then(res => {
          let {results: permissionList} = res.data;
          let role = 'staff';
          commit('SET_ROLE_PERMISSION', {permissionList, role});
          resolve({role, permissionList});
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  modules: {
    approachPrepare,
    purchasingInformation,
    IntegralManagement,
    otherMarket,
    entranceAndExitCharges,
  }
})
