export default [
  {
    path: "iframeJmreport",
    name: "iframeJmreport",
    component: () => import('./views/iframeJmreport'),
    meta: {
      title: "报表"
    }
  }
]
