var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productSettings" },
    [
      _c(
        "div",
        { staticClass: "flex-back m-l-10 m-b-10 p-t-10" },
        [_c("el-page-header", { on: { back: _vm.goBack } })],
        1
      ),
      _c("div", { staticClass: "headTiter b-c-f p-t-20 p-l-20 p-b-20" }, [
        _c("label", [
          _vm._v(" 市场价格取值设置-" + _vm._s(_vm.market.marketName) + " "),
        ]),
      ]),
      _c("div", { staticClass: "table-productSettings content" }, [
        _c("div", { staticClass: "redio-box m-l-20 m-t-10 m-r-20" }, [
          _c(
            "span",
            [
              _c("span", { staticClass: "p-r-10" }, [_vm._v("排序")]),
              _c(
                "el-radio",
                {
                  attrs: { label: "goodsName" },
                  on: { change: _vm.search },
                  model: {
                    value: _vm.params.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "type", $$v)
                    },
                    expression: "params.type",
                  },
                },
                [_vm._v("按名称")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "goodsType" },
                  on: { change: _vm.search },
                  model: {
                    value: _vm.params.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "type", $$v)
                    },
                    expression: "params.type",
                  },
                },
                [_vm._v("按货品类型")]
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "m-r-10" },
            [
              _vm.$getPermissionByRole("116")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = true
                        },
                      },
                    },
                    [_vm._v("添加关注货品")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {},
          [
            _vm.$getPermissionByRole("35")
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "table-data-list",
                    attrs: {
                      stripe: "",
                      data: _vm.tableData,
                      "header-row-class-name": "table-header",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "goodsName", label: "货品名称" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.bool
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.goodsName)),
                                    ])
                                  : _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品名称" },
                                      model: {
                                        value: scope.row.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "goodsName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.goodsName",
                                      },
                                    }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2884270950
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "marketName", label: "所属大类" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.bool
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.goodsType)),
                                    ])
                                  : _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请选择所属大类",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.sel(scope.row)
                                          },
                                        },
                                        model: {
                                          value: scope.row.goodsType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "goodsType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.goodsType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "水果",
                                            value: "水果",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "蔬菜",
                                            value: "蔬菜",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        273609570
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "class-name": "",
                        prop: "categoryName",
                        label: "所属类别",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.bool
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.categoryName)),
                                    ])
                                  : _c("el-input", {
                                      staticStyle: { width: "auto" },
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.showTreeGoodType(
                                            "search",
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.categoryName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "categoryName",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.categoryName",
                                      },
                                    }),
                                scope.row.editShow
                                  ? _c(
                                      "el-dialog",
                                      {
                                        staticClass: "tree_type",
                                        attrs: {
                                          visible: scope.row.editShow,
                                          title: "货品分类",
                                          modal: false,
                                        },
                                        on: {
                                          "update:visible": function ($event) {
                                            return _vm.$set(
                                              scope.row,
                                              "editShow",
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          ref: "inp",
                                          staticStyle: { width: "auto" },
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.inputText,
                                            callback: function ($$v) {
                                              _vm.inputText = $$v
                                            },
                                            expression: "inputText",
                                          },
                                        }),
                                        _c("el-tree", {
                                          ref: "treeParams",
                                          attrs: {
                                            data: _vm.treeData,
                                            "node-key": "categoryId",
                                            props: _vm.defaultProps,
                                            "highlight-current": true,
                                            "filter-node-method":
                                              _vm.filterNode,
                                            "default-expand-all": "",
                                            "show-checkbox": "",
                                            "check-strictly": "",
                                            "check-on-click-node": true,
                                          },
                                          on: {
                                            "check-change": _vm.handleClick,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3840846476
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "categoryName", label: "规格" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.bool
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.specifications)),
                                    ])
                                  : _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入货品规格" },
                                      model: {
                                        value: scope.row.specifications,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "specifications",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row.specifications",
                                      },
                                    }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3188408611
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "categoryName", label: "产地" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.bool
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.sourceName)),
                                    ])
                                  : _c("el-cascader", {
                                      attrs: {
                                        "show-all-levels": _vm.bool,
                                        props: _vm.props,
                                        separator: "",
                                        filterable: "",
                                        options: _vm.options,
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getCheckedNodesTow(
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.cascade,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "cascade", $$v)
                                        },
                                        expression: "scope.row.cascade",
                                      },
                                    }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1627991640
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "valueType", label: "价格取值方式" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: _vm.statusColor(scope.row),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("value")(scope.row.valueType)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1388904838
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "400" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.del(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPrice(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("价格设置")]
                                ),
                                !scope.row.bool
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "btn",
                                        staticStyle: { color: "#05AE09" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.save(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("保存")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        251419586
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, prev, pager, next",
                total: _vm.total,
                "page-size": _vm.params.page_size,
                "current-page": _vm.params.current_page,
              },
              on: { "current-change": _vm.handleCurrentChange },
            }),
          ],
          1
        ),
      ]),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "添加关注货品",
                visible: _vm.dialogFormVisible,
                width: "50vw",
                "before-close": _vm.dialogBeforeClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
                close: function ($event) {
                  return _vm.defaultData(_vm.form)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: { model: _vm.form, "label-width": "120px" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "货品名称", prop: "mid" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: { placeholder: "请输入货品名称" },
                                      model: {
                                        value: _vm.form.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "goodsName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "所属大类",
                                      prop: "importTime",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请选择所属大类",
                                        },
                                        on: { change: _vm.selectGoodsTypId },
                                        model: {
                                          value: _vm.form.goodsTypId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "goodsTypId",
                                              $$v
                                            )
                                          },
                                          expression: "form.goodsTypId",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "水果",
                                            value: "308",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "蔬菜", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid-content tree_goods_type content",
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "goosType",
                                    attrs: {
                                      label: "所属货品类别",
                                      prop: "categoryId",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        maxlength: _vm.GLOBAL.maxlength,
                                        placeholder: "请选择所属货品类别",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.showTreeGoodType("set")
                                        },
                                      },
                                      model: {
                                        value: _vm.form.categoryName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "categoryName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.categoryName",
                                      },
                                    }),
                                    _vm.TreeGoodType
                                      ? _c(
                                          "el-dialog",
                                          {
                                            staticClass: "tree_type",
                                            attrs: {
                                              visible: _vm.TreeGoodType,
                                              title: "货品分类",
                                              modal: false,
                                            },
                                            on: {
                                              "update:visible": function (
                                                $event
                                              ) {
                                                _vm.TreeGoodType = $event
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "inp1",
                                              staticStyle: {
                                                width: "auto",
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.inputText1,
                                                callback: function ($$v) {
                                                  _vm.inputText1 = $$v
                                                },
                                                expression: "inputText1",
                                              },
                                            }),
                                            _c("el-tree", {
                                              ref: "treeForm",
                                              attrs: {
                                                data: _vm.treeData,
                                                "node-key": "categoryId",
                                                props: _vm.defaultProps,
                                                "highlight-current": true,
                                                "filter-node-method":
                                                  _vm.filterNode,
                                                "default-expand-all": "",
                                                "show-checkbox": "",
                                                "check-strictly": "",
                                              },
                                              on: {
                                                "check-change":
                                                  _vm.handleFormClick,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "货品规格",
                                      prop: "priceGrowth",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: { placeholder: "请输入货品规格" },
                                      model: {
                                        value: _vm.form.specifications,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "specifications",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.specifications",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "产地", prop: "salesNum" },
                                  },
                                  [
                                    _c("el-cascader", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        separator: "",
                                        props: _vm.props,
                                        filterable: "",
                                        options: _vm.options,
                                        clearable: "",
                                      },
                                      on: { change: _vm.getCheckedNodes },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancel } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.buttonLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addMarketQuotations("add")
                        },
                      },
                    },
                    [_vm._v("确 定 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.focusMarketFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: " content",
              attrs: {
                title: "价格设置",
                visible: _vm.focusMarketFormVisible,
                "before-close": _vm.dialogBeforeClose,
                width: "60vw",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.focusMarketFormVisible = $event
                },
                close: function ($event) {
                  return _vm.defaultData(_vm.form)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "m-b-10",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "font-size": "18px",
                    "font-weight": "600",
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.goodsName))]),
                  _c("span", [
                    _vm._v("市场：" + _vm._s(_vm.market.marketName)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "m-b-10" }, [
                _c("p", { staticClass: "m-b-10" }, [_vm._v("价格取值方式")]),
                _c("div", { staticClass: "redio-box m-l-20 m-t-10 m-r-20" }, [
                  _c(
                    "span",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "system" },
                          model: {
                            value: _vm.valueType,
                            callback: function ($$v) {
                              _vm.valueType = $$v
                            },
                            expression: "valueType",
                          },
                        },
                        [_vm._v("系统数据(全部)")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "manual" },
                          model: {
                            value: _vm.valueType,
                            callback: function ($$v) {
                              _vm.valueType = $$v
                            },
                            expression: "valueType",
                          },
                        },
                        [_vm._v("人工采集")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "gear" },
                          model: {
                            value: _vm.valueType,
                            callback: function ($$v) {
                              _vm.valueType = $$v
                            },
                            expression: "valueType",
                          },
                        },
                        [_vm._v("系统数据(部分档口)")]
                      ),
                    ],
                    1
                  ),
                  _vm.valueType == "gear"
                    ? _c(
                        "span",
                        { staticClass: "m-r-10" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addPriceData()
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm.valueType == "gear"
                ? _c(
                    "div",
                    { staticStyle: { height: "50vh", overflow: "auto" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "table-data-list",
                          attrs: {
                            stripe: "",
                            data: _vm.priceTableData,
                            "header-row-class-name": "table-header",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "gearName", label: "档口名称" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择档口名称",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeGear(scope.row)
                                            },
                                          },
                                          model: {
                                            value: scope.row.gearName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "gearName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.gearName",
                                          },
                                        },
                                        _vm._l(_vm.gearList, function (item) {
                                          return _c("el-option", {
                                            key: item.gid,
                                            attrs: {
                                              label: item.gearName,
                                              value: item.gid,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3657192714
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "goodsName", label: "货品名称" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请输入货品名称",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeGoods(scope.row)
                                            },
                                          },
                                          model: {
                                            value: scope.row.goodsName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsName",
                                          },
                                        },
                                        _vm._l(_vm.goodsTree, function (item) {
                                          return _c("el-option", {
                                            key: item.goodId,
                                            attrs: {
                                              label: item.goodName,
                                              value: item.goodId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3328725485
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "class-name": "",
                              prop: "categoryName",
                              label: "所属类别",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: "",
                                          clearable: "",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.showTreeGoodType(
                                              "searchPrice",
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.categoryName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "categoryName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.categoryName",
                                        },
                                      }),
                                      scope.row.editBool
                                        ? _c(
                                            "el-dialog",
                                            {
                                              staticClass: "tree_type",
                                              attrs: {
                                                visible: scope.row.editBool,
                                                title: "货品分类",
                                                modal: false,
                                              },
                                              on: {
                                                "update:visible": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    scope.row,
                                                    "editBool",
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                ref: "inp2",
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.inputText2,
                                                  callback: function ($$v) {
                                                    _vm.inputText2 = $$v
                                                  },
                                                  expression: "inputText2",
                                                },
                                              }),
                                              _c("el-tree", {
                                                ref: "treePriceParams",
                                                attrs: {
                                                  data: _vm.treeData,
                                                  "node-key": "categoryId",
                                                  props: _vm.defaultProps,
                                                  "highlight-current": true,
                                                  "filter-node-method":
                                                    _vm.filterNode,
                                                  "default-expand-all": "",
                                                  "show-checkbox": "",
                                                  "check-strictly": "",
                                                  "check-on-click-node": true,
                                                },
                                                on: {
                                                  "check-change":
                                                    _vm.handlePriceClick,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4067358052
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.priceDel(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.priceEmpty(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("清空")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3339989989
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.cancelPrice },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.buttonLoading,
                      },
                      on: { click: _vm.savePrice },
                    },
                    [_vm._v("保 存 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }