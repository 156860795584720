export default [
  {
    path: 'changePassword',
    name: 'changePassword',
    component: () => import('./view/changePassword'),
    meta: {
      title: '修改密码'
    }
  },
]
