export default [
  {
    path: "pointsDistributionRecord",
    name: "pointsDistributionRecord",
    component: () => import('./views/pointsDistributionRecord'),
    meta: {
      keepAlive: true, // 需要缓存
      title: "积分发放记录"
    }
  },
  {
    path: "accumulationAccount",
    name: "accumulationAccount",
    component: () => import('./views/accumulationAccount'),
    meta: {
      title: "鲜豆账户管理"
    },
  },
  {
    path: "peasExpenseCalendar",
    name: "peasExpenseCalendar",
    component: () => import('./views/peasExpenseCalendar'),
    meta: {
      title: "鲜豆消费记录"
    },
  },
  {
    path: "integralRegistration",
    name: "integralRegistration",
    component: () => import('./views/integralRegistration'),
    meta: {
      title: "积分登记"
    }
  },
  {
    path: "integralDistributedList",
    name: "integralDistributedList",
    component: () => import('./views/integralDistributedList'),
    meta: {
      title: "积分派发"
    }
  },
  {
    path: "printVoucher",
    name: "printVoucher",
    component: () => import('./components/pointsComponents/printVoucher.vue'),
    meta: {
      title: "积分兑换记录详情"
    }
  },
  {
    path: "integralDistributedDataStatistics",
    name: "integralDistributedDataStatistics",
    component: () => import('./views/integralDistributedDataStatistics.vue'),
    meta: {
      title: "积分派发数据统计"
    }
  },
  {
    path: "integralRedemptionStatistics",
    name: "integralRedemptionStatistics",
    component: () => import('./views/integralRedemptionStatistics.vue'),
    meta: {
      title: "积分兑换数据统计"
    }
  },
  {
    path: "configuration",
    name: "configuration",
    component: () => import('./views/configuration'),
    meta: {
      title: "积分配置管理"
    },
  },
  {
    path: "productCategoryConfiguration",
    name: "productCategoryConfiguration",
    component: () => import('./views/productCategoryConfiguration'),
    meta: {
      title: "货物品类配置"
    },
  },
  {
    path: "releaseRecord",
    name: "releaseRecord",
    component: () => import('./views/releaseRecord'),
    meta: {
      title: "鲜豆发放记录"
    },
  },
  {
    path: "accountDetails",
    name: "accountDetails",
    component: () => import('./views/accountDetails'),
    meta: {
      title: "鲜豆账户详情"
    }
  },
  {
    path: "integralQuery",
    name: "integralQuery",
    component: () => import('./views/IntegralQuery'),
    redirect: "/integralQuery/integralQueryOwner",
    children: [
      {
        path: "integralQueryOwner",
        name: "integralQueryOwner",
        component: () => import('./views/IntegralQueryOwner'),
        meta: {
          title: "档主积分"
        }
      },
      {
        path: "integralQueryCustomer",
        name: "integralQueryCustomer",
        component: () => import('./views/IntegralQueryCustomer'),
        meta: {
          title: "采购商积分"
        }
      }
    ]
  },
  {
    path: "IntegralAllocation",
    name: "IntegralAllocation",
    component: () => import('./views/IntegralAllocation'),
    meta: {
      title: "积分配置管理"
    }
  },
  {
    path: "integralCreditsLog/:gid",
    name: "integralCreditsLog",
    component: () => import('./views/integralCreditsLog'),
    meta: {
      title: "统计详情"
    }
  },
  {
    path: "integralStatistics",
    name: "integralStatistics",
    component: () => import('./views/integralStatistics'),
    meta: {
      title: "积分统计"
    }
  },
  {
    path: "integralDetails",
    name: "integralDetails",
    component: () => import('./views/integralDetails'),
    meta: {
      title: "积分详情"
    }
  },
  {
    path: "IntegralConsumption",
    name: "IntegralConsumption",
    component: () => import('./views/IntegralConsumption'),
    meta: {
      title: "积分消费记录"
    }
  },
  {
    path: "pointsExchange",
    name: "pointsExchange",
    component: () => import('./views/pointsExchange'),
    meta: {
      title: "积分兑换"
    }
  },
  {
    path: "pointsExchangeRecord",
    name: "pointsExchangeRecord",
    component: () => import('./views/pointsExchangeRecord'),
    meta: {
      title: "积分兑换记录"
    }
  },
  {
    path: "pointsExchangeDetails",
    name: "pointsExchangeDetails",
    component: () => import('./views/pointsExchangeDetails'),
    meta: {
      title: "积分兑换详情"
    }
  },
  {
    path: "IntegralRechargeRecord",
    name: "IntegralRechargeRecord",
    component: () => import('./views/IntegralRechargeRecord'),
    meta: {
      title: "积分充值记录"
    }
  },
  {
    path: "integralRefundType",
    name: "integralRefundType",
    component: () => import('./views/integralRefundType'),
    meta: {
      title: "积分退款类型"
    }
  },
  {
    path: "integralRefund",
    name: "integralRefund",
    component: () => import('./views/integralRefund'),
    meta: {
      title: "积分退款"
    }
  },
  {
    path: "integralActivityManagement",
    name: "integralActivityManagement",
    component: () => import('./views/integralActivityManagement'),
    meta: {
      title: "积分活动管理"
    }
  },
  {
    path: "integralActivityDetail/:id",
    name: "integralActivityDetail",
    component: () => import('./views/integralActivityDetail'),
    meta: {
      title: "活动详情"
    }
  },
  // {
  //   path: 'purchaseFruitsRewardIntegral',
  //   name: 'purchaseFruitsRewardIntegral',
  //   component: purchaseFruitsRewardIntegral,
  // },{
  //   path: 'purchaseIntegralGrantSummaryTable',
  //   name: 'purchaseIntegralGrantSummaryTable',
  //   component: purchaseIntegralGrantSummaryTable,
  // },
  {
    path: "integralActivityInfo/:id",
    name: "integralActivityInfo",
    component: () => import('./views/integralActivityInfo'),
    meta: {
      title: "查看明细"
    }
  }
];
