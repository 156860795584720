var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "titleBar  f-s-14 c-3" }, [
    _c("div", { staticClass: "leftT" }, [
      _c("div", { staticClass: "appName click" }, [
        _c("span", { staticClass: "loge" }, [
          _c("img", { attrs: { src: _vm.MarketLogo } }),
        ]),
        _c("span", [_vm._v("市场管理平台")]),
      ]),
      _c("div", { staticClass: "ub-f1" }, [_vm._v(" ")]),
    ]),
    _c(
      "div",
      { staticClass: "rightU" },
      [
        _c(
          "el-dropdown",
          [
            _c("span", { staticClass: "el-dropdown-link" }, [
              _vm.userName
                ? _c("div", { staticClass: "userName click" }, [
                    _vm._v(" " + _vm._s(_vm.userName) + " "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ])
                : _c("div", { staticClass: "userName click" }, [
                    _vm._v(" 超级管理员 "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", [
                  _c(
                    "div",
                    {
                      staticStyle: { "line-height": "40px" },
                      on: { click: _vm.toChangePassword },
                    },
                    [_c("span", [_vm._v("修改密码")])]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: " m-l-30 click exit", on: { click: _vm.goLogin } },
          [
            _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
            _vm._v(" 退出 "),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }