export default [
  {
    path: 'costSummaryTable',
    name: 'costSummaryTable',
    component: () => import('../reportStatistics/views/costSummaryTable.vue'),
    meta: {
      title: '费用汇总表'
    }
  },
  {
    path: 'regionalCostTable',
    name: 'regionalCostTable',
    component: () => import('../reportStatistics/views/regionalCostTable.vue'),
    meta: {
      title: '区域费用表'
    }
  },
  {
    path: 'costBreakDown',
    name: 'costBreakDown',
    component: () => import('../reportStatistics/views/costBreakDown.vue'),
    meta: {
      title: '费用分项表'
    }
  },
  {
    path: 'boothChargeDes',
    name: 'boothChargeDes',
    component: () => import('../reportStatistics/views/boothChargeDes.vue'),
    meta: {
      title: '档口收费明细'
    }
  },
  {
    path: 'boothChargeTypeDes',
    name: 'boothChargeTypeDes',
    component: () => import('../reportStatistics/views/boothChargeTypeDes.vue'),
    meta: {
      title: '档口收费类型明细'
    }
  },
  {
    path: 'paymentList',
    name: 'paymentList',
    component: () => import('../reportStatistics/views/paymentList.vue'),
    meta: {
      title: '缴费清单'
    }
  },
  {
    path: 'paymentHistory',
    name: 'paymentHistory',
    component: () => import('../reportStatistics/views/paymentHistory.vue'),
    meta: {
      title: '缴费历史'
    }
  },
]
