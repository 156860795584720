<template>
  <div class="newContract">
    <el-card>
      <div>合同详情</div>
      <div class="newContract-Information">
        <span
          >基本信息 合同编号:{{ contractTempleteNum }} (发起人:{{ updateByName }}/发起时间:{{
            createTime
          }}16:24 :03)</span
        >
        <span class="staus">{{ status(statusr) }}</span>
      </div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="合同名称 :">
          <el-input v-model="formInline.name" placeholder="审批人" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="合同模板 :">
          <el-select v-model="formInline.contractTempleteId" :disabled="true">
            <el-option
              v-for="(item, index) in feeConfigs"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板属性 :">
          <el-select v-model="formInline.attribute" placeholder="模板属性" :disabled="true">
            <el-option label="电子合同" value="0"></el-option>
            <el-option label="线下合同" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同年限 :">
          <el-input v-model="formInline.period" placeholder="审批人" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="开始时间 :">
          <el-input v-model="formInline.startTime" placeholder="审批人" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="结束时间 :">
          <el-input v-model="formInline.endTime" placeholder="审批人" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="签订时间 :">
          <el-input v-model="formInline.signTime" placeholder="审批人" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="newContract-Information">
          <span
            >甲方信息 是否是市场方:
            <el-select v-model="valueA" placeholder="请选择" @change="valueSelect" :disabled="true">
              <el-option label="是" value="Y"> </el-option>
              <el-option label="否" value="N"> </el-option>
            </el-select>
            <span class="Identity" v-if="valueA !== 'Y'"
              >身份性质:
              <el-select v-model="natureA" placeholder="请选择" :disabled="true">
                <el-option label="个人" value="N"> </el-option>
                <el-option label="企业" value="Y"> </el-option>
              </el-select>
            </span>
          </span>
          <!-- <el-button type="primary" v-if="valueA !== 'Y'">请选择商户</el-button> -->
        </div>
        <div class="newContract-form">
          <PatyAform
            :init="valueA !== 'N' || natureA !== 'N'"
            :firstContractUser="firstContractUser"
            :auditDetails="audit"
          ></PatyAform>
        </div>
      </div>
      <div>
        <div class="newContract-Information">
          <span
            >乙方信息 是否是市场方:
            <el-select
              v-model="valueB"
              placeholder="请选择"
              :disabled="true"
              @change="valueSelectB"
            >
              <el-option label="是" value="Y"> </el-option>
              <el-option label="否" value="N"> </el-option>
            </el-select>
            <span class="Identity" v-if="valueB !== 'Y'"
              >身份性质:
              <el-select v-model="natureB" placeholder="请选择" :disabled="true">
                <el-option label="个人" value="N"> </el-option>
                <el-option label="企业" value="Y"> </el-option>
              </el-select>
            </span>
          </span>
          <!-- <el-button type="primary" v-if="valueB !== 'Y'">请选择商户</el-button> -->
        </div>
        <div>
          <PatyBform
            :init="natureB === 'Y'"
            :modify="this.$route.query.contractId"
            :auditDetails="audit"
            :secondContractUser="secondContractUser"
          ></PatyBform>
        </div>
      </div>
      <div class="stall">
        <div class="newContract-Information">
          <span>档口信息</span>
          <!-- <el-button type="primary">请选择档口</el-button> -->
        </div>
        <div class="stall-Information">
          <el-form :inline="true" :model="stallfFrom" class="demo-form-inline">
            <el-form-item label="档口号 :">
              <el-input
                v-model="stallfFrom.boothNum"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="楼栋编号 :">
              <el-input
                v-model="stallfFrom.floorNum"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="楼层 :">
              <el-input
                v-model="stallfFrom.floorLevel"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="区域名称 :">
              <el-input
                v-model="stallfFrom.regionName"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="建筑面积 :">
              <el-input
                v-model="stallfFrom.constructionArea"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true" :model="stallfFrom" class="demo-form-inline">
            <el-form-item label="使用性质 :">
              <el-input
                v-model="stallfFrom.useNature"
                placeholder="审批人"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="cost">
        <div class="newContract-Information">
          <span>费用信息</span>
        </div>
        <div>
          <el-table :data="listAllData" border style="width: 100%">
            <el-table-column prop="feeTypeCd" label="费用类型">
              <template slot-scope="scope">
                {{ getTypefee(scope.row.feeTypeCd) }}
              </template>
            </el-table-column>
            <el-table-column prop="feeName" label="费用项目"> </el-table-column>
            <el-table-column prop="cycle" label="费用标识">
              <template slot-scope="scope">
                <div v-if="parseInt(scope.row.cycle) === 1">周期性费用</div>
                <div v-else>一次性费用</div>
              </template>
            </el-table-column>
            <el-table-column prop="startTime" label="计费开始时间"> </el-table-column>
            <el-table-column prop="endTime" label="费用结束时间"> </el-table-column>
            <el-table-column prop="unitPrice" label="单价"> </el-table-column>
            <el-table-column prop="additionalFee" label="(附加/固定)费"> </el-table-column>
            <el-table-column prop="increaseTime" label="递增开始时间"> </el-table-column>
            <el-table-column prop="increaseCycle" label="递增周期"> </el-table-column>
            <el-table-column prop="increaseRate" label="递增率"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="getCostList(scope.row)">查看费用清单</el-button>
                <!--查看费用清单-->
                <div class="eldialog">
                  <el-dialog :visible.sync="costListdialog" title="费用清单明细">
                    <template #default>
                      <div><span>费用项 :</span><span>应收总额:</span></div>
                      <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" width="180"> </el-table-column>
                        <el-table-column prop="contractFeeCycle" label="周期号" width="180">
                        </el-table-column>
                        <el-table-column prop="contractFeeMonthAmount" label="金额(每月)">
                        </el-table-column>
                        <el-table-column prop="contractFeeStartTime" label="计费开始时间">
                        </el-table-column>
                        <el-table-column prop="contractFeeEndTime" label="计费结束时间">
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-dialog>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="other">
        <div class="newContract-Information">
          <span>其他</span>
        </div>
        <div>
          <el-form :model="extension" :inline="true">
            <el-form-item v-for="(item, index) in extension" :key="index" :label="item.specName">
              <el-input v-model="item.value" :disabled="true"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="annex">
        <div class="newContract-Information">
          <span>附件信息</span>
        </div>
        <div>
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <!-- <el-button size="small" type="primary">点击上传</el-button>
            <div class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-upload>
        </div>
      </div>

      <div class="application" v-if="this.$route.query.status === '2'">
        <div class="newContract-Information">
          <span>申请结果</span>
        </div>
        <div class="audit">
          <div>
            申请结果 : <el-radio v-model="radio" label="1" :disabled="true">通过</el-radio>
            <el-radio v-model="radio" label="2" :disabled="true">不通过</el-radio>
          </div>
          <div class="audit-text">
            审核意见 :
            <div class="textarea">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="最多是200个字"
                v-model="textarea"
                :disabled="true"
              />
            </div>
          </div>
          <div class="audit-text" v-if="this.$route.query.status === '6'">
            撤销原因 :
            <div class="textarea">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="最多是200个字"
                v-model="termination"
                :disabled="true"
              />
            </div>
          </div>

          <!-- <div class="audit-text">
            终止原因 :
            <div class="textarea">
              <el-input type="textarea" :rows="3" placeholder="最多是200个字" v-model="textarea" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="operationKey">
        <el-button class="left" type="info" @click="$router.back(-1)">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import PatyBform from '../components/partyBform.vue'
import PatyAform from '../components/partyAform.vue'
import { marketplan } from '../api/api'

export default {
  name: 'newContract',
  components: { PatyAform, PatyBform },
  data() {
    return {
      statusr: '',
      textarea: '',
      termination: '',
      extension: {},
      costListdialog: false,
      stallfFrom: {
        boothNum: '',
        floorNum: '',
        floorLevel: '',
        regionName: '',
        constructionArea: '',
        useNature: ''
      },
      secondContractUser: {},
      audit: this.$route.query.audit,
      details: true,
      firstContractUser: {},
      fileList: [],
      formInline: {
        name: '', // 合同名称
        contractTempleteId: '', // 合同模板
        attribute: '', // 模板属性
        period: '', // 合同年限
        startTime: '', // 开始时间
        signTime: '', // 签订时间
        endTime: ''
      },
      feeConfigs: [],
      radio: '1',
      valueB: 'N',
      valueA: 'Y',
      natureA: 'Y',
      natureB: 'N',
      listAllData: [],
      customerId: this.$route.query.contractId,
      tableData: [],
      contractTempleteNum: '',
      updateByName: '',
      createTime: '',
      contractr: [] // 合同信息
    }
  },
  mounted() {
    this.getDetails()
    this.getlistAll()
  },
  methods: {
    // 过滤状态
    status(status) {
      let statusName = ''
      if (status === 0) {
        statusName = '暂存'
      } else if (status === 1) {
        statusName = '待他人签署'
      } else if (status === 2) {
        statusName = '待审核'
      } else if (status === 3) {
        statusName = '执行中'
      } else if (status === 4) {
        statusName = '已终止'
      } else if (status === 5) {
        statusName = '已到期'
      } else if (status === 6) {
        statusName = '已撤销'
      } else if (status === 7) {
        statusName = '已拒签'
      }
      return statusName
    },
    // 过滤显示
    getTypefee(type) {
      let nameType = ''
      if (type === 1) {
        nameType = '物业费'
      }
      if (type === 2) {
        nameType = '押金'
      }
      if (type === 3) {
        nameType = '租金'
      }
      if (type === 4) {
        nameType = '卫生费'
      }
      if (type === 5) {
        nameType = '管理费'
      }
      if (type === 8) {
        nameType = '加建设施费'
      }
      if (type === 9) {
        nameType = '其他'
      }
      return nameType
    },
    getCostList(row) {
      const contractFeeDetailList = []
      this.costListdialog = true
      this.listAllData.forEach((item, index) => {
        if (item.configId === row.configId) {
          item.contractFeeDetailList.map((v) => {
            contractFeeDetailList.push({
              contractFeeCycle: v.cycleNumber,
              contractFeeMonthAmount: v.amount,
              contractFeeStartTime: v.payStartTime,
              contractFeeEndTime: v.payEndTime
            })
            return v
          })
        }
      })
      this.tableData = contractFeeDetailList
    },
    getAInformation(data) {
      // console.log(data, '====getAInformation')
    },
    async getDetails() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true,
        id: this.customerId
      }
      // 合同信息
      const detalist = await marketplan.getDetails({ ...obj })
      // //上传
      if (detalist.data.data.contractAttachment.length > 0) {
        detalist.data.data.contractAttachment.map((item) => {
          const name = item.fileUri.split('/')[5]
          this.fileList.push({
            name: name,
            url: item.fileUri
          })
          return item
        })
      }
      this.formInline.name = detalist.data.data.contract.name
      this.feeConfigs.forEach((item) => {
        if (item.number === detalist.data.data.contract.contractTempleteNum) {
          this.formInline.contractTempleteId = item.id
        }
      })
      this.contractTempleteNum = detalist.data.data.contract.contractTempleteNum
      this.contractr = detalist.data.data.contract
      this.formInline.attribute = String(detalist.data.data.contract.attribute)
      this.formInline.period = detalist.data.data.contract.period
      this.formInline.startTime = detalist.data.data.contract.startTime
      this.formInline.signTime = detalist.data.data.contract.signTime
      this.formInline.endTime = detalist.data.data.contract.endTime
      this.updateByName = detalist.data.data.contract.updateByName
      this.createTime = detalist.data.data.contract.createTime
      this.statusr = detalist.data.data.contract.status
      // // 甲方
      this.firstContractUser = detalist.data.data.firstContractUser
      if (this.firstContractUser.isMarketSide === 1) {
        this.valueA = 'Y'
        this.valueB = 'N'
        this.natureA = detalist.data.data.firstContractUser.identity === 0 ? 'N' : 'Y'
      } else {
        this.valueB = 'Y'
        this.valueA = 'N'
        this.natureB = detalist.data.data.secondContractUser.identity === 0 ? 'N' : 'Y'
      }
      // 乙方
      this.secondContractUser = detalist.data.data.secondContractUser
      // 档口信息
      this.stallfFrom.boothNum = detalist.data.data.contractDetail.boothNum
      this.stallfFrom.boothId = detalist.data.data.contractDetail.boothId
      this.stallfFrom.floorNum = detalist.data.data.contractDetail.floorNum
      this.stallfFrom.floorLevel = detalist.data.data.contractDetail.floorLevel
      this.stallfFrom.regionName = detalist.data.data.contractDetail.regionName
      this.stallfFrom.constructionArea = detalist.data.data.contractDetail.constructionArea
      this.stallfFrom.useNature = detalist.data.data.contractDetail.useNature
      //审核详情
      if (detalist.data.data.contractAudit !== null) {
        this.radio = detalist.data.data.contractAudit.auditStatus
        this.textarea = detalist.data.data.contractAudit.auditOpinion
        this.termination = detalist.data.data.contractAudit.revokeReason
      }
      //费用
      this.listAllData = detalist.data.data.contractFeeList
      const otherCosts = []
      detalist.data.data.contractExpandValues.map((item) => {
        otherCosts.push({
          specName: item.expandSetttingName,
          value: item.value,
          domainName: item.valueDomain,
          expandSetttingId: item.expandSetttingId,
          id: item.id,
          contractId: item.contractId
        })
        return item
      })
      // 扩展
      this.extension = otherCosts
    },
    // 合同模板
    getlistAll() {
      let obj = {
        requestNo: new Date().getTime(),
        isDecode: true
      }
      marketplan
        .getlistAll({ ...obj })
        .then((result) => {
          this.feeConfigs = result.data.data
          this.feeConfigs.forEach((item) => {
            if (item.number === this.contractr.contractTempleteNum) {
              this.formInline.contractTempleteId = item.id
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
      window.open(file.url)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    valueSelectB() {
      if (this.valueB === 'Y') {
        this.valueA = 'N'
      } else {
        this.valueA = 'Y'
      }
    },
    valueSelect() {
      if (this.valueA === 'Y') {
        this.valueB = 'N'
      } else {
        this.valueB = 'Y'
      }
      console.log(this.valueA, '===?')
    }
  }
}
</script>
<style lang="scss">
.newContract {
  white-space: '' !important;
  .other {
     white-space: pre-wrap !important;
    .el-form-item__label {
      width: 189px;
    }
  }

  .operationKey {
    // border: 1px solid;
    text-align: center;
    padding-top: 60px;
    .el-button {
      width: 160px;
      height: 44px;
      font-size: 16px;
    }
    .left {
      margin-right: 20px;
    }
    .right {
      margin-left: 20px;
    }
  }
  .annex {
    .el-dialog__body {
      img {
        width: 100%;
      }
    }
  }
  .application {
    .audit {
      margin-left: 10px;
    }
    .audit-text {
      margin-top: 20px;
    }
    .textarea {
      display: inline-block;
      vertical-align: middle;
      width: 70%;
    }
  }
  .el-table--border th {
    text-align: center;
    background: rgba(209, 207, 207, 0.933);
    color: #000;
    font-size: 14px;
  }
  .stall-Information {
    width: 80%;
    .el-form-item:nth-of-type(1) {
      .el-form-item__label {
        padding-left: 40px;
      }
    }
    .el-form-item:nth-of-type(3) {
      .el-form-item__label {
        padding-left: 25px;
      }
    }
    .el-form-item:nth-of-type(4) {
      .el-form-item__label {
        padding-left: 25px;
      }
    }
  }
  .newContract-Information {
    height: 40px;
    background: #eeee;
    line-height: 40px;
    margin-top: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    .el-button {
      height: 38px;
      margin-left: 40px;
    }
    .el-select {
      width: 100px;
    }
  }
  .el-form-item--small.el-form-item {
    margin-right: 130px;
  }
  .Identity {
    margin-left: 60px;
  }
  .newContract-form {
    .el-form-item:nth-of-type(1) {
      .el-form-item__label {
        padding-left: 40px;
      }
    }
    .el-form-item:nth-of-type(5) {
      .el-form-item__label {
        padding-left: 10px;
      }
    }
    .el-form {
      width: 100%;
    }
    .el-form-item--small.el-form-item {
      margin-right: 150px !important;
    }
  }
  .staus {
    float: right;
    font-size: 18px;
    font-weight: 550;
    margin-right: 170px;
  }
}
</style>
