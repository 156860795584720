import marketPlan from './views/marketplan'
import detaiLs from './views/details.vue'
import newContract from './views/newContract.vue'

export default [{
  path: 'marketplan',
  name: 'marketplan',
  component: () => import('./views/marketplan.vue'),
  meta: {
    title: "市场平面图"
  }
},
{
  path: 'details',
  name: 'details',
  component: () => import('./views/details.vue'),
  meta: {
    title: "详情"
  }
},
{
  path: 'newContract',
  name: 'newContract',
  component: () => import('./views/newContract.vue'),
  meta: {
    title: "合同详情"
  }
}]
